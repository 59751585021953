import { useState, useEffect } from "react";
import { getMobileFilialDatabase } from "services/mobileFilialDatabase";

function useDatabaseFetch(id) {
  const [dadosDatabase, setDadosDatabase] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  async function fetchDatabase() {
    setIsLoading(true);
    if (id && id > 0) {
      const resultado = await getMobileFilialDatabase(id);
      if (resultado) setDadosDatabase(resultado);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchDatabase();
  }, []);

  return [dadosDatabase, isLoading];
}
export { useDatabaseFetch };
