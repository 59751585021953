import React from "react";
import PropTypes from "prop-types";
import UsuarioForm from "./UsuarioForm";
import { authContext } from "contexts/AuthContext";

const handleCancel = history => () => history.goBack();

export default function UsuarioPage(props) {
  const { match, history } = props;
  const { params: { id } = {} } = match;

  const { state } = React.useContext(authContext);
  const { dadosUsuario } = state;
  const { contratanteId } = dadosUsuario;

  return (
    <UsuarioForm
      contratanteId={contratanteId}
      id={id ? Number(id) : id}
      onCancel={handleCancel(history)}
      onAfterConfirmar={handleCancel(history)}
    />
  );
}

UsuarioPage.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object
};
