import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";

import Page from "components/CustomPages/Page";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSnackbarContent from "components/CustomSnackbarContent/CustomSnackbarContent";
import ButtonsSincronizacao from "./ButtonsSincronizacao";
import ComponentSkeleton from "components/ComponentSkeleton/ComponentSkeleton";
import { useSituacaoSincronizacao } from "./SituacaoSincronizacaoHook";

const useStyles = makeStyles(theme => ({
  rootFabProgress: {
    display: "flex",
    alignItems: "center"
  },
  wrapperFabProgress: {
    margin: theme.spacing(1),
    position: "relative"
  },
  margin: {
    margin: theme.spacing(1)
  },
  progress: {
    margin: theme.spacing(2)
  }
}));

function SituacaoSincronizacao({
  tipo,
  somenteBotoes = false,
  somenteSincronizarTodos = false,
}) {
  const classes = useStyles();
  const { 
    dadosSincronizacao,
    doSincronizacao,
    doSincronizacaoCancelar,
    doLimparDadosServidor
  } = useSituacaoSincronizacao(tipo);

  const {
    dtInicio,
    dtFim,
    executando,
    sucesso,
    cancelada,
    erro,
    updatedAt,
    itemsCount,
    itensSincronizadosCount,
    envioCompleto
  } = dadosSincronizacao;

  const renderedSituacao = React.useMemo(() => {
    let variant, message;

    if (sucesso === true) {
      variant = "success";
      message = <strong>Sucesso</strong>;
    } else if (cancelada === true) {
      variant = "warning";
      message = <strong>Cancelada</strong>;
    } else if (!sucesso && erro) {
      variant = "error";
      message = <strong>Erro</strong>;
    } else if (executando === true) {
      variant = "info";
      message = (
        <strong>
          Em andamento -{" "}
          {envioCompleto === true
            ? "Sincronizando todos os dados"
            : "Sincronizando somente dados novos ou atualizados!"}{" "}
          - Processado {itensSincronizadosCount} registros
        </strong>
      );
    }
    return variant ? (
      <CustomSnackbarContent
        variant={variant}
        className={classes.margin}
        message={message}
      />
    ) : null;
  }, [dadosSincronizacao]);

  if (somenteBotoes) {
    return (
      <Page labelTituloHeader="Ações">
        <ButtonsSincronizacao
          tipo={tipo}
          executando={executando}
          somenteSincronizarTodos={somenteSincronizarTodos}
          limpaDadosServidor={false}
        />
      </Page>
    );
  }

  return (
    <Page labelTituloHeader="Dados da ultima sincronização">
      <GridContainer>
        <GridItem xs={6} sm={6} md={6}>
          <ComponentSkeleton>
            <CustomInput
              labelText="Data Inicio"
              inputProps={{
                readOnly: true,
                value: dtInicio
                  ? moment(dtInicio).format("DD/MM/YYYY - HH:mm:ss")
                  : ""
              }}
              formControlProps={{
                fullWidth: true
              }}
            />
          </ComponentSkeleton>
        </GridItem>
        <GridItem xs={6} sm={6} md={6}>
          <ComponentSkeleton>
            <CustomInput
              labelText="Data Fim"
              inputProps={{
                readOnly: true,
                value: dtFim
                  ? moment(dtFim).format("DD/MM/YYYY - HH:mm:ss")
                  : ""
              }}
              formControlProps={{
                fullWidth: true
              }}
              readOnly={true}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={6} sm={6} md={6}>
          <ComponentSkeleton>
            <CustomInput
              labelText="Quantidade de registros sincronizados"
              labelSempreAcima={true}
              inputProps={{
                readOnly: true,
                value: itemsCount
              }}
              formControlProps={{
                fullWidth: true
              }}
            />
          </ComponentSkeleton>
        </GridItem>
        <GridItem xs={6} sm={6} md={6}>
          <ComponentSkeleton>
            <CustomInput
              labelText="Ultima sincronização de pacote de dados"
              inputProps={{
                readOnly: true,
                value: updatedAt
                  ? moment(updatedAt).format("DD/MM/YYYY - HH:mm:ss")
                  : ""
              }}
              formControlProps={{
                fullWidth: true
              }}
              readOnly={true}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>
      {renderedSituacao}

      {erro && (
        <GridContainer>
          <GridItem xs>
            <CustomSnackbarContent
              variant="error"
              className={classes.margin}
              message={<strong>{erro}</strong>}
            />
          </GridItem>
        </GridContainer>
      )}

      <ButtonsSincronizacao
        tipo={tipo}
        executando={executando}
        doSincronizacao={doSincronizacao}
        doSincronizacaoCancelar={doSincronizacaoCancelar}
        limparDadosServidor={doLimparDadosServidor}
      />
    </Page>
  );
}

SituacaoSincronizacao.propTypes = {
  tipo: PropTypes.string
};

export default SituacaoSincronizacao;
