import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { isUndefined } from "lodash";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/iconeJustoPedidoBranco.png";
import { validaPermissaoUsuario } from "helpers/usuarioPermissoes.helper";
import { authContext } from "contexts/AuthContext";

let ps;

function getNestedRoutes(route, index) {
  const path = `${route.layout || ""}${route.path}`;
  if (route.subRoutes)
    return (
      <Route
        path={path}
        key={`${path}_${index}`}
        render={({ match: { path } }) =>(
          <>
            {route.subRoutes.map((sr, i) =>
              getNestedRoutes({ ...sr, layout: path }, i)
            )}
          </>
        )}
      />
    );
  return (
    <Route
      path={path}
      component={route.component}
      exact={route.exact}
      key={`${path}_${index}`}
    />
  );
}

const switchRoutes = permissoes => (
  <Switch>
    {routes
      .filter(
        route =>
          isUndefined(route.permissoes) ||
          validaPermissaoUsuario(permissoes, route.permissoes)
      )
      .map((prop, key) => {
        if (prop.layout === "/admin") {
          return getNestedRoutes(prop, key);
        }
        return null;
      })}
    <Redirect from="/login" to="/admin/home" />
    <Redirect from="/admin" to="/admin/home" />
  </Switch>
);

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  const {
    state: { dadosUsuario = {} }
  } = React.useContext(authContext);

  const { permissoes } = dadosUsuario;

  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={"Justo Pedido Mobile"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes(permissoes)}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes(permissoes)}</div>
        )}
        {getRoute() ? <Footer /> : null}
      </div>
    </div>
  );
}
