import React from "react";
import { Controller, useForm } from "react-hook-form";
import { isString } from "lodash";
import PropTypes from "prop-types";
import MuiAlert from "@material-ui/lab/Alert";

import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomSelect from "components/CustomSelect/CustomSelect.js";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import CadastroPage from "components/CustomPages/CadastroPage";
import CepInput from "components/CepInput/CepInput";
import DocumentoFederalInput, {
  cpfCnpjValidador
} from "components/DocumentoFederalInput/DocumentoFederalInput";
import { contratanteExiste } from "services/contratante";
import CustomAutocompleteCidade from "components/CustomAutocomplete/CustomAutocompleteCidade";
import TelefoneInput, {
  telefoneValidador
} from "components/TelefoneInput/TelefoneInput";
import ComponentSkeleton from "components/ComponentSkeleton/ComponentSkeleton";
import FormInput from "components/FormFields/FormFields";

const useStyles = makeStyles(theme => ({
  progress: {
    margin: theme.spacing(2)
  }
}));

export default function ContratanteForm({ dadosContratante }) {
  const {
    contratante,
    isLoading,
    salvarContratante,
    formError
  } = dadosContratante;
  const classes = useStyles();
  const {
    id,
    tipoEmpresa = "J",
    nome = "",
    fantasia = "",
    email = "",
    docFederal = "",
    docEstadual = "",
    telefone = "",
    cep = "",
    cidadeId = "",
    endereco = "",
    numero = "",
    bairro = "",
    complemento = "",
    ativo = true,
    maxDipositivos = 1,
    maxFiliais = 1
  } = contratante;

  const {
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    getValues,
    triggerValidation,
    control,
    setError
  } = useForm({
    defaultValues: {
      cidadeId
    }
  });

  React.useEffect(() => {
    register({ name: "cidadeId" }, { required: "A cidade é requerida" });
  }, []);

  const handleDadosCep = dadosCep => {
    setValue("cidadeId", dadosCep?.cidade?.id);
    setValue("endereco", dadosCep?.logradouro);
    setValue("bairro", dadosCep?.bairro);
  };

  const handleGetDadosDocFederal = ({ consulta, cep }) => {
    if (id > 0) return;
    setValue("nome", consulta?.nome);
    setValue("fantasia", consulta?.fantasia);
    setValue("numero", consulta?.numero);
    setValue("complemento", consulta?.complemento);
    setValue("telefone", consulta?.telefone);
    setValue("email", consulta?.email);
    setValue("cep", consulta?.cep);
    handleDadosCep(cep);
  };

  const watchTipoEmpresa = watch("tipoEmpresa", tipoEmpresa);

  const onSubmit = async data => {
    const dadosSalvar = {
      ...data,
      id
    };
    await salvarContratante(dadosSalvar, setError);
  };

  const handleValidacoesDocFederal = async value => {
    let valueToUse = "";
    if (isString(value)) valueToUse = value;
    else {
      const { target: { value: valueI = "" } = {} } = value || {};
      valueToUse = valueI;
    }
    const valores = getValues();
    let erro = cpfCnpjValidador(valores.tipoEmpresa, valueToUse);
    if (!erro && !id) {
      const retorno = await contratanteExiste(valueToUse);
      if (retorno)
        erro = `O ${
          valores.tipoEmpresa === "F" ? "CPF" : "CNPJ"
        } já foi cadastrado`;
    }
    return erro;
  };

  return isLoading === true ? (
    <div>
      <CircularProgress className={classes.progress} />
    </div>
  ) : (
    <CadastroPage onSalvar={handleSubmit(onSubmit)}>
      <form>
        <GridContainer>
          {formError && (
            <GridItem xs={12} sm={12} md={12}>
              <MuiAlert severity="error">{formError}</MuiAlert>
            </GridItem>
          )}
          <GridItem xs={12} sm={12} md={4}>
            <ComponentSkeleton showSkeleton={isLoading}>
              <Controller
                name="tipoEmpresa"
                control={control}
                defaultValue={tipoEmpresa}
                rules={{
                  required: "O tipo da empresa é requerido!"
                }}
                render={props => (
                  <CustomSelect
                    labelText="Tipo da Empresa"
                    id="tipoEmpresa"
                    selectProps={{
                      defaultValue: tipoEmpresa
                    }}
                    formControlProps={{
                      fullWidth: true
                    }}
                    options={[
                      {
                        value: "F",
                        label: "Física"
                      },
                      {
                        value: "J",
                        label: "Jurídica"
                      }
                    ]}
                    {...props}
                  />
                )}
              />
            </ComponentSkeleton>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <ComponentSkeleton showSkeleton={isLoading}>
              <Controller
                name="docFederal"
                control={control}
                defaultValue={docFederal}
                rules={{
                  required: `O campo CPF / CNPJ é requerido`,
                  validate: handleValidacoesDocFederal
                }}
                render={props => (
                  <CustomInput
                    labelText={watchTipoEmpresa === "F" ? "CPF" : "CNPJ"}
                    id="docFederal"
                    inputProps={{
                      name: "docFederal",
                      disabled: id > 0,
                      readOnly: id > 0,
                      inputComponent: DocumentoFederalInput,
                      inputProps: {
                        tipoPessoa: watchTipoEmpresa,
                        onGetDadosDocFederal: handleGetDadosDocFederal,
                        renderCarregando: (
                          <CircularProgress className={classes.progress} />
                        )
                      }
                    }}
                    formControlProps={{
                      fullWidth: true
                    }}
                    error={!!errors.docFederal}
                    errorMessage={
                      errors.docFederal ? errors.docFederal.message : ""
                    }
                    {...props}
                  />
                )}
              />
            </ComponentSkeleton>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <ComponentSkeleton showSkeleton={isLoading}>
              <Controller
                name="docEstadual"
                control={control}
                defaultValue={docEstadual}
                render={props => (
                  <CustomInput
                    labelText={
                      watchTipoEmpresa === "F" ? "RG" : "Inscrição Estadual"
                    }
                    id="docEstadual"
                    formControlProps={{
                      fullWidth: true
                    }}
                    {...props}
                  />
                )}
              />
            </ComponentSkeleton>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <ComponentSkeleton showSkeleton={isLoading}>
              <Controller
                name="nome"
                control={control}
                defaultValue={nome}
                rules={{
                  required: "O campo Nome / Razão Social é requerido."
                }}
                render={props => (
                  <CustomInput
                    labelText={
                      watchTipoEmpresa === "F" ? "Nome" : "Razão Social"
                    }
                    id="nome"
                    formControlProps={{
                      fullWidth: true
                    }}
                    error={!!errors.nome}
                    errorMessage={errors.nome ? errors.nome.message : ""}
                    {...props}
                  />
                )}
              />
            </ComponentSkeleton>
          </GridItem>
        </GridContainer>
        {watchTipoEmpresa === "J" ? (
          <GridContainer>
            <GridItem xs={12} sm={12} md={8}>
              <ComponentSkeleton showSkeleton={isLoading}>
                <Controller
                  name="fantasia"
                  control={control}
                  defaultValue={fantasia}
                  render={props => (
                    <CustomInput
                      labelText="Nome Fantasia"
                      id="fantasia"
                      formControlProps={{
                        fullWidth: true
                      }}
                      {...props}
                    />
                  )}
                />
              </ComponentSkeleton>
            </GridItem>
          </GridContainer>
        ) : null}

        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <ComponentSkeleton showSkeleton={isLoading}>
              <Controller
                name="email"
                control={control}
                defaultValue={email}
                rukes={{
                  required: "O campo email é requerido"
                }}
                render={props => (
                  <CustomInput
                    labelText="Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    error={!!errors.email}
                    errorMessage={errors.email ? errors.email.message : ""}
                    {...props}
                  />
                )}
              />
            </ComponentSkeleton>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <FormInput
              isLoading={isLoading}
              control={control}
              name="maxDipositivos"
              labelText="Quantidade Máxima de Dispositivos"
              defaultValue={maxDipositivos}
              errors={errors}
              rules={{
                min: {
                  value: 1,
                  message: "O valor mínimo é 1."
                }
              }}
              inputProps={{
                type: "Number",
                min: 1
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <FormInput
              isLoading={isLoading}
              control={control}
              name="maxFiliais"
              labelText="Quantidade Máxima de Filiais"
              defaultValue={maxFiliais}
              errors={errors}
              rules={{
                min: {
                  value: 1,
                  message: "O valor mínimo é 1."
                }
              }}
              inputProps={{
                type: "Number",
                min: 1
              }}
            />
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <ComponentSkeleton showSkeleton={isLoading}>
              <Controller
                name="telefone"
                control={control}
                defaultValue={telefone}
                rules={{
                  required: `O campo telefone é requerido`,
                  validate: telefoneValidador
                }}
                render={props => (
                  <CustomInput
                    labelText="Telefone"
                    id="telefone"
                    inputProps={{
                      inputComponent: TelefoneInput
                    }}
                    formControlProps={{
                      fullWidth: true
                    }}
                    error={!!errors.telefone}
                    errorMessage={
                      errors.telefone ? errors.telefone.message : ""
                    }
                    {...props}
                  />
                )}
              />
            </ComponentSkeleton>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <ComponentSkeleton showSkeleton={isLoading}>
              <Controller
                name="cep"
                control={control}
                defaultValue={cep}
                render={props => (
                  <CustomInput
                    labelText="Cep"
                    id="cep"
                    inputProps={{
                      inputComponent: CepInput,
                      inputProps: {
                        onGetDadosCep: handleDadosCep,
                        renderCarregandoCep: (
                          <CircularProgress className={classes.progress} />
                        )
                      }
                    }}
                    formControlProps={{
                      fullWidth: true
                    }}
                    error={!!errors.cep}
                    errorMessage={errors.cep ? errors.cep.message : ""}
                    {...props}
                  />
                )}
              />
            </ComponentSkeleton>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <ComponentSkeleton showSkeleton={isLoading}>
              <Controller
                name="cidadeId"
                control={control}
                defaultValue={cidadeId}
                render={props => (
                  <CustomAutocompleteCidade
                    id="cidadeId"
                    labelText="Cidade"
                    name="cidadeId"
                    formControlProps={{
                      fullWidth: true
                    }}
                    error={!!errors.cidadeId}
                    errorMessage={
                      errors.cidadeId ? errors.cidadeId.message : ""
                    }
                    setValueReactForm={setValue}
                    triggerValidationReactForm={triggerValidation}
                    {...props}
                  />
                )}
              />
            </ComponentSkeleton>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <ComponentSkeleton showSkeleton={isLoading}>
              <Controller
                name="endereco"
                control={control}
                defaultValue={endereco}
                render={props => (
                  <CustomInput
                    labelText="Endereço"
                    id="endereco"
                    formControlProps={{
                      fullWidth: true
                    }}
                    {...props}
                  />
                )}
              />
            </ComponentSkeleton>
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <ComponentSkeleton showSkeleton={isLoading}>
              <Controller
                name="numero"
                control={control}
                defaultValue={numero}
                render={props => (
                  <CustomInput
                    labelText="Numero"
                    id="numero"
                    formControlProps={{
                      fullWidth: true
                    }}
                    {...props}
                  />
                )}
              />
            </ComponentSkeleton>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <ComponentSkeleton showSkeleton={isLoading}>
              <Controller
                name="bairro"
                control={control}
                defaultValue={bairro}
                render={props => (
                  <CustomInput
                    labelText="Bairro"
                    id="bairro"
                    formControlProps={{
                      fullWidth: true
                    }}
                    {...props}
                  />
                )}
              />
            </ComponentSkeleton>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <ComponentSkeleton showSkeleton={isLoading}>
              <Controller
                name="complemento"
                control={control}
                defaultValue={complemento}
                render={props => (
                  <CustomInput
                    labelText="Complemento"
                    id="complemento"
                    formControlProps={{
                      fullWidth: true
                    }}
                    {...props}
                  />
                )}
              />
            </ComponentSkeleton>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <ComponentSkeleton showSkeleton={isLoading}>
              <Controller
                name="ativo"
                control={control}
                defaultValue={ativo}
                render={props => (
                  <CustomSwitch
                    labelText="Ativo"
                    id="ativo"
                    switchProps={{
                      color: "primary"
                    }}
                    {...props}
                  />
                )}
              />
            </ComponentSkeleton>
          </GridItem>
        </GridContainer>
      </form>
    </CadastroPage>
  );
}

ContratanteForm.propTypes = {
  dadosContratante: PropTypes.object
};
