import { omit } from "lodash";
import { doResquestGraphql } from "../helpers/graphql";
import { Ability } from '@casl/ability';

const usuarioSubsection = `
  id
  nome
  email
  telefone
  ativo
  ultimaAtividade
`;

export async function getListaUsuarios(options = {}) {
  const { limit = 0, offset = 0, where, order, fields } = options;
  const query = `
    query getUsuarios($queryOptions: QueryOptions) {
      usuarios(queryOptions: $queryOptions) {
        ${fields ? fields : usuarioSubsection}
      }
    }
  `;

  const variables = {
    queryOptions: {
      limit,
      offset,
      where,
      order
    }
  };

  if (!limit) delete variables.queryOptions.limit;
  if (!offset) delete variables.queryOptions.offset;

  const resultado = await doResquestGraphql(query, variables);
  if (resultado.status && resultado.data && resultado.data.usuarios)
    return resultado.data.usuarios;
  return;
}

export async function getQtdeUsuarios(where) {
  const query = `
    query usuariosCount($queryOptions: QueryOptions) {
      usuariosCount(queryOptions: $queryOptions)
    }
  `;

  const variables = {
    queryOptions: {
      where
    }
  };

  const retorno = await doResquestGraphql(query, variables);
  if (retorno && retorno.status) return retorno.data.usuariosCount;
  return 0;
}

export async function deleteUsuario(id) {
  const query = `
    mutation deleteUsuario($id: ID!) {
      deleteUsuario(id: $id)
    }
  `;

  const variables = {
    id
  };

  const retorno = await doResquestGraphql(query, variables);
  if (retorno && retorno.status) return retorno.data.deleteUsuario;
  return false;
}

export async function usuarioEmailExiste(email) {
  const query = `
    query doUsuarioExiste($email: String!) {
      usuarioEmailExiste(email: $email)
    }
  `;

  const variables = {
    email
  };

  const retorno = await doResquestGraphql(query, variables);
  if (retorno && retorno.status) return retorno.data.usuarioEmailExiste;
  return false;
}

export async function saveUpdateUsuario(usuario) {
  if (usuario.id)
    return await updateUsuario({
      id: usuario.id,
      input: { ...omit(usuario, ["id", "password", "contratanteId"]) }
    });
  return await saveUsuario({
    input: { ...omit(usuario, ["id", "passwordConfirmacao"]) }
  });
}

async function saveUsuario(values) {
  const query = `
    mutation doCreateUsuario($input: UsuarioCreateInput!) {
      createUsuario(input: $input) {
        ${usuarioSubsection}
      }
    }
  `;

  const retorno = await doResquestGraphql(query, values);
  if (retorno.status && retorno.data && retorno.data.createUsuario)
    return retorno.data.createUsuario;
  return;
}

async function updateUsuario(values) {
  const query = `
        mutation doUpdateUsuario($id: ID!, $input: UsuarioUpdateInput!) {
          updateUsuario(id: $id, input: $input) {
            ${usuarioSubsection}
          }
        }
      `;

  const retorno = await doResquestGraphql(query, values);
  if (retorno.status && retorno.data && retorno.data.updateUsuario)
    return retorno.data.updateUsuario;
  return;
}

export async function getUsuario(id) {
  const query = `
    query getUsuario($id: ID!) {
      usuario(id: $id) {
        ${usuarioSubsection}
      }
    }
  `;

  const variables = {
    id
  };

  const retorno = await doResquestGraphql(query, variables);
  if (retorno.status && retorno.data && retorno.data.usuario)
    return retorno.data.usuario;
  return;
}

export async function doLogin({ email, password, permaneceLogado = false }) {
  const query = `
    mutation getCreateToken($email: String!, $password: String!, $permaneceLogado: Boolean!) {
      createToken(email: $email, password: $password, permaneceLogado: $permaneceLogado) {
        token
        usuario {
          id
          nome
          email
          telefone
          caslRules
          contratanteId
        }
      }
    }
  `;

  const variables = {
    email,
    password,
    permaneceLogado
  };

  const retorno = await doResquestGraphql(query, variables);
  if (retorno.status && retorno.data && retorno.data.createToken) {
    const { token, usuario } = retorno.data.createToken;
    if (usuario.caslRules) {
      usuario.permissoes = new Ability(JSON.parse(usuario.caslRules));
    }
    return { token, usuario };
  }
  return;
}

export async function validaUsuarioSenha(senhaAtual) {
  const query = `
    query doValidaUsuarioSenha($senhaAtual: String!) {
      usuarioValidaSenhaAtual(senhaAtual: $senhaAtual)
    }
  `;

  const variables = {
    senhaAtual
  };

  const retorno = await doResquestGraphql(query, variables);
  if (retorno.status && retorno.data && retorno.data.usuarioValidaSenhaAtual)
    return retorno.data.usuarioValidaSenhaAtual;
  return;
}

export async function salvarUsuarioNovaSenha(novaSenha, token) {
  const query = `
    mutation doUsuarioAtualizarSenha($novaSenha: String!, $token: String!) {
      usuarioAtualizarSenha(novaSenha: $novaSenha, token: $token)
    }
  `;

  const variables = {
    novaSenha,
    token
  };

  return doResquestGraphql(query, variables);
}

export async function doCreateTokenAutoLogin(tempToken) {
  const query = `
    mutation doCreateTokenAutoLogin($tempToken: String!) {
      createTokenAutoLogin(tempToken: $tempToken) {
        token
        usuario {
          id
          nome
          email
          telefone
          caslRules
          contratanteId
        }
        acao
      }
    }
  `;

  const variables = {
    tempToken
  };

  const retorno = await doResquestGraphql(query, variables);

  if (retorno.status && retorno.data && retorno.data.createTokenAutoLogin) {
    const { token, usuario, acao } = retorno.data.createTokenAutoLogin;
    if (usuario.caslRules) {
      usuario.permissoes = new Ability(JSON.parse(usuario.caslRules));
    }
    return { token, usuario, acao };
  }
  return;
}

export async function usuarioAtualizarSenhaRecuperacao(novaSenha, token) {
  const query = `
    mutation doUsuarioAtualizarSenhaRecuperacao($novaSenha: String!) {
      usuarioAtualizarSenhaRecuperacao(novaSenha: $novaSenha)
    }
  `;

  const variables = {
    novaSenha,
    token
  };

  return doResquestGraphql(query, variables);
}

export async function usuarioRecuperarSenha(email) {
  const query = `
    query doUsuarioRecuperarSenha($email: String!) {
      usuarioRecuperarSenha(email: $email)
    }
  `;

  const variables = {
    email
  };

  const retorno = await doResquestGraphql(query, variables);
  if (retorno && retorno.status) return retorno.data.usuarioRecuperarSenha;
  return false;
}