import React, { Fragment } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Link from "@material-ui/icons/Link";
import LinkOff from "@material-ui/icons/LinkOff";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green, red } from "@material-ui/core/colors";
// import Button from '@material-ui/core/Button';
import Fab from "@material-ui/core/Fab";
import { filialGerenciadorTestarConexao } from "services/mobileFilialGerenciador";
import { SnackbarContent } from "@material-ui/core";
import { useConfiguracoesBasicasContext } from "customHooks/ConfiguracoesBasicasContext";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center"
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative"
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700]
    }
  },
  buttonError: {
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700]
    }
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  rootSnackbar: {
    maxWidth: 600
  },
  snackbar: {
    margin: theme.spacing(1)
  },
  iconColor: {
    colorPrimary: "#fff"
  }
}));

export default function TestarConexao({
  onChange,
  conexaoEstabelecida,
  validaDados
}) {
  const classes = useStyles();

  const [loading, setLoading] = React.useState(false);
  const [sucesso, setSucesso] = React.useState(conexaoEstabelecida);
  const [msgRetorno, setMsgRetorno] = React.useState(
    conexaoEstabelecida === true
      ? "Conexão já foi testada com sucesso"
      : "Conexão não testada"
  );

  const { filial } = useConfiguracoesBasicasContext();
  const { identificadorApp } = filial;

  const buttonClassname = clsx({
    [classes.buttonSuccess]: sucesso,
    [classes.buttonError]: !sucesso
  });

  const snackbarClassname = clsx({
    [classes.snackbar]: true,
    [classes.buttonSuccess]: sucesso,
    [classes.buttonError]: !sucesso
  });

  const handleButtonClick = async dadosFormulario => {
    if (!loading) {
      setSucesso(false);
      setLoading(true);
      const { dados } = dadosFormulario;
      const {
        tipoIntegracao,
        database,
        username,
        password,
        hostname,
        porta,
        portaMssql
      } = dados;

      const dadosConexao = {
        dialect: tipoIntegracao,
        database: database,
        username: username,
        password: password,
        host: hostname
      };
      if (!["sqlite", "mssql"].includes(tipoIntegracao)) {
        dadosConexao.port = parseInt(porta);
      } else if (["mssql"].includes(tipoIntegracao) && Number(portaMssql) > 0) {
        dadosConexao.port = portaMssql;
      }
      const retorno = await filialGerenciadorTestarConexao(
        identificadorApp,
        dadosConexao
      );
      if (retorno) {
        const { status, msg, erro } = retorno;
        setSucesso(!!status && !erro);
        setMsgRetorno(msg || erro || "");
        if (onChange) onChange(!!status && !erro);
      }
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Fab
            classes={{
              root: buttonClassname
            }}
            aria-label="Situação"
            onClick={validaDados(handleButtonClick)}
          >
            {sucesso ? (
              <Link style={{ color: "#fff" }} />
            ) : (
              <LinkOff style={{ color: "#fff" }} />
            )}
          </Fab>
          {loading && (
            <CircularProgress size={68} className={classes.fabProgress} />
          )}
        </div>
        <div className={classes.wrapper}>
          <Button
            variant="contained"
            // color={sucesso ? "sucesso" : "danger"}
            className={buttonClassname}
            disabled={loading}
            onClick={validaDados(handleButtonClick)}
          >
            Testar Conexão
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </div>
      {msgRetorno && (
        <div className={classes.rootSnackbar}>
          <SnackbarContent className={snackbarClassname} message={msgRetorno} />
        </div>
      )}
    </Fragment>
  );
}

TestarConexao.propTypes = {
  onChange: PropTypes.func,
  conexaoEstabelecida: PropTypes.bool,
  validaDados: PropTypes.func
};
