import { doResquestGraphql } from "../helpers/graphql";

const dispositivoSubsection = `
    id
    uuid
    dispositivo
    plataforma
    versaoApp
    dataAtivacao
    dataUltimoAcesso
    ativo
    bloqueado
    token
    filialId
    apelido
    email
    telefone
`;

export async function getListaMobileDispositivos(options = {}) {
  const { limit = 0, offset = 0, where, order, fields } = options;
  const query = `
    query getMobileDispositivos($queryOptions: QueryOptions) {
        mobileDispositivos(queryOptions: $queryOptions) {
        ${fields || dispositivoSubsection}
      }
    }
  `;

  const variables = {
    queryOptions: {
      limit,
      offset,
      where,
      order
    }
  };

  if (!limit) delete variables.queryOptions.limit;
  if (!offset) delete variables.queryOptions.offset;

  const resultado = await doResquestGraphql(query, variables);
  if (resultado.status && resultado.data && resultado.data.mobileDispositivos)
    return resultado.data.mobileDispositivos;
  return;
}

export async function getQtdeMobileDispositivos(where) {
  const query = `
    query getQtdeMobileDispositivosCount($queryOptions: QueryOptions) {
        mobileDispositivosCount(queryOptions: $queryOptions)
    }
  `;

  const variables = {
    queryOptions: {
      ...where
    }
  };

  const retorno = await doResquestGraphql(query, variables);
  if (retorno && retorno.status) return retorno.data.mobileDispositivosCount;
  return 0;
}

export async function deleteMobileDispositivo(id) {
  const query = `
    mutation deleteMobileDispositivo($id: ID!) {
      deleteMobileDispositivo(id: $id)
    }
  `;

  const variables = {
    id
  };

  const retorno = await doResquestGraphql(query, variables);
  if (retorno && retorno.status) return retorno.data.deleteMobileDispositivo;
  return false;
}

export async function bloqueiaMobileDispositivo(id) {
  const query = `
    mutation bloqueiaMobileDispositivo($id: ID!) {
      bloqueiaMobileDispositivo(id: $id)
    }
  `;

  const variables = {
    id
  };

  const retorno = await doResquestGraphql(query, variables);
  if (retorno && retorno.status) return retorno.data.bloqueiaMobileDispositivo;
  return false;
}

export async function updateMobileDispositivo(values) {
  const query = `
    mutation doUpdateMobileDispositivo($input: MobileDispositivoUpdateInput!) {
      updateMobileDispositivo(input: $input) {
        ${dispositivoSubsection}
      }
    }
  `;

  const variables = {
    input: values
  };

  const retorno = await doResquestGraphql(query, variables);
  if (retorno.status && retorno.data && retorno.data.updateMobileDispositivo)
    return retorno.data.updateMobileDispositivo;
  return;
}

export async function getMobileDispositivo(id) {
  const query = `
    query getMobileDispositivo($id: ID!) {
        mobileDispositivo(id: $id) {
        ${dispositivoSubsection}
      }
    }
  `;

  const variables = {
    id
  };

  const retorno = await doResquestGraphql(query, variables);
  if (retorno.status && retorno.data && retorno.data.mobileDispositivo)
    return retorno.data.mobileDispositivo;
  return;
}
