import React from "react";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import useConfiguracaoDados from "customHooks/ConfiguracaoDadosHook";
import ConfiguracaoUsuario from "./ConfiguracaoUsuario";
import ConfiguracaoCliente from "./ConfiguracaoCliente";
import ConfiguracaoPedido from "./ConfiguracaoPedido";
import ConfiguracaoProduto from "./ConfiguracaoProduto";
import ConfiguracaoOrdemServico from "./ConfiguracaoOrdemServico";

const useStyles = makeStyles(theme => ({
  progress: {
    margin: theme.spacing(2)
  }
}));

export default function ConfiguracaoBasica() {
  const classes = useStyles();
  const { handleSubmit, reset, control, watch } = useForm();

  const { isLoading, doSaveDadosConfiguracao } = useConfiguracaoDados({
    tipo: "configuracaoBasica",
    reset
  });

  const onSubmit = async data => {
    const { dados } = data;
    await doSaveDadosConfiguracao({
      dados: JSON.stringify(dados),
      tipo: "configuracaoBasica"
    });
  };

  return (
    <React.Fragment>
      <ConfiguracaoUsuario isLoading={isLoading} control={control} />
      <ConfiguracaoCliente isLoading={isLoading} control={control} />
      <ConfiguracaoProduto
        isLoading={isLoading}
        control={control}
        watch={watch}
      />
      <ConfiguracaoPedido isLoading={isLoading} control={control} />
      <ConfiguracaoOrdemServico isLoading={isLoading} control={control} />

      <Button color="info" onClick={handleSubmit(onSubmit)}>
        Salvar
      </Button>
    </React.Fragment>
  );
}

ConfiguracaoBasica.propTypes = {};
