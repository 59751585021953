import { useState, useEffect } from "react";

function useCustomTablePaginationFetch(doFetch, doFetchQtde) {
  const [isLoading, setIsLoading] = useState(true);
  // const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState(null);
  const [pageRows, setPageRows] = useState(20);
  const [dados, setDados] = useState({
    data: [],
    totalCount: 0,
    page
  });

  async function fetchDados() {
    // setRefresh(false);
    const qtdeTotal = await doFetchQtde();
    const resultado = await doFetch({
      limit: pageRows,
      offset: page * pageRows,
      order: order || undefined
    });
    setIsLoading(true);
    if (resultado) {
      setDados({
        data: resultado,
        totalCount: qtdeTotal,
        page
      });
      // if (!page) setPage(1);
    }
    setIsLoading(false);
  }

  const doRefresh = async () => fetchDados();

  useEffect(() => {
    fetchDados();
  }, [page, order, pageRows]);

  return [
    dados,
    isLoading,
    pageRows,
    setPage,
    setOrder,
    setPageRows,
    doRefresh
  ];
}
export { useCustomTablePaginationFetch };
