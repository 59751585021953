import React from "react";
import PropTypes from "prop-types";
import TabSelect from "./tabSelectInsert/TabSelect";
import InsertPadrao from "./tabSelectInsert/tabInsert/InsertPadrao";
import SomenteCamposPersonalizados from "./tabSelectInsert/somenteCamposPersonalizados/SomenteCamposPersonalizados";
import { ConfiguracaoDadosContext } from "customHooks/ConfiguracaoDadosHook";

export default function ConfiguracaoSQL() {
  const { configuracaoConst, isLoading } = React.useContext(
    ConfiguracaoDadosContext
  );

  const { campos, tagsInsert, someteCamposPersonalizados } = configuracaoConst;
  const render = React.useMemo(
    () =>
      campos ? (
        <TabSelect
          isLoading={isLoading}
          configuracaoConst={configuracaoConst}
        />
      ) : tagsInsert ? (
        <InsertPadrao
          configuracaoConst={configuracaoConst}
          isLoading={isLoading}
        />
      ) : someteCamposPersonalizados === true ? (
        <SomenteCamposPersonalizados isLoading={isLoading} />
      ) : null,
    [configuracaoConst, isLoading]
  );
  return render;
}

ConfiguracaoSQL.propTypes = {};
