import React from "react";
import PropTypes from "prop-types";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap"
  }
}));

function InsertExemplo({ exemplo }) {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader className={classes.header}>
        <strong>Exemplo de Instrução</strong>
      </CardHeader>
      <CardBody>{exemplo}</CardBody>
    </Card>
  );
}

InsertExemplo.propTypes = {
  exemplo: PropTypes.string
};

export default InsertExemplo;
