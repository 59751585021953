import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import Page from "components/CustomPages/Page";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import ComponentSkeleton from "components/ComponentSkeleton/ComponentSkeleton";

function DadosExecucaoAgendada({ tipo }) {
  const [dadosAgenda, setDadosAgenda] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);

  // const { getDadosAgenda } = useSocketEmitService();

  // React.useEffect(() => {
  //   const loadDadosAgenda = async () => {
  //     const retorno = await getDadosAgenda(tipo);
  //     if (retorno) await setDadosAgenda(retorno);
  //     await setIsLoading(false);
  //   };
  //   loadDadosAgenda();
  // }, []);

  const { isExecutando = false, proximaExecucao, ultimaExecucao } = dadosAgenda;
  return (
    <Page labelTituloHeader="Agenda de checagem da sincronização">
      <GridContainer>
        <GridItem xs={3} sm={3} md={3}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <CustomSwitch
              labelText="Em andamento?"
              switchProps={{
                checked: isExecutando === true,
                readOnly: true
              }}
            />
          </ComponentSkeleton>
        </GridItem>
        <GridItem xs={4} sm={4} md={4}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <CustomInput
              labelText="Data da ultima checagem"
              inputProps={{
                readOnly: true,
                value: ultimaExecucao
                  ? moment(ultimaExecucao).format("DD/MM/YYYY - HH:mm:ss")
                  : ""
              }}
              formControlProps={{
                fullWidth: true
              }}
            />
          </ComponentSkeleton>
        </GridItem>
        <GridItem xs={4} sm={4} md={4}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <CustomInput
              labelText="Data da próxima checagem"
              inputProps={{
                readOnly: true,
                value: proximaExecucao
                  ? moment(proximaExecucao).format("DD/MM/YYYY - HH:mm:ss")
                  : ""
              }}
              formControlProps={{
                fullWidth: true
              }}
              readOnly={true}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>
    </Page>
  );
}

DadosExecucaoAgendada.propTypes = {
  tipo: PropTypes.string
};

export default DadosExecucaoAgendada;
