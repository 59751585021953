import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

import FormTrocarSenhaAtual from "./FormTrocarSenhaAtual";
import FormTrocarSenhaNova from "./FormTrocarSenhaNova";
import CadastroPage from "components/CustomPages/CadastroPage";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Notificacoes from "components/Notificacoes/Notificacoes";
import {
  validaUsuarioSenha,
  salvarUsuarioNovaSenha
} from "services/usuario";

const drawerWidth = 500;

const useStyles = makeStyles(() => ({
  drawerPaper: {
    width: drawerWidth
  }
}));

function FormTrocarSenha({ isOpen, onClose }) {
  const classes = useStyles();

  const [tokenSenhaConfirmada, setTokenSenhaConfirmada] = React.useState("");

  const handleValidarSenhaAtual = async senhaAtual => {
    const token = await validaUsuarioSenha(senhaAtual);
    if (token) {
      Notificacoes.success("Senha confirmada");
      return setTokenSenhaConfirmada(token);
    }
    Notificacoes.error("Senha inválida");
  };

  const handleSalvarNovaSenha = async novaSenha => {
    const senhaSalva = await salvarUsuarioNovaSenha(
      novaSenha,
      tokenSenhaConfirmada
    );
    if (senhaSalva && senhaSalva.status === true && senhaSalva.data) {
      if (senhaSalva.data.usuarioAtualizarSenha === true) {
        Notificacoes.success("Senha alterada com sucesso");
        return handleOnClose();
      }
      Notificacoes.error("Sessão expirada valide novamente a senha atual!");
      return setTokenSenhaConfirmada("");
    }
    Notificacoes.error(
      "Ocorreu um erro ao tentar efetuar a atualização da senha"
    );
  };

  const handleOnClose = () => {
    setTokenSenhaConfirmada("");
    onClose();
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={isOpen}
      ModalProps={{
        keepMounted: true // Better open performance on mobile.
      }}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <CadastroPage labelTituloHeader="Alterar senha">
        <GridContainer justify="center">
          <GridItem xm>
            {!tokenSenhaConfirmada ? (
              <FormTrocarSenhaAtual
                validarSenhaAtual={handleValidarSenhaAtual}
                onClose={handleOnClose}
              />
            ) : (
              <FormTrocarSenhaNova
                salvarNovaSenha={handleSalvarNovaSenha}
                onClose={handleOnClose}
              />
            )}
          </GridItem>
        </GridContainer>
      </CadastroPage>
    </SwipeableDrawer>
  );
}

FormTrocarSenha.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default FormTrocarSenha;
