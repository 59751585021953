import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import QRCode from "qrcode.react";

import Modal from "@material-ui/core/Modal";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

function GerarQRCode({ opened = false, valor, handleClose }) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={opened}
      onClose={handleClose}
    >
      <div style={modalStyle} className={classes.paper}>
        <GridContainer justify="center">
          <GridItem>
            <h3 id="simple-modal-title">Código QRCode</h3>
          </GridItem>
          <GridItem>
            <QRCode value={valor} size={180} />
          </GridItem>
          ,
        </GridContainer>
      </div>
    </Modal>
  );
}

GerarQRCode.propTypes = {
  opened: PropTypes.bool,
  valor: PropTypes.string,
  handleClose: PropTypes.func
};

export default GerarQRCode;
