import React from "react";
import { useController } from "react-hook-form";
import { get } from "lodash";

import ComponentSkeleton from "components/ComponentSkeleton/ComponentSkeleton";
import CustomInput from "components/CustomInput/CustomInput";

export default function FormInput({
  isLoading = true,
  label,
  control,
  defaultValue,
  name,
  rules,
  fullWidth = true,
  errors = {},
  ...otherProps
}) {
  const {
    field: { ref, ...inputProps },
    meta: { invalid }
  } = useController({
    name,
    control,
    rules,
    defaultValue
  });

  return (
    <ComponentSkeleton showSkeleton={isLoading}>
      <CustomInput
        labelText={label}
        formControlProps={{
          fullWidth: fullWidth
        }}
        error={invalid}
        errorMessage={get(errors, `${name}.message`, "")}
        {...otherProps}
        {...inputProps}
        inputRef={ref}
      />
    </ComponentSkeleton>
  );
}
