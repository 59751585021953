import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { debounce } from "lodash";

import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import CustomInput from "components/CustomInput/CustomInput";

import "../../assets/css/login.css";
import { authContext } from "contexts/AuthContext";
import { doLogin } from "services/usuario";
import Notificacoes from "components/Notificacoes/Notificacoes";
import CustomBackdrop from "components/CustomBackdrop/CustomBackdrop";
import { usuarioEmailExiste } from "services/usuario";

import logo from "assets/img/logoBannerTransparent.gif";

const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  form: {
    width: "80%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export default function LoginForm() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [emailValidado, setEmailValidado] = useState(false);

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    setError,
    clearErrors
  } = useForm({
    mode: "onChange"
  });

  const authValues = useContext(authContext);

  React.useEffect(() => register({ name: "permaneceLogado" }), []);

  const onSubmit = async data => {
    try {
      setLoading(true);
      const retorno = await doLogin(data);
      if (retorno) {
        authValues.setAuthStatus(retorno.usuario, retorno.token, "");
        return;
      }
    } catch (error) {}
    setLoading(false);
    Notificacoes.error("Email ou senha inválidos");
  };

  const checkEmailExiste = debounce(async valor => {
    if (!valor) return setEmailValidado(false);
    const retorno = await usuarioEmailExiste(valor);
    setEmailValidado(!!retorno);
    if (!retorno) setError("email", "notMatch", "Email não cadastrado!");
    else clearErrors(["email"]);
  }, 300);

  return (
    <div className="limiter">
      <div className="container-login100">
        <div className="wrap-login100">
          <div
            className="login100-form-title"
            style={{ backgroundImage: "url(/resources/images/bg-01.jpg)" }}
          >
            <img src={logo} style={{ width: 500, height: 100 }} />
            {/* <span className="login100-form-title-1">Justo Pedido Mobile</span> */}
          </div>
          <div className={classes.paper}>
            <div className={classes.form}>
              <CustomInput
                labelText="Email"
                id="email"
                inputProps={{
                  name: "email",
                  inputRef: register({ 
                    required: "O email é requerido.",
                    validate: checkEmailExiste
                  }),
                  defaultValue: ""
                }}
                formControlProps={{
                  fullWidth: true
                }}
                error={!!errors.email}
                errorMessage={errors.email ? errors.email.message : ""}
              />

              <CustomInput
                labelText="Senha"
                id="password"
                inputProps={{
                  name: "password",
                  inputRef: register({ required: "A senha é requerida." }),
                  defaultValue: "",
                  type: "password",
                  disabled: !emailValidado
                }}
                formControlProps={{
                  fullWidth: true
                }}
                error={!!errors.password}
                errorMessage={errors.password ? errors.password.message : ""}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    name="permaneceLogado"
                    // ref={register()}
                    color="primary"
                    defaultChecked={false}
                    disabled={!emailValidado}
                    onChange={event =>
                      setValue("permaneceLogado", event.target.checked)
                    }
                  />
                }
                label="Permanecer Logado"
              />
              <Button
                disabled={!emailValidado}
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleSubmit(onSubmit)}
              >
                Efetuar Login
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/esqueciminhasenha" variant="body2">
                    Esqueceu a Senha?
                  </Link>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <CustomBackdrop open={loading} />
    </div>
  );
}
