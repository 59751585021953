import React, { Fragment, useContext } from "react";
import { authContext } from "contexts/AuthContext";
import { Switch, Route, Redirect } from "react-router-dom";

import Admin from "layouts/Admin";
import publicRoutes from "./publicRoutes";
import RecuperarSenha from "views/Usuario/recuperarSenha/RecuperarSenha";
import LandingPage from "views/LandingPage/LandingPage";
import AdminGerenciador from "layouts/AdminGerenciador";

export default function Routes() {
  const { state } = useContext(authContext);
  const { dadosUsuario, acao } = state;
  const { id } = dadosUsuario;

  const usuarioAutenticado = id && id > 0;
  const recuperarSenha = usuarioAutenticado && acao === "RECUPERAR_SENHA";

  return recuperarSenha ? (
    <Switch>
      <Route
        path={"/recuperarsenha"}
        component={RecuperarSenha}
        key={"recuperarsenha"}
      />
      {/* <RecuperarSenha /> */}
      <Redirect from="/" to="/recuperarsenha" />
      <Redirect to="/recuperarsenha" />
    </Switch>
  ) : usuarioAutenticado ? (
    <Switch>
      <Route path={"/"} component={LandingPage} exact={true} />
      <Route path={"/admin"} component={Admin} />
      <Route path={"/gerenciador/:id"} component={AdminGerenciador} />
      <Redirect from="/login" to="/admin/home" />
      <Redirect from="/admin" to="/admin/home" />
      <Redirect from="/" />
    </Switch>
  ) : (
    <Switch>
      {publicRoutes.map((prop, key) => (
        <Route
          path={prop.path}
          component={prop.component}
          key={key}
          exact={true}
        />
      ))}
      <Redirect from="/recuperarsenha" to="/login" />
      <Redirect to="/" />
    </Switch>
  );
}
