/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import Link from "@material-ui/core/Link";
import GetAppIcon from '@material-ui/icons/GetApp';
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.js";

import { isUndefined, isFunction } from "lodash";

import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";
import { authContext } from "contexts/AuthContext";
import { validaPermissaoUsuario } from "helpers/usuarioPermissoes.helper";

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const classes = useStyles();
  
  const {
    state: { dadosUsuario = {} }
  } = React.useContext(authContext);

  const { permissoes } = dadosUsuario;

  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }
  const { color, logo, image, logoText, routes, mostraDownload = true } = props;
  let links = (
    <List className={classes.list}>
      {routes.filter(r => isFunction(r.showSiderbar) ? r.showSiderbar(dadosUsuario) : r.showSiderbar).filter(
        route =>
          isUndefined(route.permissoes) ||
          validaPermissaoUsuario(permissoes, route.permissoes)
      ).map((prop, key) => {
        const path = `${prop.layout || ''}${prop.path}`;
        const listItemClasses = classNames({
          [" " + classes[color]]: activeRoute(path)
        });
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(path)
        });
        return (
          <NavLink
            to={path}
            className={classes.item}
            activeClassName="active"
            key={key}
          >
            <ListItem button className={classes.itemLink + listItemClasses}>
              {prop.icon && (
                typeof prop.icon === "string" ? (
                  <Icon
                    className={classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive
                    })}
                  >
                    {prop.icon}
                  </Icon>
                ) : (
                  <prop.icon
                    className={classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive
                    })}
                  />
                )
              )}
              <ListItemText
                primary={props.rtlActive ? prop.rtlName : prop.name}
                className={classNames(classes.itemText, whiteFontClasses, {
                  [classes.itemTextRTL]: props.rtlActive
                })}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        );
      })}
      {mostraDownload && (
        <>
        <Link target="_blank" href="https://drive.google.com/file/d/1l2tWH2GAYc1Zrjn2V0-tuUh5IWKRtZ0C/view?usp=sharing">
          <ListItem button className={classes.itemLink}>
            <GetAppIcon 
              className={classNames(classes.itemIcon)}
            />
            <ListItemText
              primary={"Download Gerenciador Instalador"}
              className={classNames(classes.itemText)}
              disableTypography={true}
            />
          </ListItem>  
        </Link>
        <Link target="_blank" href="https://drive.google.com/file/d/1ks1HiMhTMChfHuXwNayTEE_6N537kHDh/view?usp=sharing">
          <ListItem button className={classes.itemLink}>
            <GetAppIcon 
              className={classNames(classes.itemIcon)}
            />
            <ListItemText
              primary={"Download Gerenciador Executável"}
              className={classNames(classes.itemText)}
              disableTypography={true}
            />
          </ListItem>  
        </Link>
        </>
      )}
    </List>
  );
  let brand = (
    <div className={classes.logo}>
      <a
        // href="https://www.creative-tim.com?ref=mdr-sidebar"
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive
        })}
        target="_blank"
      >
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
        {logoText}
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool
};
