import React from "react";
import PropTypes from "prop-types";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import StopIcon from "@material-ui/icons/Stop";
import { red } from "@material-ui/core/colors";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Notificacoes from "components/Notificacoes/Notificacoes";
import { useConfirmation } from "components/CustomConfirmationDialog/ConfirmationService";

import { warningColor } from "assets/jss/material-dashboard-react.js";

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  cancelButton: {
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700]
    }
  },
  limparDadosServidorButton: {
    backgroundColor: warningColor[0],
    "&:hover": {
      backgroundColor: warningColor[2]
    }
  },
  labelText: {
    textShadow: "0px 8px 11px #949494"
  }
}));

function ButtonsSincronizacao({
  executando,
  doSincronizacao,
  doSincronizacaoCancelar,
  limparDadosServidor,
  somenteSincronizarTodos = false
}) {
  const classes = useStyles();
  const confirm = useConfirmation();

  const handleSincronizar = reenviarTodos => () => {
    confirm({
      variant: "danger",
      catchOnCancel: false,
      title: "Deseja efetuar uma sincronização?"
    }).then(async () => doSincronizar(reenviarTodos));
  };

  const doSincronizar = async reenviarTodos => {
    const retorno = await doSincronizacao(reenviarTodos);
    if (retorno === true) {
      Notificacoes.success("Solicitado o inicio da sincronização");
    } else
      Notificacoes.error(
        "Não foi possível solicitar o inicio da sincronização"
      );
  };

  const handleCancelar = () => {
    confirm({
      variant: "danger",
      catchOnCancel: false,
      title: "Deseja cancelar a sincronização?"
    }).then(async () => doCancelar());
  };

  const doCancelar = async () => {
    const retorno = await doSincronizacaoCancelar();
    if (retorno === true) {
      Notificacoes.success("Solicitado o cancelamento da sincronização");
    } else
      Notificacoes.error(
        "Não foi possível solicitar o cancelamento da sincronização"
      );
  };

  const handleLimparDadosServidor = () => {
    confirm({
      variant: "danger",
      catchOnCancel: false,
      title: "Confirma a exclusão dos dados enviados para o servidor?"
    }).then(async () => doLimparDadosServidor());
  };

  const doLimparDadosServidor = async () => {
    const retorno = await limparDadosServidor();
    if (retorno === true) {
      Notificacoes.success("Solicitada limpeza dos dados enviados ao Servidor");
    } else
      Notificacoes.error(
        "Não foi possível solicitar a limpeza dos dados do servidor"
      );
  };

  return (
    <div className={classes.root}>
      <GridContainer justify="center" alignItems="center">
        {!somenteSincronizarTodos && (
          <GridItem>
            <GridContainer
              direction="column"
              justify="center"
              alignItems="center"
              alignContent="center"
            >
              <GridItem>
                <Fab
                  onClick={handleSincronizar(false)}
                  color="secondary"
                  disabled={executando === true}
                >
                  <PlayArrowIcon />
                </Fab>
              </GridItem>
              <GridItem>
                <span className={classes.labelText}>
                  Sincronizar dados Novos/Atualizados
                </span>
              </GridItem>
            </GridContainer>
          </GridItem>
        )}
        <GridItem>
          <GridContainer
            direction="column"
            justify="center"
            alignItems="center"
          >
            <GridItem>
              <Fab
                onClick={handleSincronizar(true)}
                color="secondary"
                disabled={executando === true}
              >
                <PlayArrowIcon />
              </Fab>
            </GridItem>
            <GridItem>
              <span className={classes.labelText}>Sincronizar Todos</span>
            </GridItem>
          </GridContainer>
        </GridItem>
        {!somenteSincronizarTodos && (
          <>
            <GridItem>
              <GridContainer
                direction="column"
                justify="center"
                alignItems="center"
              >
                <GridItem>
                  <Fab
                    disabled={executando === false}
                    onClick={handleCancelar}
                    classes={{
                      root: classes.cancelButton
                    }}
                  >
                    <StopIcon style={{ color: "#fff" }} />
                  </Fab>
                </GridItem>
                <GridItem>
                  <span className={classes.labelText}>Cancelar</span>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem>
              <GridContainer
                direction="column"
                justify="center"
                alignItems="center"
              >
                <GridItem>
                  <Fab
                    disabled={executando === true}
                    onClick={handleLimparDadosServidor}
                    classes={{
                      root: classes.limparDadosServidorButton
                    }}
                  >
                    <StopIcon style={{ color: "#fff" }} />
                  </Fab>
                </GridItem>
                <GridItem>
                  <span className={classes.labelText}>
                    Limpar dados no Servidor
                  </span>
                </GridItem>
              </GridContainer>
            </GridItem>
          </>
        )}
      </GridContainer>
    </div>
  );
}

ButtonsSincronizacao.propTypes = {
  tipo: PropTypes.string,
  executando: PropTypes.bool,
  doSincronizacao: PropTypes.func,
  doSincronizacaoCancelar: PropTypes.func,
  doReiniciar: PropTypes.func
};

export default ButtonsSincronizacao;
