import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Button from "components/CustomButtons/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";

import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";

const useStyles = makeStyles(theme => ({
  divCenter: {
    // alignContent: "center"
  },
  button: {
    margin: theme.spacing(1)
  }
}));

function FormTrocarSenhaNova({ onClose, salvarNovaSenha }) {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    errors,
    getValues,
    triggerValidation,
    formState
  } = useForm();

  const onSubmit = async data => {
    const { password } = data;
    salvarNovaSenha(password);
  };

  const compararConfirmacaoSenha = value => {
    const { password = "" } = getValues();
    return value && value === password;
  };

  const validaSenha = value => {
    if (
      value &&
      formState.touched.findIndex(t => t === "passwordConfirmacao") > 0
    ) {
      triggerValidation({ name: "passwordConfirmacao" });
    }
    // return;
  };

  const getConfirmacaoMessage = () => {
    if (!errors.passwordConfirmacao) return "";
    const { type = "", message } = errors.passwordConfirmacao;
    if (type === "compararConfirmacaoSenha")
      return "A confirmação da senha não esta igual a senha";
    return message;
  };

  return (
    <div className={classes.divCenter}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Nova senha"
            id="password"
            inputProps={{
              name: "password",
              inputRef: register({
                required: "O campo da nova senha é requerido",
                validate: validaSenha
              }),
              type: "password"
            }}
            formControlProps={{
              fullWidth: true
            }}
            error={!!errors.password}
            errorMessage={errors.password ? errors.password.message : ""}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Confirmação da nova senha"
            id="passwordConfirmacao"
            inputProps={{
              name: "passwordConfirmacao",
              inputRef: register({
                required: "O campo de confirmação da nova senha é requerido",
                validate: compararConfirmacaoSenha
              }),
              type: "password"
            }}
            formControlProps={{
              fullWidth: true
            }}
            error={!!errors.passwordConfirmacao}
            errorMessage={getConfirmacaoMessage()}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem sm>
          <Button
            variant="contained"
            className={classes.button}
            color="danger"
            onClick={onClose}
            endIcon={<CancelIcon />}
          >
            Cancelar
          </Button>
        </GridItem>
        <GridItem sm>
          <Button
            variant="contained"
            className={classes.button}
            color="primary"
            onClick={handleSubmit(onSubmit)}
            endIcon={<SaveIcon />}
          >
            Salvar
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  );
}

FormTrocarSenhaNova.propTypes = {
  onClose: PropTypes.func,
  salvarNovaSenha: PropTypes.func
};

export default FormTrocarSenhaNova;
