import React from "react";
import PropTypes from "prop-types";
import { get, isFunction, isArray } from "lodash";
import moment from "moment";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useFieldArray, Controller } from "react-hook-form";

import CustomButton from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import FieldsPersonalizados from "./FieldsPersonalizados";
import Page from "components/CustomPages/Page";
import ComponentSkeleton from "components/ComponentSkeleton/ComponentSkeleton";
import { useConfiguracoesBasicasContext } from "customHooks/ConfiguracoesBasicasContext";

const fieldName = "mapeamento.personalizados";

function MapeamentoFieldsPersonalizados({
  isLoading,
  fieldsListar = [],
  ligacaoComFields = true,
  camposPersonalizadosEditavel,
  sempreEditavel = false,
  formMethods
}) {
  const { configuracaoBasica } = useConfiguracoesBasicasContext();

  const { control, errors, clearErrors, triggerValidation } = formMethods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName
  });

  const handleRemover = index => () => remove(index);

  const errosMapeamento = React.useMemo(() => get(errors, fieldName), [
    formMethods,
    errors
  ]);

  const permiteEditavel = React.useMemo(
    () =>
      isFunction(camposPersonalizadosEditavel)
        ? camposPersonalizadosEditavel({ configuracaoBasica })
        : camposPersonalizadosEditavel || sempreEditavel,
    [camposPersonalizadosEditavel, configuracaoBasica, sempreEditavel]
  );

  const handleValidaItem = fieldName => (valor = {}) => {
    if (ligacaoComFields === true && !valor.fieldRelacionado)
      return "O campo Field Relacionado é requerido";
    else if (!valor.label) return "O campo Label é requerido";
    else if (
      valor.tipo === "selecionavel" &&
      (!isArray(valor.valoresSelecionavel) || !valor.valoresSelecionavel.length)
    )
      return "O campo de Possíveis valores é requerido";
    return clearErrors([fieldName]);
  };

  const handleAdd = async () => {
    const fieldsValidar = fields.map((f, index) => `${fieldName}[${index}]`);
    let podeCriar = true;
    if (fieldsValidar.length)
      podeCriar = await triggerValidation(fieldsValidar);

    if (podeCriar)
      append({
        name: fieldName,
        id: `CP_${moment().format("YYYYMMDDHHmmss")}`,
        label: "",
        tipo: "texto",
        fieldRelacionado: "",
        mostraLista: false,
        editavel: sempreEditavel,
        editavelObrigatorio: false
      });
  };

  return (
    <Page labelTituloHeader="Campos Personalizados">
      {fields.map((item, index) => (
        <GridContainer key={item.id}>
          <GridItem xs>
            <ComponentSkeleton showSkeleton={isLoading}>
              <Controller
                control={control}
                name={`${fieldName}[${index}]`}
                rules={{
                  validate: handleValidaItem(`mapeamento_campo_${index}`)
                }}
                defaultValue={item}
                render={props => (
                  <FieldsPersonalizados
                    onRemover={handleRemover(index)}
                    ligacaoComFields={ligacaoComFields}
                    sempreEditavel={sempreEditavel}
                    options={fieldsListar}
                    error={!!get(errosMapeamento, `${index}`)}
                    errorMessage={get(errosMapeamento, `${index}.message`, "")}
                    permiteEditavel={permiteEditavel}
                    {...props}
                  />
                )}
              />
            </ComponentSkeleton>
          </GridItem>
        </GridContainer>
      ))}

      <CustomButton
        onClick={handleAdd}
        startIcon={<AddCircleIcon />}
        color="info"
      >
        Adicionar Campo Personalizado
      </CustomButton>
    </Page>
  );
}

MapeamentoFieldsPersonalizados.propTypes = {
  isLoading: PropTypes.bool,
  formMethods: PropTypes.any,
  mapeamento: PropTypes.object,
  fieldsListar: PropTypes.arrayOf(PropTypes.string),
  configuracaoCampo: PropTypes.object,
  camposPersonalizadosEditavel: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func
  ]),
  ligacaoComFields: PropTypes.bool,
  sempreEditavel: PropTypes.bool
};

export default MapeamentoFieldsPersonalizados;
