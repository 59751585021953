import React from "react";
import PropTypes from "prop-types";
import { keys, get, isFunction } from "lodash";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomSelect from "components/CustomSelect/CustomSelect";
import Page from "components/CustomPages/Page";
import { Controller } from "react-hook-form";
import { useConfiguracoesBasicasContext } from "customHooks/ConfiguracoesBasicasContext";
import ComponentSkeleton from "components/ComponentSkeleton/ComponentSkeleton";
import CustomInput from "components/CustomInput/CustomInput";

export default function MapeamentoFields({
  campos,
  field,
  fieldsListar,
  formMethods,
  isLoading
}) {
  const { errors, control } = formMethods;
  const errorsMapeamento = React.useMemo(() => get(errors, "mapeamento"), [
    errors
  ]);

  const { configuracaoBasica } = useConfiguracoesBasicasContext();

  const mapeamentoField =
    field && field !== "select" ? `mapeamento.${field}.` : `mapeamento.`;

  const checkCampoOpicional = valor =>
    isFunction(valor) ? valor({ configuracaoBasica }) === true : valor === true;

  const checkCampoVisivel = valor =>
    !valor ? true : valor({ configuracaoBasica }) === true;

  return (
    <Page labelTituloHeader="Mapeamento dos Campos">
      {keys(campos)
        .filter(k => checkCampoVisivel(campos[k].visivel))
        .map(k => (
          <GridContainer key={`mapeamento_campo_${k}`}>
            <GridItem xs={12} sm={12} md={8}>
              <ComponentSkeleton showSkeleton={isLoading}>
                <Controller
                  control={control}
                  name={`${mapeamentoField}${k}`}
                  rules={{
                    required: checkCampoOpicional(campos[k].opcional)
                      ? false
                      : `O campo ${campos[k].label} é requerido!`
                  }}
                  render={props => (
                    <CustomInput
                      labelText={campos[k].label}
                      selectProps={{
                        displayEmpty: true
                      }}
                      formControlProps={{
                        fullWidth: true,
                        required: !checkCampoOpicional(campos[k].opcional)
                      }}
                      // options={fieldsListar}
                      error={!!get(errorsMapeamento, k)}
                      errorMessage={get(errorsMapeamento, `${k}.message`, "")}
                      {...props}
                    />
                  )}
                />
              </ComponentSkeleton>
            </GridItem>
          </GridContainer>
        ))}
    </Page>
  );
}

MapeamentoFields.propTypes = {
  formMethods: PropTypes.any,
  isLoading: PropTypes.bool,
  campos: PropTypes.object,
  field: PropTypes.string,
  fieldsListar: PropTypes.array
};
