import { useEffect, useState } from "react";
import { getMobileFilial } from "services/mobileFilial";
import {
  keys,
  omit,
  camelCase,
  lowerFirst,
  upperFirst,
  isString
} from "lodash";
import { getMobileFilialDatabase } from "services/mobileFilialDatabase";
import { tiposConfiguracao } from "./database/configuracaoMapeamento/configuracaoTipos";

const mobileFilialActions = {
  MOBILEFILIAL_SET_STATE: "mobileFilial/SET_STATE"
};

const reducer = (state, action) => {
  switch (action.type) {
    case mobileFilialActions.MOBILEFILIAL_SET_STATE:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

export const getIsLoadingVariableName = tipo =>
  `isLoading${upperFirst(camelCase(tipo))}`;

export const getVariableName = tipo => lowerFirst(tipo);

export const getIsLoadingValue = (tipo, dados) =>
  tipo && dados ? dados[getIsLoadingVariableName(tipo)] || false : false;

export const getValue = (tipo, dados) =>
  tipo && dados ? dados[getVariableName(tipo)] || {} : {};

const getValoresDefault = () => {
  const valores = {};

  keys(tiposConfiguracao).forEach(k => {
    valores[getIsLoadingVariableName(k)] = true;
    valores[getVariableName(k)] = {
      dados: {},
      mapeamento: {},
      tipo: k,
      ...(tiposConfiguracao[k].valorDefault || {})
    };
  });

  return valores;
};

export default function useMobileFilialReducer(filialId) {
  const [mobileFilial, setMobileFilial] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  async function fetchMobileFilial() {
    try {
      setIsLoading(true);
      if (filialId && filialId > 0) {
        const resultado = await getMobileFilial(filialId);
        if (resultado) setMobileFilial(resultado);
      }
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchMobileFilial();
    // keys(tiposConfiguracao).forEach(k => fetchFilialDatabase(id, k));
  }, []);

  return { isLoading, mobileFilial, setMobileFilial };
}
