import { doResquestGraphql } from "../helpers/graphql";

const contratanteSubsection = `
  id
  tipoEmpresa
  email
  nome
  fantasia
  docFederal
  docEstadual
  telefone
  ativo
  endereco
  numero
  complemento
  cep
  bairro
  cidadeId
  maxDipositivos
  maxFiliais
  cidade {
    nome
    estado {
      sigla
    }
  }
`;

export async function getListaContratantes(options = {}) {
  const { limit = 0, offset = 0, where, order, fields } = options;
  let fieldsToUse = `
    id
    nome
    fantasia
    docFederal
    telefone
    ativo
  `;
  if (fields) fieldsToUse = fields;
  const query = `
    query getContratantes($queryOptions: QueryOptions) {
      contratantes(queryOptions: $queryOptions) {
        ${fieldsToUse}
      }
    }
  `;

  const variables = {
    queryOptions: {
      limit,
      offset,
      where,
      order
    }
  };

  if (!limit) delete variables.queryOptions.limit;
  if (!offset) delete variables.queryOptions.offset;

  const resultado = await doResquestGraphql(query, variables);
  if (resultado.status && resultado.data && resultado.data.contratantes)
    return resultado.data.contratantes;
  return;
}

export async function getQtdeContratantes(where) {
  const query = `
    query contratantesCount($queryOptions: QueryOptions) {
      contratantesCount(queryOptions: $queryOptions)
    }
  `;

  const variables = {
    queryOptions: {
      where
    }
  };

  const retorno = await doResquestGraphql(query, variables);
  if (retorno && retorno.status) return retorno.data.contratantesCount;
  return 0;
}

export async function deleteContratante(id) {
  const query = `
    mutation deleteContratante($id: ID!) {
      deleteContratante(id: $id)
    }
  `;

  const variables = {
    id
  };

  const retorno = await doResquestGraphql(query, variables);
  if (retorno && retorno.status) return retorno.data.deleteContratante;
  return false;
}

export async function contratanteExiste(docFederal) {
  const query = `
    query doContratanteExiste($docFederal: String!) {
      contratanteExiste(docFederal: $docFederal)
    }
  `;

  const variables = {
    docFederal
  };

  const retorno = await doResquestGraphql(query, variables);
  if (retorno && retorno.status) return retorno.data.contratanteExiste;
  return false;
}

export async function saveUpdateContratante(values) {
  const query = `
    mutation doCreateUpdateContratante($input: ContratanteCreateUpdateInput!) {
      createUpdateContratante(input: $input) {
        ${contratanteSubsection}
      }
    }
  `;

  const variables = {
    input: values
  };

  return await doResquestGraphql(query, variables);
}

export async function getContratante(id) {
  const query = `
    query getContratante($id: ID!) {
      contratante(id: $id) {
        ${contratanteSubsection}
      }
    }
  `;

  const variables = {
    id
  };

  const retorno = await doResquestGraphql(query, variables);
  if (retorno.status && retorno.data && retorno.data.contratante)
    return retorno.data.contratante;
  return;
}

export async function getContratanteUsuarios(contratanteId) {
  const query = `
    query getContratanteUsuarios($contratanteId: ID!) {
      contratanteUsuarios(contratanteId: $contratanteId) {
        id
        nome
        email
        ativo
      }
    }
  `;

  const variables = {
    contratanteId
  };

  const retorno = await doResquestGraphql(query, variables);
  if (retorno.status && retorno.data && retorno.data.contratanteUsuarios)
    return retorno.data.contratanteUsuarios;
  return;
};

export async function getContratanteMobileFiliais(contratanteId) {
  const query = `
    query getContratanteMobileFiliais($contratanteId: ID!) {
      contratanteMobileFiliais(contratanteId: $contratanteId) {
        id
        filialUuid
        identificadorApp
        numeroDispositivos
        nome
        tipoDocFederal
        docFederal
        docEstadual
        email
        ativo
      }
    }
  `;

  const variables = {
    contratanteId
  };

  const retorno = await doResquestGraphql(query, variables);
  if (retorno.status && retorno.data && retorno.data.contratanteMobileFiliais)
    return retorno.data.contratanteMobileFiliais;
  return;
};

