import React from "react";
import PropTypes from "prop-types";
import DatabaseForm from "./DatabaseForm";

const handleCancel = history => () => history.goBack();

export default function DatabasePage(props) {
  const { match, history } = props;
  const { params: { id } = {} } = match;
  return (
    <DatabaseForm
      id={id ? Number(id) : id}
      onCancel={handleCancel(history)}
      onAfterConfirmar={handleCancel(history)}
    />
  );
}

DatabasePage.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object
};
