import React from "react";
import MaskedInput from "react-text-mask";
import { omit, isEmpty } from "lodash";
import axios from "axios";
import PropTypes from "prop-types";
import { isObject } from "lodash";
import { consultaCep } from "services/cep";

// 87706-440
const mascara = [/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/];

const getSomenteNumeros = value =>
  (value.match(/\d/g) || []).map(Number).join("");

export const rcFormValidatorCEP = (rule, value, callback) => {
  const somenteNumeros = getSomenteNumeros(value);
  if (somenteNumeros && somenteNumeros.length < 8) {
    return callback(`CEP inválido!`);
  }
  return callback();
};

const doRequestGetCep = async cep => {
  const url = `https://viacep.com.br/ws/${cep}/json/`;
  try {
    const dadosCep = await axios.get(url);
    return dadosCep.status === 200 && dadosCep.data && !dadosCep.data.erro
      ? dadosCep.data
      : undefined;
    // eslint-disable-next-line no-empty
  } catch (error) {}
  return;
};

export default function CepInput(props) {
  const [carregandoCep, setCarregandoCep] = React.useState(false);

  const {
    className,
    placeholder,
    showMask = false,
    renderCarregandoCep,
    onGetDadosCep,
    readOnly = false,
    onBlur,
    doRequestCep,
    onChange,
    placeholderChar = "_",
    inputRef,
    onCarregandoCep,
    value,
    defaultValue,
    ...otherProps
  } = props;

  const valuetoUse = React.useMemo(() => {
    if (!isObject(value)) return value;
    return value?.target?.value;
  }, [value])

  const [ultimoCepPesquisado, setUltimoCepPesquisado] = React.useState(
    defaultValue || valuetoUse
  );

  const onCepBlur = async e => {
    const { target } = e;

    if (
      target.value &&
      !isEmpty(target.value) &&
      String(getSomenteNumeros(target.value)).length === 8 &&
      readOnly !== true &&
      ultimoCepPesquisado !== target.value &&
      onGetDadosCep
    ) {
      setCarregandoCep(true);
      if (onCarregandoCep) onCarregandoCep(true);
      let dadosCep;
      if (doRequestCep) dadosCep = await doRequestCep(target.value);
      else dadosCep = await consultaCep(target.value);
      if (dadosCep) {
        setUltimoCepPesquisado(target.value);
        onGetDadosCep(dadosCep);
      }
      setCarregandoCep(false);
      if (onCarregandoCep) onCarregandoCep(false);
    }
    if (onBlur) onBlur(e);
  };

  const onChangeCep = event => {
    const { target = {} } = event;
    const valorTratado = String(target.value || "")
      .replace(new RegExp(placeholderChar, "g"), " ")
      .trim();
    if (onChange) onChange(valorTratado);
  };

  return carregandoCep && renderCarregandoCep ? (
    renderCarregandoCep
  ) : (
    <MaskedInput
      mask={mascara}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      placeholderChar={placeholderChar}
      showMask={showMask}
      onChange={onChangeCep}
      onBlur={onCepBlur}
      value={valuetoUse}
      defaultValue={defaultValue}
      {...omit(otherProps, [
        "onChange",
        "onGetDadosCep",
        "onBlur",
        "doRequestCep"
      ])}
      render={(ref, props) => (
        <input
          name="telefone"
          placeholder={placeholder}
          ref={ref}
          className={className}
          {...props}
        />
      )}
    />
  );
}

CepInput.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  showMask: PropTypes.bool,
  renderCarregandoCep: PropTypes.node,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  onCarregandoCep: PropTypes.func,
  onGetDadosCep: PropTypes.func,
  readOnly: PropTypes.bool,
  onBlur: PropTypes.func,
  doRequestCep: PropTypes.func,
  onChange: PropTypes.func,
  inputRef: PropTypes.func,
  placeholderChar: PropTypes.string
};
