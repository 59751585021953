import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import CircularProgress from "@material-ui/core/CircularProgress";

import CustomTabs from "components/CustomTabs/CustomTabs";
import ContratanteForm from "./ContratanteForm/ContratanteForm";
import ContratanteUsuarios from "./ContratanteUsuarios/ContratanteUsuarios";
import ContratanteMobileFiliais from "./ContratanteMobileFiliais/ContratanteMobileFiliais";
import {
  useContratanteFetch,
  useContratanteUsuarioFetch,
  useContratanteMobileFilialFetch
} from "./ContratanteFormHooks";

const useStyles = makeStyles(theme => ({
  progress: {
    margin: theme.spacing(2)
  }
}));

export default function ContratanteTabs(props) {
  const { match, history } = props;
  const { params: { contratanteId } = {} } = match;

  const classes = useStyles();

  const dadosContratante = useContratanteFetch(contratanteId, history);
  const dadosContratanteUsuario = useContratanteUsuarioFetch(contratanteId);
  const dadosContratanteMobileFilial = useContratanteMobileFilialFetch(contratanteId);

  return (
    <CustomTabs
      title="Incluir / Alterar Contratante"
      headerColor="info"
      tabs={[
        {
          tabName: "Dados do Contratante",
          // tabIcon: BugReport,
          tabContent:
            dadosContratante.isLoading === true ? (
              <div>
                <CircularProgress className={classes.progress} />
              </div>
            ) : (
              <ContratanteForm dadosContratante={dadosContratante} />
            )
        },
        {
          tabName: "Usuários",
          disabled: !contratanteId,
          // tabIcon: Code,
          tabContent: (
            <ContratanteUsuarios
              dadosContratanteUsuario={dadosContratanteUsuario}
              contratanteId={contratanteId ? Number(contratanteId) : undefined}
            />
          )
        },
        {
          tabName: "Filiais",
          // tabIcon: Code,
          disabled: !contratanteId,
          tabContent: (
            <ContratanteMobileFiliais
              dadosContratanteMobileFilial={dadosContratanteMobileFilial}
              contratanteId={contratanteId ? Number(contratanteId) : undefined}
            />
          )
        }
      ]}
    />
  );
}

ContratanteTabs.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object
};
