import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import SyncIcon from "@material-ui/icons/Sync";
import SyncProblemIcon from "@material-ui/icons/SyncProblem";
import ErrorIcon from "@material-ui/icons/Error";
import TodayIcon from "@material-ui/icons/Today";

import Page from "../../../components/CustomPages/Page";
import PhonelinkIcon from "@material-ui/icons/Phonelink";
import PhonelinkOffIcon from "@material-ui/icons/PhonelinkOff";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer.js";

import { fromPdvAPI } from "helpers/axiosRequest";

const useStyles = makeStyles(theme => ({
  listItemAvatar: {
    textAlign: "center",
    padding: "15px"
  },
  statusIcon: {
    paddingTop: "10px"
  },
  statusTagsContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: "10px"
  },
  statusTags: {
    width: "350px",
    marginBottom: "7px",
    fontWeight: "bold"
  },
  labelClassName: {
    fontSize: "14px"
  }
}));

export default function Home() {
  const [status, setStatus] = React.useState([]);

  const classes = useStyles();

  const loadFiliaisStatus = async () => {
    const payload = {
      metodo: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/contratante/gerenciador/status`
    };
    const dadosRequest = await fromPdvAPI(payload);
    if (dadosRequest.data) setStatus(dadosRequest.data);
  };

  React.useEffect(() => {
    loadFiliaisStatus();
  }, []);

  return (
    <div>
      {status.map(s => (
        <Page
          key={s.filialId}
          labelTituloHeader={`${s.filialId} - ${s.nome}`}
          headerProps={{
            style: {
              width: "630px",
              height: "30px",
              paddingTop: "5px"
            },
            labelClassName: classes.labelClassName
          }}
        >
          <GridContainer>
            <GridItem xs={3}>
              <div className={classes.listItemAvatar}>
                <h5>Status do Gerenciador</h5>
                <div className={classes.statusIcon}>
                  {s.ativo ? (
                    <PhonelinkIcon style={{ fontSize: 80, color: "green" }} />
                  ) : (
                    <PhonelinkOffIcon style={{ fontSize: 80, color: "red" }} />
                  )}
                </div>
              </div>
            </GridItem>
            <GridItem xs={9}>
              <div className={classes.statusTagsContainer}>
              <Chip
                icon={<SyncIcon />}
                label={`${s.pedidosSincronizados} - Pedidos Sincronizados`}
                color="success"
                className={classes.statusTags}
              />

              <Chip
                icon={<TodayIcon />}
                label={`${s.pedidosDia} - Pedidos no dia`}
                color="primary"
                className={classes.statusTags}
              />

              <Chip
                icon={<SyncProblemIcon />}
                label={`${s.pedidosSemSincronizar} - Pedidos sem Sincronizar com ERP`}
                color="secondary"
                className={classes.statusTags}
              />

              {/* {!!s.qtdeErros && ( */}
              <Chip
                icon={<ErrorIcon />}
                label={`${s.qtdeErros} - Erros não resolvidos`}
                color="secondary"
                className={classes.statusTags}
              />
              {/* )} */}
              </div>
            </GridItem>
          </GridContainer>
        </Page>
      ))}
    </div>
  );
}
