import React from "react";
import PropTypes from "prop-types";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import HeaderPage from "./HeaderPage";

export default function Page(props) {
  const {
    children,
    labelTituloHeader,
    labelCategoria,
    containerProps,
    bodyFooter,
    headerProps,
    bodyProps,
    footerProps
  } = props;

  return (
    <Card {...containerProps}>
      {(labelTituloHeader || labelCategoria) && (
        <HeaderPage
          labelTituloHeader={labelTituloHeader}
          labelCategoria={labelCategoria}
          {...headerProps}
        />
      )}
      <CardBody {...bodyProps}> {children} </CardBody>
      {bodyFooter && <CardFooter {...footerProps}> {bodyFooter} </CardFooter>}
    </Card>
  );
}

Page.propTypes = {
  children: PropTypes.node,
  bodyFooter: PropTypes.node,
  labelTituloHeader: PropTypes.string,
  labelCategoria: PropTypes.string,
  containerProps: PropTypes.object,
  headerProps: PropTypes.object,
  bodyProps: PropTypes.object,
  footerProps: PropTypes.object
};
