import { doResquestGraphql } from "../helpers/graphql";

const TesteConexao = `
  status
  msg
  erro
`;

export async function filialGerenciadorStatus(identificadorApp) {
  const query = `
    query doFilialGerenciadorStatus($identificadorApp: String!) {
      filialGerenciadorStatus(identificadorApp: $identificadorApp)
    }
  `;

  const variables = {
    identificadorApp
  };

  const retorno = await doResquestGraphql(query, variables);
  if (retorno.status && retorno.data && retorno.data.filialGerenciadorStatus)
    return retorno.data.filialGerenciadorStatus;
  return;
}

export async function filialGerenciadorTestarConexao(
  identificadorApp,
  dadosConexao
) {
  const query = `
    query getFilialGerenciadorTestarConexao($identificadorApp: String!, $dadosConexao: DadosConexaoInput!) {
      filialGerenciadorTestarConexao(identificadorApp: $identificadorApp, dadosConexao: $dadosConexao) {
        ${TesteConexao}
      }
    }
  `;

  const variables = {
    identificadorApp,
    dadosConexao
  };

  const retorno = await doResquestGraphql(query, variables);
  if (
    retorno.status &&
    retorno.data &&
    retorno.data.filialGerenciadorTestarConexao
  )
    return retorno.data.filialGerenciadorTestarConexao;
  return;
}

export async function filialGerenciadorTestarSQL(identificadorApp, dadosSQL) {
  const query = `
    query getFilialGerenciadorTestarSQL($identificadorApp: String!, $dadosSQL: DadosSQLInput!) {
      filialGerenciadorTestarSQL(identificadorApp: $identificadorApp, dadosSQL: $dadosSQL) {
        ${TesteConexao}
      }
    }
  `;

  const variables = {
    identificadorApp,
    dadosSQL
  };

  const retorno = await doResquestGraphql(query, variables);
  if (retorno.status && retorno.data && retorno.data.filialGerenciadorTestarSQL)
    return retorno.data.filialGerenciadorTestarSQL;
  return;
}

export async function filialGerenciadorSincronizar(identificadorApp, dados) {
  const query = `
    query getFilialGerenciadorSincronizar($identificadorApp: String!, $dados: DadosSincronizar!) {
      filialGerenciadorSincronizar(identificadorApp: $identificadorApp, dados: $dados)
    }
  `;

  const variables = {
    identificadorApp,
    dados
  };

  const retorno = await doResquestGraphql(query, variables);
  if (
    retorno.status &&
    retorno.data &&
    retorno.data.filialGerenciadorSincronizar
  )
    return retorno.data.filialGerenciadorSincronizar;
  return;
}

export async function filialGerenciadorSincronizarCancelar(
  identificadorApp,
  tipo
) {
  const query = `
    query getFilialGerenciadorSincronizarCancelar($identificadorApp: String!, $tipo: TipoDados!) {
      filialGerenciadorSincronizarCancelar(identificadorApp: $identificadorApp, tipo: $tipo)
    }
  `;

  const variables = {
    identificadorApp,
    tipo
  };

  const retorno = await doResquestGraphql(query, variables);
  if (
    retorno.status &&
    retorno.data &&
    retorno.data.filialGerenciadorSincronizarCancelar
  )
    return retorno.data.filialGerenciadorSincronizarCancelar;
  return;
}

export async function filialGerenciadorSincronizarStatus(
  identificadorApp,
  tipo
) {
  const query = `
    query getFilialGerenciadorSincronizarStatus($identificadorApp: String!, $tipo: TipoDados!) {
      filialGerenciadorSincronizarStatus(identificadorApp: $identificadorApp, tipo: $tipo)
    }
  `;

  const variables = {
    identificadorApp,
    tipo
  };

  const retorno = await doResquestGraphql(query, variables);
  if (
    retorno.status &&
    retorno.data &&
    retorno.data.filialGerenciadorSincronizarStatus
  )
    return retorno.data.filialGerenciadorSincronizarStatus;
  return;
}

export async function filialGerenciadorLimparDados(identificadorApp, tipo) {
  const query = `
    mutation doFilialGerenciadorLimparDadosSite($identificadorApp: String!, $tipo: TipoDados!) {
      filialGerenciadorLimparDadosSite(identificadorApp: $identificadorApp, tipo: $tipo)
    }
  `;

  const variables = {
    identificadorApp,
    tipo
  };

  const retorno = await doResquestGraphql(query, variables);
  if (
    retorno.status &&
    retorno.data &&
    retorno.data.filialGerenciadorLimparDadosSite
  )
    return retorno.data.filialGerenciadorLimparDadosSite;
  return;
}

export async function filialGerenciadorReiniciar(identificadorApp) {
  const query = `
    mutation doFilialGerenciadorReiniciar($identificadorApp: String!) {
      filialGerenciadorReiniciar(identificadorApp: $identificadorApp)
    }
  `;

  const variables = {
    identificadorApp
  };

  const retorno = await doResquestGraphql(query, variables);
  if (retorno.status && retorno.data && retorno.data.filialGerenciadorReiniciar)
    return retorno.data.filialGerenciadorReiniciar;
  return;
}
