import React from "react";
import PropTypes from "prop-types";
import { omit } from "lodash";
// @material-ui/core components
// core components
import CustomTable from "../CustomTable";
import { useCustomTablePaginationFetch } from "./CustomTablePaginationHooks";
import { TablePagination } from "@material-ui/core";

// eslint-disable-next-line react/display-name
const CustomTablePagination = React.forwardRef((props, ref) => {
  const {
    doFetch,
    doFetchQtde,
    onEdit,
    onRemove,
    columns,
    options = {},
    ...otherProps
  } = props;
  const [
    dados,
    isLoading,
    pageRows,
    setPage,
    setOrder,
    setPageRows,
    doRefresh
  ] = useCustomTablePaginationFetch(doFetch, doFetchQtde);

  React.useImperativeHandle(ref, () => ({
    async callRefresh() {
      doRefresh();
    }
  }));

  const handleOrderChange = (orderBy, orderDirection) => {
    let order;
    if (orderBy >= 0)
      order = {
        campo: columns[orderBy].field,
        order: orderDirection.toUpperCase()
      };
    setOrder(order);
  };

  const handleRemoveRegistro = React.useMemo(
    () =>
      onRemove
        ? async dataRemove => {
            await onRemove(dataRemove);
            doRefresh();
          }
        : undefined,
    [onRemove]
  );

  const handleChangeRowsPerPage = event => {
    setPageRows(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <CustomTable
      isLoading={isLoading}
      onOrderChange={handleOrderChange}
      onRemove={handleRemoveRegistro}
      onEdit={onEdit}
      columns={columns.map(c => ({ ...c, customSort: null }))}
      data={dados.data}
      components={{
        // eslint-disable-next-line react/display-name
        Pagination: props => (
          <TablePagination
            component="div"
            colSpan={3}
            rowsPerPageOptions={[20, 40]}
            count={dados.totalCount}
            rowsPerPage={pageRows}
            page={dados.page}
            onChangePage={(event, page) => setPage(page)}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count}`
            }
          />
        )
      }}
      options={{
        pageSize: pageRows,
        search: options.search || false,
        showTitle: options.showTitle || false,
        toolbar: options.toolbar || false,
        actionsColumnIndex: -1,
        ...omit(options, ["search", "showTitle", "toolbar"])
      }}
      {...otherProps}
    />
  );
});

export default CustomTablePagination;

CustomTablePagination.propTypes = {
  doFetch: PropTypes.func,
  doFetchQtde: PropTypes.func,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  options: PropTypes.object,
  columns: PropTypes.arrayOf(PropTypes.object)
};
