import React from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import TestarConexao from "./TestarConexao";
import Porta from "./Porta";
import CaminhoArquivoSqlite from "./CaminhoArquivoSqlite";
import TipoIntegracao from "./TipoIntegracao";
import ComponentSkeleton from "components/ComponentSkeleton/ComponentSkeleton";
import Button from "components/CustomButtons/Button";
import useConfiguracaoDados from "customHooks/ConfiguracaoDadosHook";
import Page from "components/CustomPages/Page";

const useStyles = makeStyles(theme => ({
  progress: {
    margin: theme.spacing(2)
  }
}));

export default function ConfiguracaoIntegracao() {
  const classes = useStyles();
  const { handleSubmit, reset, control, watch, setValue, errors } = useForm();

  const { isLoading, doSaveDadosConfiguracao } = useConfiguracaoDados({
    tipo: "configuracaoIntegracao",
    reset
  });

  const onSubmit = async data => {
    const { dados } = data;
    await doSaveDadosConfiguracao({
      dados: JSON.stringify(dados),
      tipo: "configuracaoIntegracao"
    });
  };

  const tipoIntegracaoEscolhido = watch("dados.tipoIntegracao");

  return (
    <Page labelTituloHeader="Configuração">
      <TipoIntegracao
        inLoading={isLoading}
        control={control}
        setValue={setValue}
      />
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="dados.database"
              control={control}
              rules={{
                required: "A database é requerida."
              }}
              render={props => (
                <CustomInput
                  formControlProps={{
                    fullWidth: true
                  }}
                  labelText="Database"
                  error={!!errors.database}
                  errorMessage={errors.database ? errors.database.message : ""}
                  {...props}
                />
              )}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="dados.username"
              control={control}
              rules={{
                required: "A usuário é requerida."
              }}
              render={props => (
                <CustomInput
                  formControlProps={{
                    fullWidth: true
                  }}
                  labelText="Usuário"
                  error={!!errors.username}
                  errorMessage={errors.username ? errors.username.message : ""}
                  {...props}
                />
              )}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="dados.password"
              control={control}
              rules={{
                required: "A senha é requerida."
              }}
              render={props => (
                <CustomInput
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "password"
                  }}
                  labelText="Senha"
                  error={!!errors.password}
                  errorMessage={errors.password ? errors.password.message : ""}
                  {...props}
                />
              )}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="dados.hostname"
              control={control}
              rules={{
                required: "O Hostname é requerida."
              }}
              render={props => (
                <CustomInput
                  formControlProps={{
                    fullWidth: true
                  }}
                  labelText="Hostname"
                  error={!!errors.hostname}
                  errorMessage={errors.hostname ? errors.hostname.message : ""}
                  {...props}
                />
              )}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>

      <Porta
        isLoading={isLoading}
        control={control}
        tipoIntegracao={tipoIntegracaoEscolhido}
        errors={errors}
      />

      <CaminhoArquivoSqlite
        isLoading={isLoading}
        control={control}
        tipoIntegracao={tipoIntegracaoEscolhido}
        errors={errors}
      />

      <ComponentSkeleton showSkeleton={isLoading}>
        <Controller
          name="dados.conexaoEstabelecida"
          control={control}
          valueName="conexaoEstabelecida"
          render={props => (
            <TestarConexao validaDados={handleSubmit} {...props} />
          )}
        />
      </ComponentSkeleton>

      <Button color="info" onClick={handleSubmit(onSubmit)}>
        Salvar
      </Button>
    </Page>
  );
}

ConfiguracaoIntegracao.propTypes = {
  filialId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isLoading: PropTypes.bool,
  configuracaoIntegracao: PropTypes.object,
  identificadorApp: PropTypes.string
};
