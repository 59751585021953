import React from "react";
import PropTypes from "prop-types";
import { omit, get } from "lodash";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import InsertPadraoInsert from "./InsertPadraoInsert";
import InsertPadraoInsertArray from "./InsertPadraoInsertArray";
import { Controller, useFormContext } from "react-hook-form";
import ComponentSkeleton from "components/ComponentSkeleton/ComponentSkeleton";

function InsertPadrao({ configuracaoConst, isLoading }) {
  const formMethods = useFormContext();
  const { control } = formMethods;
  const { tagsInsert } = configuracaoConst;
  const tagsInsertToUse = Array.isArray(tagsInsert)
    ? tagsInsert
    : [
        {
          field: "insert",
          multiplos: tagsInsert.multiplos,
          tagsInsert: omit(tagsInsert, ["exemploInsert", "multiplos"]),
          exemploInsert: tagsInsert.exemploInsert,
          tagsInsertPersonalizadas: tagsInsert.tagsInsertPersonalizadas
        }
      ];

  return (
    <>
      {tagsInsertToUse.map(configuracaoInsert =>
        get(configuracaoInsert, "multiplos", false) === true ? (
          <InsertPadraoInsertArray
            key={`insert_padrao_insert_${configuracaoInsert.field}`}
            formMethods={formMethods}
            configuracaoInsert={configuracaoInsert}
            isLoading={isLoading}
          />
        ) : (
          <InsertPadraoInsert
            key={`insert_padrao_insert_${configuracaoInsert.field}`}
            formMethods={formMethods}
            configuracaoInsert={configuracaoInsert}
            isLoading={isLoading}
          />
        )
      )}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              control={control}
              valueName="checked"
              onChange={([event]) => event.target.checked}
              name={"dados.transacaoInsert"}
              render={props => (
                <CustomSwitch labelText="Utiliza Transação?" {...props} />
              )}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>
    </>
  );
}

InsertPadrao.propTypes = {
  isLoading: PropTypes.bool,
  configuracaoConst: PropTypes.object,
};

export default InsertPadrao;
