import React from "react";
import PropTypes from "prop-types";
import { get, keys, upperFirst, mergeWith, isArray, merge } from "lodash";
import RightArrow from "@material-ui/icons/ArrowRightAlt";
import Chip from "@material-ui/core/Chip";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Divider from "@material-ui/core/Divider";
import { Tooltip } from "@material-ui/core";
import useConfiguracaoDados from "customHooks/ConfiguracaoDadosHook";

const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap"
  },
  tag: {
    margin: 1
    // display: "flex",
    // justifyContent: "flex-start"
  },
  labelTipo: {
    alignItems: "center"
  },
  divider: {
    margin: 10
  },
  card: {
    width: "100%"
  }
}));

function RenderTagsInsert({
  tagsInsert,
  label = "",
  tagsInsertPersonalizadas
}) {
  const { configuracaoSalva } = useConfiguracaoDados({
    tipo: tagsInsertPersonalizadas
  });

  const classes = useStyles();
  const tagsInsertLocal = React.useMemo(() => {
    let valorInicial = {};
    if (configuracaoSalva) {
      const { mapeamento = {} } = configuracaoSalva;
      const { personalizados = [] } = mapeamento;
      for (const campo of personalizados) {
        const tagLabel = `${campo.id} - ${campo.label}`;
        if (
          [
            "texto",
            "textLongo",
            "data",
            "hora",
            "dataHora",
            "selecionavel"
          ].includes(campo.tipo)
        ) {
          if (!valorInicial.string) valorInicial.string = [];
          valorInicial.string.push(tagLabel);
        } else if (["inteiro"].includes(campo.tipo)) {
          if (!valorInicial.integer) valorInicial.integer = [];
          valorInicial.integer.push(tagLabel);
        } else if (["decimal", "monetario"].includes(campo.tipo)) {
          if (!valorInicial.decimal) valorInicial.decimal = [];
          valorInicial.decimal.push(tagLabel);
        } else if (["booleano"].includes(campo.tipo)) {
          if (!valorInicial.boolean) valorInicial.boolean = [];
          valorInicial.boolean.push(tagLabel);
        }
      }
    }
    return mergeWith(
      merge({}, tagsInsert),
      valorInicial,
      (objValue, srcValue) => {
        if (isArray(objValue)) {
          return objValue.concat(srcValue);
        }
      }
    );
  }, [tagsInsert, configuracaoSalva]);

  const valoresKeys = keys(tagsInsertLocal);
  const exemplo = get(tagsInsertLocal[get(valoresKeys, 0)], 0);
  return (
    <Card profile className={classes.card}>
      <CardHeader className={classes.header}>
        <strong>Parametros para utilizar no {label}</strong>
      </CardHeader>
      <CardBody className={classes.tags}>
        {valoresKeys.map(k => (
          <React.Fragment key={`tags_tipo_${k}`}>
            <GridContainer justify="flex-start" alignItems="center" spacing={0}>
              <GridItem xs={12} sm={12} md={2}>
                <Chip
                  label={
                    <>
                      <strong>{upperFirst(k)}</strong>
                      <RightArrow fontSize="small" />
                    </>
                  }
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={10}>
                {tagsInsertLocal[k].map((tag, index) => (
                  <Tooltip
                    key={`tag_${tag}_${index}`}
                    title={
                      tag.includes("CP_")
                        ? "Campo personalizado. Nome do field utilizar - Label"
                        : ""
                    }
                  >
                    <Chip
                      size="small"
                      label={<strong>{tag}</strong>}
                      color="primary"
                      className={classes.tag}
                    />
                  </Tooltip>
                ))}
              </GridItem>
            </GridContainer>
            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
      </CardBody>
      <p>
        Utilizar os campos dentro de <strong>{`{ }`}</strong> ignorando o tipo,
        exemplo <strong>{`{${exemplo}}`}</strong>.
      </p>
    </Card>
  );
}

RenderTagsInsert.propTypes = {
  tagsInsert: PropTypes.object,
  label: PropTypes.string,
  tagsInsertPersonalizadas: PropTypes.string
};

export default RenderTagsInsert;
