import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { isString } from "lodash";
import { Controller, useForm } from "react-hook-form";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import MuiAlert from "@material-ui/lab/Alert";

import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import CustomSelect from "components/CustomSelect/CustomSelect";
import CadastroPage from "components/CustomPages/CadastroPage";
import { saveUpdateMobileFilial } from "services/mobileFilial";
import Notificacoes from "components/Notificacoes/Notificacoes";
import CepInput from "components/CepInput/CepInput";
import CustomAutocompleteCidade from "components/CustomAutocomplete/CustomAutocompleteCidade";
import { cpfCnpjValidador } from "components/DocumentoFederalInput/DocumentoFederalInput";
import DocumentoFederalInput from "components/DocumentoFederalInput/DocumentoFederalInput";
import TelefoneInput, {
  telefoneValidador
} from "components/TelefoneInput/TelefoneInput";
import GerarQRCode from "components/GerarQRCode/GerarQRCode";
import ComponentSkeleton from "components/ComponentSkeleton/ComponentSkeleton";
import FormInput from "components/FormFields/FormFields";
import MobileFilialDispositivosLista from "views/Admin/MobileFilialDispositivos/lista/MobileFilialDispositivosLista";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

export default function MobileFilialForm({
  mobileFilial,
  contratanteId,
  isLoading,
  onCancel,
  onAfterConfirmar,
  setMobileFilial,
  exibeHeader = true,
  history
}) {
  const classes = useStyles();

  const {
    filialUuid = "",
    identificadorApp = "",
    numeroDispositivos = 1,
    nome = "",
    tipoDocFederal = "J",
    fantasia = "",
    docFederal = "",
    docEstadual = "",
    telefone = "",
    email = "",
    cep = "",
    cidadeId = "",
    endereco = "",
    numero = "",
    bairro = "",
    complemento = "",
    ativo = true
  } = mobileFilial;

  const {
    handleSubmit,
    control,
    errors,
    watch,
    setValue,
    getValues,
    setError,
    triggerValidation
  } = useForm({
    defaultValues: {
      cidadeId
    }
  });

  const [mostrarQrCode, setMostrarQrCode] = React.useState(false);
  const [formError, setFormError] = React.useState("");

  const handleMostrarQrCode = () => setMostrarQrCode(!mostrarQrCode);

  const handleDadosCep = dadosCep => {
    setValue("cidadeId", dadosCep?.cidade?.id);
    setValue("endereco", dadosCep?.logradouro);
    setValue("bairro", dadosCep?.bairro);
  };

  const handleGetDadosDocFederal = ({ consulta, cep }) => {
    if (filialUuid) return;
    setValue("nome", consulta?.nome);
    setValue("fantasia", consulta?.fantasia);
    setValue("numero", consulta?.numero);
    setValue("complemento", consulta?.complemento);
    setValue("telefone", consulta?.telefone);
    setValue("email", consulta?.email);
    setValue("cep", consulta?.cep);
    handleDadosCep(cep);
  };

  const onSubmit = async data => {
    setFormError("");
    const mobileFilialToSave = {
      ...mobileFilial,
      ...data,
      id: Number(mobileFilial.id) > 0 ? mobileFilial.id : undefined,
      contratanteId
    };
    const retorno = await saveUpdateMobileFilial(mobileFilialToSave);
    if (
      retorno.status &&
      retorno.data &&
      retorno.data.createUpdatMobileFilial
    ) {
      Notificacoes.success("Filial salva com sucesso");
      if (setMobileFilial) setMobileFilial(retorno.data.createUpdatMobileFilial);
      if (onAfterConfirmar) onAfterConfirmar();
    } else {
      if (retorno.fieldErrors) {
        for (const key of Object.keys(retorno.fieldErrors)) {
          setError(key, {
            type: "custom",
            message: retorno.fieldErrors[key]
          });
        }
      }
      if (retorno?.errorMessages.length)
        setFormError(retorno.errorMessages.join("\n"));
      Notificacoes.error("Ocorreu um erro ao salvar a Filial");
    }
  };

  const watchTipoDocFederal = watch("tipoDocFederal", tipoDocFederal) || "J";

  const handleValidacoesDocFederal = async value => {
    let valueToUse = "";
    if (isString(value)) valueToUse = value;
    else {
      const { target: { value: valueI = "" } = {} } = value || {};
      valueToUse = valueI;
    }
    const valores = getValues();
    return cpfCnpjValidador(valores.tipoDocFederal, valueToUse);
  };

  return isLoading === true ? (
    <div>
      <CircularProgress className={classes.progress} />
    </div>
  ) : (
    <>
      <CadastroPage
        labelTituloHeader={exibeHeader === true ? "Dados da Filial" : undefined}
        onCancelar={onCancel}
        onSalvar={handleSubmit(onSubmit)}
      >
        <GridContainer>
          {formError && (
            <GridItem xs={12} sm={12} md={12}>
              <MuiAlert severity="error">{formError}</MuiAlert>
            </GridItem>
          )}
          <GridItem xs={12} sm={12} md={4}>
            <ComponentSkeleton showSkeleton={isLoading}>
              <Controller
                name="tipoDocFederal"
                control={control}
                defaultValue={tipoDocFederal}
                rules={{
                  required: "O tipo de Integração é requerido!"
                }}
                render={props => (
                  <CustomSelect
                    labelText="Tipo de Pessoa"
                    selectProps={{
                      disabled: mobileFilial.id > 0
                    }}
                    formControlProps={{
                      fullWidth: true
                    }}
                    options={[
                      {
                        value: "F",
                        label: "Física"
                      },
                      {
                        value: "J",
                        label: "Jurídica"
                      }
                    ]}
                    {...props}
                  />
                )}
              />
            </ComponentSkeleton>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <FormInput
              isLoading={isLoading}
              control={control}
              name="docFederal"
              defaultValue={docFederal}
              errors={errors}
              labelText={watchTipoDocFederal === "F" ? "CPF" : "CNPJ"}
              rules={{
                required: `O campo CPF / CNPJ é requerido`,
                validate: handleValidacoesDocFederal
              }}
              inputProps={{
                disabled: mobileFilial.id > 0,
                inputComponent: DocumentoFederalInput,
                inputProps: {
                  tipoPessoa: watchTipoDocFederal,
                  onGetDadosDocFederal: handleGetDadosDocFederal,
                  renderCarregando: (
                    <CircularProgress className={classes.progress} />
                  )
                }
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <FormInput
              isLoading={isLoading}
              control={control}
              name="docEstadual"
              defaultValue={docEstadual}
              errors={errors}
              labelText={
                watchTipoDocFederal === "F" ? "RG" : "Inscrição Estadual"
              }
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <FormInput
              isLoading={isLoading}
              control={control}
              name="nome"
              label="Nome"
              defaultValue={nome}
              rules={{
                required: "O nome é requerido."
              }}
              errors={errors}
            />
          </GridItem>
        </GridContainer>

        {watchTipoDocFederal === "J" ? (
          <GridContainer>
            <GridItem xs={12} sm={12} md={8}>
              <FormInput
                isLoading={isLoading}
                control={control}
                name="fantasia"
                labelText="Nome Fantasia"
                defaultValue={fantasia}
                errors={errors}
              />
            </GridItem>
          </GridContainer>
        ) : null}

        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <CustomInput
              labelText="Filial Uuid"
              id="filialUuid"
              inputProps={{
                value: filialUuid,
                disabled: true
              }}
              formControlProps={{
                fullWidth: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomInput
              labelText="Identificador do App"
              id="identificadorApp"
              inputProps={{
                value: identificadorApp,
                disabled: true,
                endAdornment: identificadorApp ? (
                  <InputAdornment position="end">
                    <IconButton color="primary" onClick={handleMostrarQrCode}>
                      <Icon
                        className="fa fa-qrcode"
                        color="primary"
                        fontSize="small"
                      />
                    </IconButton>
                  </InputAdornment>
                ) : null
              }}
              formControlProps={{
                fullWidth: true
              }}
            />
            <GerarQRCode
              opened={mostrarQrCode}
              handleClose={handleMostrarQrCode}
              valor={identificadorApp}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <FormInput
              isLoading={isLoading}
              control={control}
              name="numeroDispositivos"
              labelText="Quantidade de Dispositivos"
              defaultValue={numeroDispositivos}
              errors={errors}
              inputProps={{
                type: "Number"
              }}
            />
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <FormInput
              isLoading={isLoading}
              control={control}
              name="email"
              labelText="Email"
              defaultValue={email}
              errors={errors}
              inputProps={{
                type: "email"
              }}
            />
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <FormInput
              isLoading={isLoading}
              control={control}
              name="telefone"
              defaultValue={telefone}
              errors={errors}
              labelText="Telefone"
              rules={{ validate: telefoneValidador }}
              inputProps={{
                inputComponent: TelefoneInput
              }}
            />
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Controller
              name="cep"
              control={control}
              defaultValue={cep}
              render={props => (
                <CustomInput
                  labelText="Cep"
                  id="cep"
                  inputProps={{
                    inputComponent: CepInput,
                    inputProps: {
                      onGetDadosCep: handleDadosCep,
                      renderCarregandoCep: (
                        <CircularProgress className={classes.progress} />
                      )
                    }
                  }}
                  formControlProps={{
                    fullWidth: true
                  }}
                  error={!!errors.cep}
                  errorMessage={errors.cep ? errors.cep.message : ""}
                  {...props}
                />
              )}
            />
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <ComponentSkeleton showSkeleton={isLoading}>
              <Controller
                name="cidadeId"
                control={control}
                defaultValue={cidadeId}
                render={props => (
                  <CustomAutocompleteCidade
                    id="cidadeId"
                    labelText="Cidade"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    error={!!errors.cidadeId}
                    errorMessage={
                      errors.cidadeId ? errors.cidadeId.message : ""
                    }
                    setValueReactForm={setValue}
                    triggerValidationReactForm={triggerValidation}
                    {...props}
                  />
                )}
              />
            </ComponentSkeleton>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <ComponentSkeleton showSkeleton={isLoading}>
              <Controller
                name="endereco"
                control={control}
                defaultValue={endereco}
                render={props => (
                  <CustomInput
                    labelText="Endereço"
                    id="endereco"
                    formControlProps={{
                      fullWidth: true
                    }}
                    {...props}
                  />
                )}
              />
            </ComponentSkeleton>
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <ComponentSkeleton showSkeleton={isLoading}>
              <Controller
                name="numero"
                control={control}
                defaultValue={numero}
                render={props => (
                  <CustomInput
                    labelText="Numero"
                    id="numero"
                    formControlProps={{
                      fullWidth: true
                    }}
                    {...props}
                  />
                )}
              />
            </ComponentSkeleton>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <ComponentSkeleton showSkeleton={isLoading}>
              <Controller
                name="bairro"
                control={control}
                defaultValue={bairro}
                render={props => (
                  <CustomInput
                    labelText="Bairro"
                    id="bairro"
                    formControlProps={{
                      fullWidth: true
                    }}
                    {...props}
                  />
                )}
              />
            </ComponentSkeleton>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <ComponentSkeleton showSkeleton={isLoading}>
              <Controller
                name="complemento"
                control={control}
                defaultValue={complemento}
                render={props => (
                  <CustomInput
                    labelText="Complemento"
                    id="complemento"
                    formControlProps={{
                      fullWidth: true
                    }}
                    {...props}
                  />
                )}
              />
            </ComponentSkeleton>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <ComponentSkeleton showSkeleton={isLoading}>
              <Controller
                name="ativo"
                control={control}
                defaultValue={ativo}
                render={props => (
                  <CustomSwitch
                    labelText="Ativo"
                    id="ativo"
                    switchProps={{
                      color: "primary"
                    }}
                    {...props}
                  />
                )}
              />
            </ComponentSkeleton>
          </GridItem>
        </GridContainer>
      </CadastroPage>
      {mobileFilial.id > 0 && (
        <MobileFilialDispositivosLista
          filialId={mobileFilial.id}
          contratanteId={contratanteId}
          history={history}
        />
      )}
    </>
  );
}

MobileFilialForm.propTypes = {
  mobileFilial: PropTypes.object,
  contratanteId: PropTypes.number,
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  onAfterConfirmar: PropTypes.func,
  exibeHeader: PropTypes.bool
};
