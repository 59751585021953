import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import { Controller, useForm } from "react-hook-form";
import CircularProgress from "@material-ui/core/CircularProgress";

import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import CadastroPage from "components/CustomPages/CadastroPage";
import { useUsuarioFetch } from "./UsuarioFormHooks";
import { saveUpdateUsuario } from "services/usuario";
import Notificacoes from "components/Notificacoes/Notificacoes";
import { usuarioEmailExiste } from "services/usuario";
import ComponentSkeleton from "components/ComponentSkeleton/ComponentSkeleton";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

export default function UsuarioForm({
  id,
  contratanteId,
  onCancel,
  onAfterConfirmar
}) {
  const classes = useStyles();

  const [usuario, isLoading] = useUsuarioFetch(id);
  const {
    nome = "",
    email = "",
    telefone = "",
    password = "",
    ativo = true
  } = usuario;

  const { handleSubmit, getValues, trigger, formState, control } = useForm({
    mode: "onBlur"
  });

  const { errors } = formState;

  const compararConfirmacaoSenha = value => {
    const { password = "" } = getValues();
    return (
      (value && value === password) ||
      "A confirmação da senha não esta igual a senha"
    );
  };

  const validaSenha = value => {
    if (
      value &&
      (Object.keys(formState?.touched) || []).findIndex(
        t => t === "passwordConfirmacao"
      ) > 0
    ) {
      trigger("passwordConfirmacao");
    }
  };

  const onSubmit = async data => {
    const usuario = {
      ...data,
      id,
      contratanteId
    };
    const retorno = await saveUpdateUsuario(usuario);
    if (retorno) {
      Notificacoes.success("Usuario salvo com sucesso");
      if (onAfterConfirmar) onAfterConfirmar();
    } else Notificacoes.error("Ocorreu um erro ao salvar a Usuario");
  };

  const getConfirmacaoMessage = () => {
    if (!errors.passwordConfirmacao) return "";
    const { type = "", message } = errors.passwordConfirmacao;
    if (type === "compararConfirmacaoSenha")
      return "A confirmação da senha não esta igual a senha.";
    return message;
  };

  const handleValidacaoEmail = async value => {
    if (!value || id > 0) return;
    const retorno = await usuarioEmailExiste(value);
    if (retorno) return "Email já cadastrado";
    return;
  };

  return isLoading === true ? (
    <div>
      <CircularProgress className={classes.progress} />
    </div>
  ) : (
    <CadastroPage
      labelTituloHeader="Dados do Usuário"
      onCancelar={onCancel}
      onSalvar={handleSubmit(onSubmit)}
    >
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="nome"
              control={control}
              defaultValue={nome}
              rules={{
                required: "O nome é requerido."
              }}
              render={props => (
                <CustomInput
                  labelText="Nome"
                  id="nome"
                  formControlProps={{
                    fullWidth: true
                  }}
                  error={!!errors.nome}
                  errorMessage={errors.nome ? errors.nome.message : ""}
                  {...props}
                />
              )}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="email"
              control={control}
              defaultValue={email}
              rules={{
                required: "O email é requerido.",
                pattern: {
                  // eslint-disable-next-line no-control-regex
                  value: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                  message: "Email inválido"
                },
                validate: handleValidacaoEmail
              }}
              render={props => (
                <CustomInput
                  labelText="Email"
                  id="email"
                  inputProps={{
                    disabled: id > 0
                  }}
                  formControlProps={{
                    fullWidth: true
                  }}
                  error={!!errors.email}
                  errorMessage={errors.email ? errors.email.message : ""}
                  {...props}
                />
              )}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>
      {!id && (
        <Fragment>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <ComponentSkeleton showSkeleton={isLoading}>
                <Controller
                  name="password"
                  control={control}
                  defaultValue={password}
                  rules={{
                    required: "O campo de senha é requerido",
                    validate: validaSenha
                  }}
                  render={props => (
                    <CustomInput
                      labelText="Senha"
                      id="password"
                      inputProps={{
                        type: "password"
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                      error={!!errors.password}
                      errorMessage={
                        errors.password ? errors.password.message : ""
                      }
                      {...props}
                    />
                  )}
                />
              </ComponentSkeleton>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <ComponentSkeleton showSkeleton={isLoading}>
                <Controller
                  name="passwordConfirmacao"
                  control={control}
                  defaultValue={password}
                  rules={{
                    required: "O campo de confirmação de senha é requerido",
                    validate: compararConfirmacaoSenha
                  }}
                  render={props => (
                    <CustomInput
                      labelText="Confirmação de Senha"
                      id="passwordConfirmacao"
                      inputProps={{
                        type: "password"
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                      error={!!errors.passwordConfirmacao}
                      errorMessage={getConfirmacaoMessage()}
                      {...props}
                    />
                  )}
                />
              </ComponentSkeleton>
            </GridItem>
          </GridContainer>
        </Fragment>
      )}

      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="telefone"
              control={control}
              defaultValue={telefone}
              render={props => (
                <CustomInput
                  labelText="Telefone"
                  id="telefone"
                  formControlProps={{
                    fullWidth: true
                  }}
                  {...props}
                />
              )}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="ativo"
              control={control}
              defaultValue={ativo}
              render={props => (
                <CustomSwitch
                  labelText="Ativo"
                  id="ativo"
                  switchProps={{
                    color: "primary"
                  }}
                  {...props}
                />
              )}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>
    </CadastroPage>
  );
}

UsuarioForm.propTypes = {
  id: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  contratanteId: PropTypes.number,
  visivel: PropTypes.bool,
  onCancel: PropTypes.func,
  onAfterConfirmar: PropTypes.func
};
