import { Parser } from "node-sql-parser";
import { endsWith, get } from "lodash";
import SupervisedUserCircle from "@material-ui/icons/SupervisedUserCircle";
import PeopleOutline from "@material-ui/icons/PeopleOutline";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import BuildIcon from "@material-ui/icons/Build";
import StoreIcon from "@material-ui/icons/Store";
import ComputerIcon from "@material-ui/icons/Computer";
import SettingsIcon from "@material-ui/icons/Settings";
import MoneyOffOutlinedIcon from "@material-ui/icons/MoneyOffOutlined";
import SituacaoPedido from "views/Gerenciador/views/SituacaoPedido/SituacaoPedido";

const parser = new Parser();

export const tiposConfiguracao = {
  configuracaoIntegracao: {
    valorDefault: {
      dados: {
        tipoIntegracao: "mysql",
        hostname: "127.0.0.1",
        porta: 3306
      }
    }
  },
  configuracaoBasica: {
    valorDefault: {
      dados: {
        tipoValidacaoCampoSenhaUsuario: "md5",
        permiteVendaProdutoSemEstoque: false,
        permiteAlterarValorVendaItemPedido: false,
        permiteDescontoNoItemPedido: false,
        permiteDescontoNoPedido: false,
        utilizaCondicaoPagamento: false,
        utilizaSituacaoPedido: false,
        utilizaTipoOperacao: false,
        permiteInclusaoNovosClientes: true,
        utilizaOrdemServico: false,
        permiteIncluirServicoSemCadastro: false,
        permiteDescontoNoProdutoOrdemServico: false,
        permiteAlterarValorVendaProdutoOrdemServico: false,
        permiteDescontoNoServicoOrdemServico: false,
        permiteAlterarValorVendaServicoOrdemServico: false,
        permiteDescontoNaOrdemServico: false,
        utilizaPedido: true,
        produtoComImagem: false,
        catalogoProduto: false,
        imagemServerAuthToken: "",
        produtoFazDownloadImagem: true,
        labelPrazoEntregaPedido: "Prazo de Entrega",
        utilizaTabelaPreco: false,
        utilizaFormaPagamentoCliente: false,
        utilizaSugestaoUltimoPedido: false,
        utilizaProdutoFracionado: false,
        clientePorVendedor: false,
        campoClienteValidacaoUnico: "docFederal"
      }
    }
  },
  configuracaoDadosUsuario: {
    titulo: "Dados dos Usuários",
    icon: SupervisedUserCircle,
    campos: {
      usuarioIdentificador: {
        label: "Chave Primária do Usuário"
      },
      nome: { label: "Nome" },
      login: { label: "Login" },
      password: {
        label: "Senha",
        visivel: ({ configuracaoBasica: { dados } = {} }) =>
          dados &&
          dados.tipoValidacaoCampoSenhaUsuario &&
          dados.tipoValidacaoCampoSenhaUsuario !== "criadaApp"
      },
      email: { label: "Email", opcional: true },
      visualizaTodosPedidos: {
        label: "Pode visualizar pedido de outros vendedores?",
        opcional: true
      },
      administraSenhas: {
        label: "Pode limpar a senha de outros usuários?",
        opcional: true,
        visivel: ({ configuracaoBasica: { dados } = {} }) =>
          dados &&
          dados.tipoValidacaoCampoSenhaUsuario &&
          dados.tipoValidacaoCampoSenhaUsuario !== "criadaApp"
      },
      naoFazPedido: {
        label: "Não pode criar pedidos",
        opcional: true
      }
    }
  },
  configuracaoDadosFormaPagamento: {
    titulo: "Dados da Forma de Pagamento",
    icon: CreditCardIcon,
    campos: {
      formaPagamentoIdentificador: {
        label: "Chave Primária da Forma de Pagamento"
      },
      descricao: {
        label: "Descrição"
      },
      usaValorAPrazo: {
        label: "Utiliza Valor a Prazo",
        opcional: true
      }
    }
  },
  configuracaoDadosCondicaoPagamento: {
    titulo: "Dados da Condição de Pagamento",
    icon: CreditCardIcon,
    enabled: ({ configuracaoBasica }) =>
      get(configuracaoBasica, "dados.utilizaCondicaoPagamento", false) === true,
    campos: {
      condicaoPagamentoIdentificador: {
        label: "Chave Primária da Condição de Pagamento"
      },
      descricao: {
        label: "Descrição"
      }
    }
  },
  configuracaoDadosTipoOperacaoPedido: {
    titulo: "Dados do Tipo de Operação do Pedido",
    icon: SettingsIcon,
    enabled: ({ configuracaoBasica }) =>
      get(configuracaoBasica, "dados.utilizaTipoOperacao", false) === true,
    campos: {
      tipoOperacaoPedidoIdentificador: {
        label: "Chave Primária do Tipo de Operação do Pedido"
      },
      descricao: {
        label: "Descrição"
      }
    }
  },
  configuracaoDadosProduto: {
    titulo: "Dados do Produto",
    icon: ComputerIcon,
    campos: {
      produtoIdentificador: {
        label: "Chave Primária do Produto"
      },
      descricao: {
        label: "Descrição"
      },
      descricaoDetalhada: {
        label: "Descrição Detalhada do Produto",
        opcional: true
      },
      valorUnitario: {
        label: "Valor de venda do Produto"
      },
      valorVendaAPrazo: {
        label:
          "Valor de venda a prazo do Produto. *Forma de Pagamento a prazo. Default 0.",
        opcional: true
      },
      estoqueAtual: {
        label: "Estoque Atual"
      },
      observacao: {
        label: "Observação",
        opcional: true
      },
      grupo: {
        label: "Nome do Grupo",
        opcional: true
      },
      imagemURL: {
        label: "URL da Imagem",
        opcional: true,
        visivel: ({ configuracaoBasica: { dados } = {} }) =>
          dados && dados.produtoComImagem === true && !dados.catalogoProduto
      },
      valorMinimoVenda: {
        label: "Valor de Venda Mínimo (default 0)",
        opcional: true,
        visivel: ({ configuracaoBasica: { dados } = {} }) =>
          dados &&
          dados.permiteAlterarValorVendaItemPedido &&
          dados.permiteAlterarValorVendaItemPedido === true
      },
      percDescontoMaximo: {
        label: "% Máximo de desconto permitido no produto(default 100%)",
        opcional: true,
        visivel: ({ configuracaoBasica: { dados } = {} }) =>
          dados &&
          dados.permiteDescontoNoItemPedido &&
          dados.permiteDescontoNoItemPedido === true
      },
      ativo: {
        label: "Situação Ativa do Produto"
      }
    },
    camposPersonalizados: true
  },
  configuracaoDadosTabelaPreco: {
    titulo: "Dados da Tabela de Preço",
    icon: MoneyOffOutlinedIcon,
    enabled: ({ configuracaoBasica }) =>
      get(configuracaoBasica, "dados.utilizaTabelaPreco", false) === true,
    campos: {
      tabelaPrecoIdentificador: {
        label: "Chave Primária da tabela de Preço"
      },
      descricao: {
        label: "Descrição"
      }
    },
    camposPersonalizados: false,
    selectItens: {
      exemploSql:
        "SELECT codigo_produto, valor_venda from tabela_preco_item where codigo_tabela_preco = $TABELA_PRECO_IDENTIFICADOR",
      campos: {
        produtoIdentificador: {
          label: "Chave Primária do produto"
        },
        valorVenda: {
          label: "Valor de Venda"
        }
      }
    }
  },
  configuracaoDadosProdutoImagem: {
    camposPersonalizados: false,
    icon: AddAPhotoIcon,
    enabled: ({ configuracaoBasica }) =>
      get(configuracaoBasica, "dados.catalogoProduto", false) === true,
    titulo: "Imagens do Produto",
    campos: {
      produtoIdentificador: {
        label: "Chave Primária do Produto"
      },
      descricao: {
        label: "Descrição da imagem",
        opcional: true
      },
      imagemURL: {
        label: "URL da Imagem"
      },
      ordem: {
        label: "Ordem da imagem",
        opcional: true
      },
      ativo: {
        label: "Situação Ativa da imagem"
      }
    }
  },
  configuracaoDadosServico: {
    titulo: "Dados do Serviço",
    icon: BuildIcon,
    enabled: ({ configuracaoBasica }) =>
      get(configuracaoBasica, "dados.utilizaOrdemServico", false) === true,
    campos: {
      servicoIdentificador: {
        label: "Chave Primária do Serviço"
      },
      descricao: {
        label: "Descrição"
      },
      descricaoDetalhada: {
        label: "Descrição Detalhada do Serviço",
        opcional: true
      },
      valorUnitario: {
        label: "Valor de venda do Serviço"
      },
      observacao: {
        label: "Observação",
        opcional: true
      },
      grupo: {
        label: "Nome do Grupo",
        opcional: true
      },
      ativo: {
        label: "Situação Ativa do Serviço"
      }
    },
    camposPersonalizados: false
  },
  configuracaoDadosCliente: {
    titulo: "Dados do Cliente",
    icon: PeopleOutline,
    camposPersonalizados: true,
    camposPersonalizadosEditavel: ({ configuracaoBasica }) =>
      get(
        configuracaoBasica,
        "dados.dados.permiteInclusaoNovosClientes",
        true
      ) === true,
    campos: {
      clienteIdentificador: {
        label: "Chave Primária do Cliente"
      },
      nome: {
        label: "Nome / Razão Social"
      },
      fantasia: {
        label: "Fantasia",
        opcional: true
      },
      tipoPessoa: {
        label: "Tipo de Pessoa (F) Física (J) Jurídica"
      },
      docFederal: {
        label: "Doc. Federal CPF / CNPJ"
      },
      docEstadual: {
        label: "Doc. Estadual RG / Inscrição Estadual",
        opcional: true
      },
      telefone: {
        label: "Telefone",
        opcional: true
      },
      email: {
        label: "Email",
        opcional: true
      },
      cep: {
        label: "Cep",
        opcional: true
      },
      cidade: {
        label: "Cidade",
        opcional: true
      },
      estado: {
        label: "Estado - Sigla",
        opcional: true
      },
      endereco: {
        label: "Endereço",
        opcional: true
      },
      numero: {
        label: "Numero",
        opcional: true
      },
      bairro: {
        label: "Bairro",
        opcional: true
      },
      complemento: {
        label: "Complemento",
        opcional: true
      },
      observacao: {
        label: "Observação",
        opcional: true
      },
      ativo: {
        label: "Situacao Ativo (Boolean)"
      },
      usuarioIdentificador: {
        label: "Chave Primária do Usuário Vendedor",
        opcional: true,
        visivel: ({ configuracaoBasica }) =>
          get(configuracaoBasica, "dados.clientePorVendedor", false) === true
      },
      tabelaPrecoIdentificador: {
        label: "Chave Primária da Tabela de Preço do Cliente",
        opcional: true,
        visivel: ({ configuracaoBasica }) =>
          get(configuracaoBasica, "dados.utilizaTabelaPreco", false) === true
      }
    },
    selectFormaPagamento: {
      visivel: ({ configuracaoBasica }) =>
        get(configuracaoBasica, "dados.utilizaFormaPagamentoCliente", false) ===
        true,
      exemploSql:
        "SELECT codigo_forma_pagamento from tabela_forma_pagamento_cliente where codigo_cliente = $CLIENTE_IDENTIFICADOR",
      campos: {
        formaPagamentoIdentificador: {
          label: "Chave Primária da Forma de Pagamento"
        }
      }
    }
  },
  configuracaoDadosContasReceber: {
    titulo: "Contas a Receber",
    exemploSql:
      "select cod_cliente_contas_receber, cod_cliente, vr_documento, dt_vencimento, dt_recebimento, CASE WHEN dt_recebimento is not null THEN 'pago' WHEN dt_recebimento is null THEN 'aberto' WHEN dt_vencimento > CURDATE() THEN 'pendente' END as situacao from tab_cliente_contas_receber",
    icon: MoneyOffOutlinedIcon,
    campos: {
      contaReceberIdentificador: {
        label: "Chave Primária da Conta a Receber"
      },
      clienteIdentificador: {
        label: "Chave Primária do Cliente"
      },
      valor: {
        label: "Valor"
      },
      dataVencimento: {
        label: "Data de Vencimento"
      },
      dataPagamento: {
        label: "Data do Pagamento",
        opcional: true
      },
      formaPagamentoIdentificador: {
        label: "Chave Primária da Forma de Pagamento",
        opcional: true
      },
      situacao: {
        label: "Situação (pago, aberto, pendente)"
      }
    },
    camposPersonalizados: true
  },
  configuracaoDadosInsertCliente: {
    titulo: "Inserir dados do Cliente",
    icon: PersonAddIcon,
    enabled: ({ configuracaoBasica }) =>
      get(configuracaoBasica, "dados.permiteInclusaoNovosClientes", false) ===
      true,
    tagsInsert: [
      {
        field: "insertCliente",
        multiplos: true,
        tagsInsert: {
          string: [
            "CLIENTE_IDENTIFICADOR",
            "NOME",
            "TIPO_PESSOA",
            "DOC_FEDERAL",
            "DOC_ESTADUAL",
            "TELEFONE",
            "EMAIL",
            "CEP",
            "CIDADE",
            "ESTADO",
            "ENDERECO",
            "NUMERO",
            "BAIRRO",
            "COMPLEMENTO",
            "OBSERVACAO",
            "USUARIO_IDENTIFICADOR",
            "IBGE_ESTADO",
            "IBGE_CIDADE"
          ]
        },
        tagsInsertPersonalizadas: "configuracaoDadosCliente",
        exemploInsert: `INSERT INTO cliente (nome,  tipo_pessoa, cpf, cnpj, telefone) values 
          ("{NOME}", "{TIPO_PESSOA}", if("{TIPO_PESSOA}" = "F", "{DOC_FEDERAL}", ""), if("{TIPO_PESSOA}" = "J",  "{DOC_FEDERAL}", ""), "{TELEFONE}")`
      },
      {
        field: "selectUltimoCliente",
        label: `Select consultar a Chave Primária do novo cliente salvo`,
        exemploInsert: `SELECT codigo_cliente AS CLIENTE_IDENTIFICADOR FROM cliente ORDER BY codigo_cliente DESC LIMIT 1`,
        tagsInsert: {
          string: ["CLIENTE_IDENTIFICADOR"]
        }
      }
    ]
  },
  configuracaoDadosPedido: {
    titulo: "Campos personalizados do Pedido",
    icon: StoreIcon,
    enabled: ({ configuracaoBasica }) =>
      get(configuracaoBasica, "dados.utilizaPedido", false) === true,
    someteCamposPersonalizados: true
  },
  configuracaoDadosInsertPedido: {
    titulo: "Inserir dados do Pedido",
    icon: StoreIcon,
    enabled: ({ configuracaoBasica }) =>
      get(configuracaoBasica, "dados.utilizaPedido", false) === true,
    tagsInsert: [
      {
        field: "insertPedido",
        label: "Insert do Pedido",
        multiplos: true,
        exemploInsert: `INSERT INTO 
          venda ( codigo_cliente, codigo_vendedor, codigo_tipo_operacao, codigo_forma_pagamento, dt_venda, obs_venda, observacao, valor_desconto, perc_desconto, valor_venda_total)
          VALUES ({CLIENTE_IDENTIFICADOR}, {USUARIO_IDENTIFICADOR}, {TIPO_OPERACAO_PEDIDO_IDENTIFICADOR}, {FORMA_PAGAMENTO_IDENTIFICADOR}, STR_TO_DATE("{DATA_PEDIDO}", "%Y-%m-%d %H:%i:%s"), "{PRAZO_ENTREGA}", "{OBSERVACAO}", {VALOR_DESCONTO}, {PERCENTUAL_DESCONTO}, {VALOR_TOTAL})`,
        tagsInsert: {
          string: [
            "CLIENTE_IDENTIFICADOR",
            "USUARIO_IDENTIFICADOR",
            "TIPO_OPERACAO_PEDIDO_IDENTIFICADOR",
            "FORMA_PAGAMENTO_IDENTIFICADOR",
            "CONDICAO_PAGAMENTO_IDENTIFICADOR",
            "SITUACAO_PEDIDO_IDENTIFICADOR",
            "DATA_PEDIDO",
            "PRAZO_ENTREGA",
            "OBSERVACAO",
            "TABELA_PRECO_IDENTIFICADOR",
            "DATA_SINCRONIZADO_MOBILE",
            "DATA_SINCRONIZADO_ERP"
          ],
          decimal: [
            "VALOR_BRUTO",
            "VALOR_DESCONTO_ITENS",
            "VALOR_DESCONTO",
            "PERCENTUAL_DESCONTO",
            "VALOR_TOTAL"
          ]
        },
        tagsInsertPersonalizadas: "configuracaoDadosPedido"
      },
      {
        field: "selectUltimoPedido",
        label: `Select consultar a Chave Primária do novo pedido salvo`,
        exemploInsert: `SELECT codigo_pedido AS PEDIDO_IDENTIFICADOR FROM pedido ORDER BY codigo_pedido DESC LIMIT 1`,
        tagsInsert: {
          string: ["PEDIDO_IDENTIFICADOR"]
        }
      },
      {
        field: "insertItemPedido",
        label: "Insert do Item do Pedido",
        multiplos: true,
        exemploInsert: `INSERT INTO venda_itens ( codigo_venda, codigo_produto, observacao, valor_desconto, valor_percentual_desconto, qtde, valor_unitario, valor_venda )
        VALUES ( {PEDIDO_IDENTIFICADOR}, {PRODUTO_IDENTIFICADOR}, "{OBSERVACAO}", {VALOR_DESCONTO}, {PERCENTUAL_DESCONTO}, {QUANTIDADE}, {VALOR_VENDA}, {VALOR_TOTAL} )`,
        tagsInsert: {
          string: [
            "PEDIDO_IDENTIFICADOR",
            "PRODUTO_IDENTIFICADOR",
            "DESCRICAO_PRODUTO",
            "OBSERVACAO"
          ],
          decimal: [
            "VALOR_VENDA",
            "VALOR_DESCONTO",
            "PERCENTUAL_DESCONTO",
            "QUANTIDADE",
            "VALOR_TOTAL"
          ]
        }
      }
    ]
  },
  configuracaoDadosSituacaoPedido: {
    titulo: "Situação do Pedido",
    icon: StoreIcon,
    enabled: ({ configuracaoBasica }) =>
      get(configuracaoBasica, "dados.utilizaSituacaoPedido", false) === true,
    valorDefault: {
      dados: {
        situacaoInicial: "",
        situacaoFinal: "",
        select: ""
      }
    },
    component: SituacaoPedido
  },
  configuracaoDadosOrdemServico: {
    titulo: "Campos personalizados da Ordem de Serviço",
    icon: BuildIcon,
    enabled: ({ configuracaoBasica }) =>
      get(configuracaoBasica, "dados.utilizaOrdemServico", false) === true,
    someteCamposPersonalizados: true
  },
  configuracaoDadosInsertOrdemServico: {
    titulo: "Inserir dados da Ordem de Serviço",
    icon: BuildIcon,
    enabled: ({ configuracaoBasica }) =>
      get(configuracaoBasica, "dados.utilizaOrdemServico", false) === true,
    tagsInsert: [
      {
        field: "insertOrdemServico",
        label: "Insert do OrdemServico",
        multiplos: true,
        exemploInsert: `INSERT INTO 
          ordem_servico ( codigo_cliente, codigo_tecnico, codigo_forma_pagamento, dt_inicio_atendimento, dt_termino_atendimento, prazo_entrega, observacao, valor_desconto, perc_desconto, valor_os_total)
          VALUES ({CLIENTE_IDENTIFICADOR}, {USUARIO_IDENTIFICADOR}, {FORMA_PAGAMENTO_IDENTIFICADOR}, STR_TO_DATE("{DATA_ORDEM_SERVICO}", "%Y-%m-%d %H:%i:%s"), STR_TO_DATE("{DATA_FINALIZADA_ORDEM_SERVICO}", "%Y-%m-%d %H:%i:%s"), "{PRAZO_ENTREGA}", "{OBSERVACAO}", {VALOR_DESCONTO}, {PERCENTUAL_DESCONTO}, {VALOR_TOTAL})`,
        tagsInsert: {
          string: [
            "CLIENTE_IDENTIFICADOR",
            "USUARIO_IDENTIFICADOR",
            "FORMA_PAGAMENTO_IDENTIFICADOR",
            "CONDICAO_PAGAMENTO_IDENTIFICADOR",
            "SITUACAO_PEDIDO_IDENTIFICADOR",
            "DATA_ORDEM_SERVICO",
            "DATA_FINALIZADA_ORDEM_SERVICO",
            "PRAZO_ENTREGA",
            "OBSERVACAO"
          ],
          decimal: [
            "VALOR_BRUTO",
            "VALOR_DESCONTO_ITENS",
            "VALOR_DESCONTO_SERVICOS",
            "VALOR_DESCONTO",
            "PERCENTUAL_DESCONTO",
            "VALOR_TOTAL"
          ]
        },
        tagsInsertPersonalizadas: "configuracaoDadosOrdemServico"
      },
      {
        field: "selectUltimoOrdemServico",
        label: `Select consultar a Chave Primária da nova Ordem de Serviço salva`,
        exemploInsert: `SELECT codigo_ordem_servico AS ORDEM_SERVICO_IDENTIFICADOR FROM ordem_servico ORDER BY codigo_ordem_servico DESC LIMIT 1`,
        tagsInsert: {
          string: ["ORDEM_SERVICO_IDENTIFICADOR"]
        }
      },
      {
        field: "insertItemOrdemServico",
        label: "Insert do Item da Ordem de Serviço",
        multiplos: true,
        exemploInsert: `INSERT INTO ordem_servico_items ( codigo_ordem_servico, codigo_produto, observacao, valor_desconto, valor_percentual_desconto, qtde, valor_unitario, valor_venda )
        VALUES ( {ORDEM_SERVICO_IDENTIFICADOR}, {PRODUTO_IDENTIFICADOR}, "{OBSERVACAO}", {VALOR_DESCONTO}, {PERCENTUAL_DESCONTO}, {QUANTIDADE}, {VALOR_VENDA}, {VALOR_TOTAL} )`,
        tagsInsert: {
          string: [
            "ORDEM_SERVICO_IDENTIFICADOR",
            "PRODUTO_IDENTIFICADOR",
            "DESCRICAO_ITEM",
            "OBSERVACAO"
          ],
          decimal: [
            "VALOR_VENDA",
            "VALOR_DESCONTO",
            "PERCENTUAL_DESCONTO",
            "QUANTIDADE",
            "VALOR_TOTAL"
          ]
        }
      },
      {
        field: "insertServicoOrdemServico",
        label: "Insert do Servico da Ordem de Serviço",
        multiplos: true,
        exemploInsert: `INSERT INTO ordem_servico_servicos ( codigo_ordem_servico, codigo_servico, observacao, valor_desconto, valor_percentual_desconto, qtde, valor_unitario, valor_venda )
        VALUES ( {ORDEM_SERVICO_IDENTIFICADOR}, {SERVICO_IDENTIFICADOR}, "{OBSERVACAO}", {VALOR_DESCONTO}, {PERCENTUAL_DESCONTO}, {QUANTIDADE}, {VALOR_VENDA}, {VALOR_TOTAL} )`,
        tagsInsert: {
          string: [
            "ORDEM_SERVICO_IDENTIFICADOR",
            "SERVICO_IDENTIFICADOR",
            "DESCRICAO_ITEM",
            "OBSERVACAO"
          ],
          decimal: [
            "VALOR_VENDA",
            "VALOR_DESCONTO",
            "PERCENTUAL_DESCONTO",
            "QUANTIDADE",
            "VALOR_TOTAL"
          ]
        }
      }
    ]
  }
};

export const getFieldsFromSQL = instrucaoSQL => {
  return
  if (!instrucaoSQL) return;
  try {
    const ast = parser.astify(
      endsWith(instrucaoSQL, ";") ? instrucaoSQL.slice(0, -1) : instrucaoSQL
    );
    return ast
      ? ast.columns.map(column => (column.as ? column.as : column.expr.column))
      : undefined;
  } catch (error) {
    return;
  }
};

export const validaSQLLimit = instrucaoSQL => {
  if (!instrucaoSQL) return;
  try {
    const ast = parser.astify(
      endsWith(instrucaoSQL, ";") ? instrucaoSQL.slice(0, -1) : instrucaoSQL
    );
    return ast ? ast.limit : undefined;
  } catch (error) {
    return;
  }
};

export const addLimitSql = instrucaoSQL => {
  if (!instrucaoSQL) return;
  if (validaSQLLimit(instrucaoSQL)) return instrucaoSQL;
  return `${instrucaoSQL} LIMIT 1`;
};
