import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";

import { useForm, Controller } from "react-hook-form";
import CustomInput from "components/CustomInput/CustomInput";
import Notificacoes from "components/Notificacoes/Notificacoes";
import { usuarioAtualizarSenhaRecuperacao } from "services/usuario";
import { authContext } from "contexts/AuthContext";
import CustomBackdrop from "components/CustomBackdrop/CustomBackdrop";
import "assets/css/login.css";

const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  form: {
    width: "80%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

function RecuperarSenha() {
  const classes = useStyles();

  const authValues = React.useContext(authContext);

  const { handleSubmit, getValues, trigger, formState, control } = useForm();

  const { errors } = formState;

  const [loading, setLoading] = React.useState(false);

  const handleSalvarNovaSenha = async novaSenha => {
    try {
      setLoading(true);
      const senhaSalva = await usuarioAtualizarSenhaRecuperacao(novaSenha);
      if (senhaSalva && senhaSalva.status === true && senhaSalva.data) {
        if (senhaSalva.data.usuarioAtualizarSenhaRecuperacao === true) {
          Notificacoes.success("Senha alterada com sucesso");
          return authValues.setUnauthStatus();
        }
      }
      Notificacoes.error(
        "Ocorreu um erro ao tentar efetuar a atualização da senha"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleCancelar = () => {
    authValues.setUnauthStatus();
  };

  const onSubmit = async data => {
    const { password } = data;
    handleSalvarNovaSenha(password);
  };

  const compararConfirmacaoSenha = value => {
    const { password = "" } = getValues();
    return value && value === password;
  };

  const validaSenha = value => {
    if (
      value &&
      (Object.keys(formState?.touched) || []).findIndex(
        t => t === "passwordConfirmacao"
      ) > 0
    ) {
      trigger("passwordConfirmacao");
    }
  };

  const getConfirmacaoMessage = () => {
    if (!errors.passwordConfirmacao) return "";
    const { type = "", message } = errors.passwordConfirmacao;
    if (type === "validate")
      return "A confirmação da senha não esta igual a senha.";
    return message;
  };

  return (
    <div className="limiter">
      <div className="container-login100">
        <div className="wrap-login100">
          <div
            className="login100-form-title"
            style={{ backgroundImage: "url(/resources/images/bg-01.jpg)" }}
          >
            <span className="login100-form-title-1">Justo Pedido Mobile</span>
            <br />
            <span className="login100-form-title-1">Alterar senha</span>
          </div>
          <div className={classes.paper}>
            <div className={classes.form}>
              <Controller
                name="password"
                control={control}
                rules={{
                  required: "O campo da nova senha é requerido",
                  validate: validaSenha
                }}
                render={props => (
                  <CustomInput
                    labelText="Nova senha"
                    id="password"
                    inputProps={{
                      type: "password"
                    }}
                    formControlProps={{
                      fullWidth: true
                    }}
                    error={!!errors.password}
                    errorMessage={
                      errors.password ? errors.password.message : ""
                    }
                    {...props}
                  />
                )}
              />
              <Controller
                name="passwordConfirmacao"
                control={control}
                rules={{
                  required: "O campo de confirmação da nova senha é requerido",
                  validate: compararConfirmacaoSenha
                }}
                render={props => (
                  <CustomInput
                    labelText="Confirmação da nova senha"
                    id="passwordConfirmacao"
                    inputProps={{
                      type: "password"
                    }}
                    formControlProps={{
                      fullWidth: true
                    }}
                    error={!!errors.passwordConfirmacao}
                    errorMessage={getConfirmacaoMessage()}
                    {...props}
                  />
                )}
              />

              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleSubmit(onSubmit)}
                startIcon={<SaveIcon />}
              >
                Salvar nova senha
              </Button>

              <Button
                fullWidth
                variant="contained"
                color="secondary"
                className={classes.submit}
                onClick={handleCancelar}
                startIcon={<CancelIcon />}
              >
                Cancelar
              </Button>
            </div>
          </div>
        </div>
      </div>
      <CustomBackdrop open={loading} />
    </div>
  );
}

RecuperarSenha.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default RecuperarSenha;
