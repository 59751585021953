import React from "react";
import PropTypes from "prop-types";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import FormGroup from "@material-ui/core/FormGroup";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Clear from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import { keys, isObject, get, isArray } from "lodash";

import CustomButton from "components/CustomButtons/Button";
import CustomSelect from "components/CustomSelect/CustomSelect";
import CustomInput from "components/CustomInput/CustomInput";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckBox";
import { useConfirmation } from "components/CustomConfirmationDialog/ConfirmationService";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInputTags from "components/CustomAutocomplete/CustomInputTags";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  textFieldSelecionavel: {
    width: "auto",
    minWidth: 200,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));

const valorSelecionavelTooltip =
  "Digite o valor e pressione enter para incluir. O valor retornado será o label ou para criar um valor com label e id utilize o separador | ex: Frete FOB|1";

const tipos = {
  texto: "Texto",
  textoLongo: "Texto Longo",
  inteiro: "Inteiro",
  decimal: "Decimal",
  monetario: "Monetário",
  data: "Data",
  hora: "Hora",
  dataHora: "Data e Hora",
  booleano: "Booleano",
  selecionavel: "Selecionável"
};

function FieldsPersonalizados({
  options,
  error,
  errorMessage,
  value = {},
  onChange,
  onRemover,
  permiteEditavel = false,
  ligacaoComFields = true,
  sempreEditavel = false
}) {
  const classes = useStyles();
  const confirm = useConfirmation();
  const [localValue, setLocalValue] = React.useState(value);
  const {
    label,
    tipo,
    fieldRelacionado,
    editavel,
    editavelObrigatorio,
    id,
    mostraLista,
    valoresSelecionavel
  } = localValue;

  const handleChange = fieldName => newValue => {
    let valorSalvar = newValue;
    if (!isArray(newValue) && isObject(newValue)) {
      if (newValue.target.type === "checkbox") {
        valorSalvar = get(newValue.target, "checked", false);
      } else valorSalvar = newValue.target.value;
    }
    let novoValue = { ...localValue, [fieldName]: valorSalvar };
    if (fieldName === "tipo" && valorSalvar === "selecionavel")
      novoValue = { ...novoValue, editavel: true };

    onChange(novoValue);
    setLocalValue(novoValue);
  };

  const handleRemover = () => {
    confirm({
      variant: "danger",
      catchOnCancel: false,
      title: "Deseja remover esse campo?"
    }).then(async () => onRemover());
  };

  return (
    <div className={classes.root}>
      <GridContainer>
        <GridItem xs>
          <InputLabel>{id}</InputLabel>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs>
          <FormGroup row>
            {ligacaoComFields === true && (
              <CustomSelect
                labelText={"Field relacionado"}
                selectProps={{
                  displayEmpty: true,
                  value: fieldRelacionado,
                  onChange: handleChange("fieldRelacionado")
                }}
                formControlProps={{
                  required: true,
                  className: classes.textField
                }}
                options={options}
              />
            )}
            <CustomInput
              labelText="Label"
              inputProps={{
                value: label,
                onChange: handleChange("label")
              }}
              formControlProps={{
                required: true,
                className: classes.textField
              }}
            />
            <CustomSelect
              labelText={"Tipo"}
              selectProps={{
                displayEmpty: true,
                value: tipo,
                onChange: handleChange("tipo")
              }}
              formControlProps={{
                required: true,
                className: classes.textField
              }}
              options={keys(tipos)
                .filter(k =>
                  permiteEditavel === true || sempreEditavel === true
                    ? k
                    : k !== "selecionavel"
                )
                .map(k => ({ value: k, label: tipos[k] }))}
            />
            {(permiteEditavel === true || sempreEditavel === true) &&
              tipo === "selecionavel" && (
                <Tooltip title={valorSelecionavelTooltip}>
                  <CustomInputTags
                    labelText={"Possíveis valores"}
                    inputProps={{
                      value: valoresSelecionavel,
                      onChange: handleChange("valoresSelecionavel")
                    }}
                    formControlProps={{
                      required: true,
                      className: classes.textFieldSelecionavel
                    }}
                  />
                </Tooltip>
              )}
            <CustomCheckbox
              labelText="Mostra na Lista"
              formControlProps={{
                className: classes.textField
              }}
              checkboxProps={{
                checked: Boolean(mostraLista) || false,
                onChange: handleChange("mostraLista")
              }}
            />
            {permiteEditavel === true || sempreEditavel === true ? (
              <>
                {sempreEditavel === false && (
                  <CustomCheckbox
                    labelText="Editável"
                    disabled={tipo === "selecionavel"}
                    formControlProps={{
                      className: classes.textField
                    }}
                    checkboxProps={{
                      checked: Boolean(editavel) || false,
                      onChange: handleChange("editavel")
                    }}
                  />
                )}
                {(editavel === true || sempreEditavel === true) && (
                  <CustomCheckbox
                    labelText="Obrigatório"
                    formControlProps={{
                      className: classes.textField
                    }}
                    checkboxProps={{
                      checked: editavelObrigatorio,
                      onChange: handleChange("editavelObrigatorio")
                    }}
                  />
                )}
              </>
            ) : null}
            {error ? (
              <Clear
                className={classes.feedback + " " + classes.labelRootError}
              />
            ) : null}
            <CustomButton
              size="sm"
              onClick={handleRemover}
              color="danger"
              startIcon={<RemoveCircleOutlineOutlinedIcon />}
            >
              Remover
            </CustomButton>
          </FormGroup>
        </GridItem>
      </GridContainer>
      {error ? (
        <GridContainer>
          <GridItem>
            {" "}
            <FormHelperText error>{errorMessage}</FormHelperText>{" "}
          </GridItem>
        </GridContainer>
      ) : null}
    </div>
  );
}

FieldsPersonalizados.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
  onRemover: PropTypes.func,
  permiteEditavel: PropTypes.bool,
  ligacaoComFields: PropTypes.bool,
  sempreEditavel: PropTypes.bool
};

export default FieldsPersonalizados;
