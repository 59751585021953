import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import RenderTagsInsert from "./RenderTagsInsert";
import InsertExemplo from "./InsertExemplo";
import InputAreaSelectInsert from "../InputAreaSelectInsert";
import ComponentSkeleton from "components/ComponentSkeleton/ComponentSkeleton";
import { Controller } from "react-hook-form";

function InsertPadraoInsert({ configuracaoInsert, isLoading, formMethods }) {
  const { errors, control } = formMethods;
  const {
    field,
    tagsInsert,
    exemploInsert,
    label = "SQL Insert",
    tagsInsertPersonalizadas
  } = configuracaoInsert;

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs>
          <RenderTagsInsert
            tagsInsert={tagsInsert}
            label={label}
            tagsInsertPersonalizadas={tagsInsertPersonalizadas}
          />
        </GridItem>
      </GridContainer>
      {exemploInsert && (
        <GridContainer>
          <GridItem xs>
            <InsertExemplo exemplo={exemploInsert} />
          </GridItem>
        </GridContainer>
      )}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name={`dados.${field}`}
              control={control}
              rules={{ required: "O campo de Insert é requerido" }}
              render={props => (
                <InputAreaSelectInsert
                  inputProps={{
                    multiline: true
                  }}
                  formControlProps={{
                    fullWidth: true,
                    required: true
                  }}
                  labelText={label}
                  error={!!get(errors, `dados.${field}`, "")}
                  errorMessage={get(errors, `dados.${field}.message`, "")}
                  {...props}
                />
              )}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
}

InsertPadraoInsert.propTypes = {
  isLoading: PropTypes.bool,
  formMethods: PropTypes.object,
  configuracaoInsert: PropTypes.object
};

export default InsertPadraoInsert;
