import { Parser } from "node-sql-parser";
import { endsWith } from "lodash";

const parser = new Parser();

export const tiposConfiguracao = {
  configuracaoIntegracao: {
    sql: false,
    valorDefault: {
      dados: {
        tipoIntegracao: "mysql",
        hostname: "127.0.0.1",
        porta: 3306
      }
    }
  },
  configuracaoDadosUsuario: {
    titulo: "Dados dos Usuários",
    sql: true,
    utilizaInsert: false,
    campos: {
      usuarioIdentificador: {
        label: "Identificação do Usuário"
      },
      nome: { label: "Nome" },
      login: { label: "Login" },
      password: { label: "Senha" },
    }
  },
  configuracaoDadosCondicaoPagamento: {
    titulo: "Dados da Condição de Pagamento",
    sql: true,
    utilizaInsert: false,
    campos: {
      condicaoPagamentoIdentificador: {
        label: "Código Identificador da Condição de Pagamento"
      },
      descricao: {
        label: "Descrição"
      }
    }
  },
  configuracaoDadosFormaPagamento: {
    titulo: "Dados da Forma de Pagamento",
    sql: true,
    utilizaInsert: false,
    campos: {
      formaPagamentoIdentificador: {
        label: "Código Identificador da Condição de Pagamento"
      },
      descricao: {
        label: "Descrição"
      }
    }
  },
  configuracaoDadosFituacaoPedido: {
    titulo: "Dados da Situação do Pedido",
    sql: true,
    utilizaInsert: false,
    campos: {
      situacaoPedidoIdentificador: {
        label: "Código Identificador da Condição de Pagamento"
      },
      descricao: {
        label: "Descrição"
      }
    }
  },
  configuracaoDadosProduto: {
    titulo: "Dados do Produto",
    sql: true,
    utilizaInsert: false,
    campos: {
      produtoIdentificador: {
        label: "Código Identificador da Condição de Pagamento"
      },
      descricao: {
        label: "Descrição"
      },
      descricaoDetalhada: {
        label: "Descrição Detalhada do Produto"
      },
      valorUnitario: {
        label: "Valor de venda do Produto"
      },
      estoqueAtual: {
        label: "Estoque Atual"
      },
      observacao: {
        label: "Observação",
        optional: true
      },
      grupo: {
        label: "Nome do Grupo",
        optional: true
      },
      ativo: {
        label: "Situação Ativa do Produto"
      }
    }
  },
  configuracaoDadosCliente: {
    titulo: "Dados do Cliente",
    sql: true,
    utilizaInsert: true,
    campos: {
      clienteIdentificador: {
        label: "Código Identificador do Cliente"
      },
      nome: {
        label: "Nome"
      },
      tipoPessoa: {
        label: "Tipo de Pessoa (F) Física (J) Jurídica"
      },
      docFederal: {
        label: "Doc. Federal CPF / CNPJ"
      },
      docEstadual: {
        label: "Doc. Estadual RG / Inscrição Estadual"
      },
      telefone: {
        label: "Telefone",
        optional: true
      },
      email: {
        label: "Email",
        optional: true
      },
      cep: {
        label: "Cep",
        optional: true
      },
      cidade: {
        label: "Cidade",
        optional: true
      },
      estado: {
        label: "Estado - Sigla",
        optional: true
      },
      endereco: {
        label: "Endereço",
        optional: true
      },
      numero: {
        label: "Numero",
        optional: true
      },
      bairro: {
        label: "Bairro",
        optional: true
      },
      complemento: {
        label: "Complemento",
        optional: true
      },
      observacao: {
        label: "Observação",
        optional: true
      },
      ativo: {
        label: "Situacao Ativo (Boolean)"
      },
      usuarioIdentificador: {
        label: "Identificação do Usuário Vendedor"
      }
    }
  },
};

export const getFieldsFromSQL = instrucaoSQL => {
  if (!instrucaoSQL) return;
  try {
    const ast = parser.astify(
      endsWith(instrucaoSQL, ";") ? instrucaoSQL.slice(0, -1) : instrucaoSQL
    );
    return ast
      ? ast.columns.map(column => (column.as ? column.as : column.expr.column))
      : undefined;
  } catch (error) {
    return;
  }
};

export const validaSQLLimit = instrucaoSQL => {
  if (!instrucaoSQL) return;
  try {
    const ast = parser.astify(
      endsWith(instrucaoSQL, ";") ? instrucaoSQL.slice(0, -1) : instrucaoSQL
    );
    return ast ? ast.limit : undefined;
  } catch (error) {
    return;
  }
};

export const addLimitSql = instrucaoSQL => {
  if (!instrucaoSQL) return;
  if (validaSQLLimit(instrucaoSQL)) return instrucaoSQL;
  return `${instrucaoSQL} LIMIT 1`;
};
