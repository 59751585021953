import { doResquestGraphql } from "../helpers/graphql";

const cepFields = `
  cep
  logradouro
  complemento
  bairro
  localidade
  uf
  unidade
  ibge
  gia
  cidade {
    id
    nome
    estado {
      id
      sigla
    }
  }
`;

export async function consultaCep(cep, fieldsToList) {
  const query = `
    query doConsultaCep($cep: String!) {
      consultaCep(cep: $cep) {
        ${fieldsToList ? fieldsToList : cepFields}
      }
    }
  `;

  const variables = {
    cep
  };

  const retorno = await doResquestGraphql(query, variables);
  if (retorno && retorno.status) return retorno.data.consultaCep;
  return;
}
