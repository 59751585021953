import React from "react";
import { get } from "lodash";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import TabSelectSelect from "./TabSelectSelect";
import { Controller, useFormContext } from "react-hook-form";
import ComponentSkeleton from "components/ComponentSkeleton/ComponentSkeleton";
import { useConfiguracoesBasicasContext } from "customHooks/ConfiguracoesBasicasContext";
import CustomTimeEdit from "components/CustomTimeEdit/CustomTimeEdit";

function TabSelect({ configuracaoConst, isLoading }) {
  const {
    configuracaoBasica: { dados = {} } = {}
  } = useConfiguracoesBasicasContext();
  const { utilizaFormaPagamentoCliente } = dados;

  const { control, errors } = useFormContext();
  const {
    campos,
    camposPersonalizados,
    camposPersonalizadosEditavel,
    selectItens,
    selectFormaPagamento
  } = configuracaoConst;
  const camposToUse = Array.isArray(campos)
    ? campos
    : [{ field: "select", campos }];

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="dados.tempoSincronizacao"
              control={control}
              render={props => (
                <CustomTimeEdit
                  formControlProps={{
                    fullWidth: true,
                    required: true
                  }}
                  labelText="Intervalo para cada sincronização"
                  error={!!get(errors, "dados.tempoSincronizacao")}
                  errorMessage={get(errors, "dados.tempoSincronizacao.message")}
                  {...props}
                />
              )}
            />
          </ComponentSkeleton>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="dados.quantidadeRegistrosPaginar"
              control={control}
              rules={{
                required:
                  "A quantidade mínima de registros por páginação é 10 e máxima 100",
                min: {
                  value: 10,
                  message:
                    "A quantidade mínima de registros por páginação é 10 e máxima 100"
                },
                max: {
                  value: 100,
                  message:
                    "A quantidade mínima de registros por páginação é 10 e máxima 100"
                }
              }}
              render={props => (
                <CustomInput
                  inputProps={{
                    type: "number",
                    min: 10,
                    max: 100
                  }}
                  formControlProps={{
                    fullWidth: true,
                    required: true
                  }}
                  labelText="Quantidade de registro para paginar por requisição (Mín. 10, Máx 100)"
                  error={!!get(errors, "dados.quantidadeRegistrosPaginar")}
                  errorMessage={get(
                    errors,
                    "dados.quantidadeRegistrosPaginar.message"
                  )}
                  {...props}
                />
              )}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>

      {camposToUse.map((configuracaoCampo, index) => (
        <TabSelectSelect
          key={`tab_select_${index}`}
          configuracaoCampo={configuracaoCampo}
          camposPersonalizados={camposPersonalizados}
          camposPersonalizadosEditavel={camposPersonalizadosEditavel}
        />
      ))}

      {selectItens && (
        <TabSelectSelect
          configuracaoCampo={{
            field: "selectItens",
            campos: selectItens.campos,
            label: "SQL Select Itens",
            exemploSql: selectItens.exemploSql
          }}
        />
      )}

      {selectFormaPagamento && utilizaFormaPagamentoCliente && (
        <TabSelectSelect
          configuracaoCampo={{
            field: "selectFormaPagamentoCliente",
            campos: selectFormaPagamento.campos,
            label: "SQL Select Formas Pagamento do Cliente",
            exemploSql: selectFormaPagamento.exemploSql
          }}
        />
      )}
    </React.Fragment>
  );
}

TabSelect.propTypes = {
  configuracaoConst: PropTypes.object,
  isLoading: PropTypes.bool
};

export default TabSelect;
