import React from "react";
import { authContext } from "contexts/AuthContext";
import Button from "components/CustomButtons/Button";
import CadastroPage from "components/CustomPages/CadastroPage";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import FormTrocarSenha from "./trocarSenha/FormTrocarSenha";

function UsuarioPerfil() {
  const { state } = React.useContext(authContext);
  const [isOpenTrocarSenha, setIsOpenTrocarSenha] = React.useState(false);

  const { dadosUsuario } = state;

  const { nome, email, telefone } = dadosUsuario;

  const handleTrocarSenha = () => {
    setIsOpenTrocarSenha(true);
  };

  const handleCancelarTrocarSenha = () => {
    setIsOpenTrocarSenha(false);
  };

  return (
    <>
      <CadastroPage labelTituloHeader="Perfil do Usuário">
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="Nome"
              id="nome"
              inputProps={{
                value: nome,
                readOnly: true
              }}
              formControlProps={{
                fullWidth: true
              }}
            />
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="Email"
              id="email"
              inputProps={{
                readOnly: true,
                value: email
              }}
              formControlProps={{
                fullWidth: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomInput
              labelText="Telefone"
              id="telefone"
              inputProps={{
                value: telefone,
                readOnly: true
              }}
              formControlProps={{
                fullWidth: true
              }}
            />
          </GridItem>
        </GridContainer>
      </CadastroPage>

      <Button color="info" onClick={handleTrocarSenha}>
        Trocar Senha
      </Button>

      <FormTrocarSenha
        isOpen={isOpenTrocarSenha}
        onClose={handleCancelarTrocarSenha}
      />
    </>
  );
}

UsuarioPerfil.propTypes = {};

export default UsuarioPerfil;
