import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { compact } from "lodash";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
// core components
import styles from "assets/jss/material-dashboard-react/components/customInputStyle.js";
import { FormHelperText } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function CustomSelect(props) {
  const classes = useStyles();
  const {
    native = true,
    formControlProps,
    labelSempreAcima,
    labelText,
    id,
    labelProps,
    options = [],
    selectProps,
    error,
    errorMessage,
    success,
    ...otherProps
  } = props;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });
  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined
  });

  const { shrink = labelSempreAcima, ...othersLabelProps } = labelProps || {};

  return (
    <FormControl
      {...formControlProps}
      className={formControlProps.className + " " + classes.formControl}
    >
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + labelClasses}
          classes={{ shrink: classes.labelShrink }}
          shrink={shrink}
          // htmlFor={id}
          {...othersLabelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      <Select
        native={native}
        classes={{
          root: marginTop,
          disabled: classes.disabled
        }}
        id={id}
        {...selectProps}
        {...otherProps}
      >
        {options.map(o => {
          let { value, label } = o;
          if (typeof o === "string") {
            value = o;
            label = o;
          }
          if (!label) label = value;
          return (
            <option key={value} value={value}>
              {label}
            </option>
          );
        })}
      </Select>
      {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
      {error ? <FormHelperText error>{errorMessage}</FormHelperText> : null}
    </FormControl>
  );
}

CustomSelect.propTypes = {
  native: PropTypes.bool,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  labelSempreAcima: PropTypes.bool,
  id: PropTypes.string,
  selectProps: PropTypes.object,
  options: PropTypes.array,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  success: PropTypes.bool
};
