import React from "react";
import PropTypes from "prop-types";
import MapeamentoFieldsPersonalizados from "../mapeamentoFields/MapeamentoFieldsPersonalizados/MapeamentoFieldsPersonalizados";
import { useFormContext } from "react-hook-form";

function SomenteCamposPersonalizados({ isLoading }) {
  const formMethods = useFormContext();
  return (
    <MapeamentoFieldsPersonalizados
      ligacaoComFields={false}
      camposPersonalizadosEditavel={true}
      sempreEditavel={true}
      formMethods={formMethods}
      isLoading={isLoading}
    />
  );
}

SomenteCamposPersonalizados.propTypes = {
  isLoading: PropTypes.bool
};

export default SomenteCamposPersonalizados;
