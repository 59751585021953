import React from "react";
import { useController } from "react-hook-form";

import ComponentSkeleton from "components/ComponentSkeleton/ComponentSkeleton";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";

export default function FormSwitch({
  isLoading = true,
  color = "primary",
  label,
  control,
  defaultValue,
  name,
  rules,
  ...otherProps
}) {
  const {
    field: { ref, ...inputProps }
  } = useController({
    name,
    control,
    rules,
    defaultValue
  });

  return (
    <ComponentSkeleton showSkeleton={isLoading}>
      <CustomSwitch
        labelText={label}
        id={name}
        switchProps={{
          color
        }}
        inputRef={ref}
        {...inputProps}
        {...otherProps}
      />
    </ComponentSkeleton>
  );
}
