import React from "react";
import SituacaoGerenciador from "./SituacaoGerenciador/SituacaoGerenciador";
import { useConfiguracoesBasicasContext } from "customHooks/ConfiguracoesBasicasContext";
import { Fab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import StopIcon from "@material-ui/icons/Stop";
import { red } from "@material-ui/core/colors";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Notificacoes from "components/Notificacoes/Notificacoes";
import { warningColor } from "assets/jss/material-dashboard-react.js";

import {
  filialGerenciadorReiniciar
} from "services/mobileFilialGerenciador";

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  cancelButton: {
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700]
    }
  },
  limparDadosServidorButton: {
    backgroundColor: warningColor[0],
    "&:hover": {
      backgroundColor: warningColor[2]
    }
  },
  labelText: {
    textShadow: "0px 8px 11px #949494"
  }
}));

export default function Home() {
  const { filial } = useConfiguracoesBasicasContext();
  console.log("filial", filial)
  const { identificadorApp } = filial;

  const classes = useStyles();

  const doReiniciar = async () => {
    const retorno = await filialGerenciadorReiniciar(filial.identificadorApp);
    if (retorno === true) {
      Notificacoes.success("Solicitado o reinicio do gerenciador");
    } else
      Notificacoes.error(
        "Não foi possível solicitar o reinicio do gerenciador"
      );
  };

  return (
    <>
      <SituacaoGerenciador identificadorApp={identificadorApp} />
      <br />
      <br />
      <GridContainer justify="left" alignItems="left">
        <GridItem>
          <GridContainer
            direction="column"
            justify="center"
            alignItems="center"
          >
            <GridItem>
              <Fab
                onClick={doReiniciar}
                disabled={!filial.identificadorApp}
                classes={{
                  root: classes.limparDadosServidorButton
                }}
              >
                <StopIcon style={{ color: "#fff" }} />
              </Fab>
            </GridItem>
            <GridItem>
              <span className={classes.labelText}>Reiniciar Gerenciador</span>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </>
  );
}
