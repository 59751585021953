import React from "react";
import PropTypes from "prop-types";
import CustomInput from "components/CustomInput/CustomInput";
import ComponentSkeleton from "components/ComponentSkeleton/ComponentSkeleton";
import { Controller } from "react-hook-form";

export default function Porta({ isLoading, control, errors, tipoIntegracao }) {
  return !["sqlite"].includes(tipoIntegracao) ? (
    <ComponentSkeleton showSkeleton={isLoading}>
      <Controller
        name="dados.porta"
        control={control}
        rules={{
          required: "O Hostname é requerida."
        }}
        render={props => (
          <CustomInput
            key={tipoIntegracao === "mssql" ? "portaMssql" : "porta"}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: "number"
            }}
            labelText="Porta"
            error={!!errors.porta}
            errorMessage={errors.porta ? errors.porta.message : ""}
            {...props}
          />
        )}
      />
    </ComponentSkeleton>
  ) : null;
}

Porta.propTypes = {
  isLoading: PropTypes.bool,
  control: PropTypes.any,
  errors: PropTypes.object,
  valorDefault: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tipoIntegracao: PropTypes.string
};
