import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import CustomSelect from "components/CustomSelect/CustomSelect";
import ComponentSkeleton from "components/ComponentSkeleton/ComponentSkeleton";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Page from "components/CustomPages/Page";

const tipoCampoSenhaListar = [
  {
    value: "texto",
    label: "Texto Puro"
  },
  {
    value: "md5",
    label: "MD5"
  },
  {
    value: "criadaApp",
    label: "Criada no App"
  },
  {
    value: "sha1",
    label: "SHA1"
  }
];

function ConfiguracaoUsuario({ isLoading, control }) {
  return (
    <Page labelTituloHeader="Usuário">
      <GridContainer>
        <GridItem xs={12} sm={8} md={6}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="dados.tipoValidacaoCampoSenhaUsuario"
              control={control}
              rules={{
                required: "O tipo de Integração é requerido!"
              }}
              render={props => (
                <CustomSelect
                  labelText="Tipo do campo de senha do usuário"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  options={tipoCampoSenhaListar}
                  {...props}
                />
              )}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>
    </Page>
  );
}

ConfiguracaoUsuario.propTypes = {
  isLoading: PropTypes.bool,
  control: PropTypes.any,
  configuracaoSalva: PropTypes.object
};

export default ConfiguracaoUsuario;
