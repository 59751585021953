import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomSelect from "components/CustomSelect/CustomSelect";
import ComponentSkeleton from "components/ComponentSkeleton/ComponentSkeleton";

const tipoIntegracaoListar = [
  {
    value: "mysql",
    label: "Banco de dados Mysql"
  },
  {
    value: "mariadb",
    label: "Banco de Dados MariaDB"
  },
  {
    value: "postgres",
    label: "Banco de Dados PostgreSQL"
  },
  {
    value: "sqlite",
    label: "Banco de Dados SQLite"
  },
  {
    value: "mssql",
    label: "Banco de Dados SQL Server"
  },
  {
    value: "firebird",
    label: "Banco de Dados Firebird"
  }  
];

export default function TipoIntegracao({ isLoading, control, setValue }) {
  const handleChangeDB = ([event]) => {
    const tipo = event.target.value;
    switch (tipo) {
      case "mysql":
      case "mariadb":
        setValue("porta", 3306, true);
        break;
      case "postgres":
        setValue("porta", 5432, true);
        break;
      case "firebird":
          setValue("porta", 5030, true);
          break;
      default:
        break;
    }
    return tipo;
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={8}>
        <ComponentSkeleton showSkeleton={isLoading}>
          <Controller
            name="dados.tipoIntegracao"
            control={control}
            rules={{
              required: "O tipo de Integração é requerido!"
            }}
            onChange={handleChangeDB}
            defaultValue="mysql"
            render={props => (
              <CustomSelect
                labelText="Tipo da Integração"
                formControlProps={{
                  fullWidth: true
                }}
                options={tipoIntegracaoListar}
                {...props}
              />
            )}
          />
        </ComponentSkeleton>
      </GridItem>
    </GridContainer>
  );
}

TipoIntegracao.propTypes = {
  isLoading: PropTypes.bool,
  control: PropTypes.any,
  setValue: PropTypes.func
};
