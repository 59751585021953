import { doLogout } from "contexts/AuthContext";
import Notificacoes from "../components/Notificacoes/Notificacoes";
import { fromPdvAPI } from "./axiosRequest";

const getFieldsErros = errors => {
  const fieldErrors = {};
  const errorMessages = [];
  for (const error of errors) {
    const { message = "" } = error;
    if (message.includes("Field value.")) {
      const values = message.split("value.")[1].split(" ");
      const field = values.splice(0, 1)[0];
      if (field) {
        if (values.some(v => v.toLowerCase() === "required"))
          fieldErrors[field] = "O campo é requerido.";
        else fieldErrors[field] = values.join(" ");
      }
    } else errorMessages.push(error.message);
  }
  return { fieldErrors, errorMessages };
};

export async function doResquestGraphql(query, variables) {
  let retorno = {
    status: false
  };
  const data = {
    query
  };
  if (variables) data.variables = variables;
  const payload = {
    metodo: "post",
    url: process.env.REACT_APP_BACKEND_URL_GRAPHQL,
    data
  };
  try {
    const dadosRequest = await fromPdvAPI(payload);
    retorno.data = dadosRequest.data.data;
    retorno.status = true;
  } catch (error) {
    retorno.errors = error;
    console.log(error);

    if (error.response && error.response.errors) {
      const { errors = [] } = error.response;
      const unauthorized = errors.some(e => e.statusCode === 401);
      if (unauthorized) {
        doLogout();
        Notificacoes.info("Sessão expirada!");
      }
      retorno = { ...retorno, errors, ...getFieldsErros(errors) };
    }
  }
  return retorno;
}
