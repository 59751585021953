import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import ComponentSkeleton from "components/ComponentSkeleton/ComponentSkeleton";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import Page from "components/CustomPages/Page";
import CustomInput from "components/CustomInput/CustomInput";

function ConfiguracaoProduto({ isLoading, control, watch }) {
  const _produtoComImagem = watch("dados.produtoComImagem");
  const _catalogoProduto = watch("dados.catalogoProduto");

  return (
    <Page labelTituloHeader="Produto">
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="dados.permiteVendaProdutoSemEstoque"
              valueName="checked"
              control={control}
              render={props => (
                <CustomSwitch
                  labelText="Permite venda de Produtos com estoque menor ou igual a 0?"
                  {...props}
                />
              )}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="dados.produtoComImagem"
              valueName="checked"
              control={control}
              render={props => (
                <CustomSwitch
                  labelText="Produto utiliza campo de Url de Imagem?"
                  {...props}
                />
              )}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>

      {/* <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="dados.catalogoProduto"
              valueName="checked"
              control={control}
              render={<CustomSwitch labelText="Utiliza catalogo de Produto?" />}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer> */}

      {/* <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="dados.imagemServerAuthToken"
              control={control}
              render={
                <CustomInput
                  inputProps={{
                    disabled: !_produtoComImagem && !_catalogoProduto
                  }}
                  formControlProps={{
                    fullWidth: true
                  }}
                  labelText="Token validação servidor de imagens"
                />
              }
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer> */}

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="dados.produtoFazDownloadImagem"
              valueName="checked"
              control={control}
              render={props => (
                <CustomSwitch
                  disabled={!_produtoComImagem && !_catalogoProduto}
                  labelText="Efetua download da imagem para utilizar offline?"
                  {...props}
                />
              )}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="dados.utilizaTabelaPreco"
              valueName="checked"
              control={control}
              render={props => (
                <CustomSwitch
                  labelText="Utiliza tabela de preço para produtos?"
                  {...props}
                />
              )}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="dados.utilizaProdutoFracionado"
              valueName="checked"
              control={control}
              onChange={([event]) => event.target.checked}
              as={
                <CustomSwitch labelText="Utiliza quantidade de produto fracionada?" />
              }
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>
    </Page>
  );
}

ConfiguracaoProduto.propTypes = {
  isLoading: PropTypes.bool,
  control: PropTypes.any,
  watch: PropTypes.func
};

export default ConfiguracaoProduto;
