import React from "react";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import { makeStyles } from "@material-ui/core/styles";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "bold",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

const PoliticaPrivacidade = () => {
  const classes = useStyles();

  return (
    <GridContainer justify="center">
      <GridItem xs={6} sm={6} md={6}>
        <Card>
          <CardHeader color="info">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h4 className={classes.cardTitleWhite}>
                  Política de Privacidade
                </h4>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <h5>Política de Privacidade</h5>
            <p>
              1. Esta Política de Privacidade se refere aos dados pessoas que
              o(a) Justo Pedido Mobile poderá obter quando seus Usuários
              utilizam do: serviços prestados durante a navegação dentro de
              nosso ambiente virtual. Nesta Poltica de Privacidade, sera
              descrita de que forma serao coletados e armazenados dados a
              respeito dos Usuários, assim como a forma pela qual tais dados
              poderão ser utilizados e armazenados nos termos da Le 12.965/2014
              ("Marco Civil da Internet"), do Decreto 8.771/2016 e da Lei n.°
              13.709/2018 (Lei Geral de Proteção de Dados) Ao aceitar a presente
              Política de Privacidade é declarado que todo seu conteúdo foi
              devidamente lido e aceito, tendo assim todas as cláusula:
              devidamente validadas pelo Usuário para que atuem em pleno vigor
              Ademais, deixa claro que esta Politica de Privacidade podera ser
              atualizada a qualquer tempo, a exclusivo critério dos
              administradores do ambiente virtual aqui discutido. Porém, deixa
              claro que toda e qualquer comunicação será devidamente comunicada
              aos Usuários para nova aceitacão de modo de que, caso continuem em
              utilizar os servicos providenciados. estarão automaticamente
              concordando com as alteracões realzadas no corpo desta politica:
            </p>

            <h5>
              Obtenção, Armazenamento e Cuidado dos dados pessoais coletados.
            </h5>

            <p>
              2. Aceitando nossa política de Privacidade, fica concedido, por
              livre e espontânea vontade, permissão para a coleta e
              armazenamento dos dados pessoas dos Usuários, que serão tratados
              da forma abaixo descrita: • Eventuais dados de navegação serão
              utilizados para possibilitar o seu acesso e saber como entrar em
              contato com o Usuário quando foi necessário. Além de utilizados
              para compreender seus interesses, como forma de garantir constante
              melhoria e evolução dos serviço: providenciados • Os dados
              pessoais, poderão ser armazenados em outros países onde o(a) Justo
              Pedido Mobile ou suas afiliadas possuam presença. Casc tais
              jurisdiçoes possuam diferentes leis de proteçao de dados, fica
              estabelecido o compromisso de armazenar e cuidar dos dados de
              acordo com tais leis e a nresente Política de Privacidade • Os
              Dados pessoais poderão ser utilizados de forma anônima para fins
              estatísticos e de controle e melhora dos nossos serviços • Serão
              estabelecidas medidas de segurança razoáveis para proteger todos
              os dados pessoais providenciados. Entretanto, lembramos que nãc
              existe uma medida de segurança 100% eficaz • Eventualmente, todos
              os dados coletados pelo Usuário durante o uso dos servicos
              prestados, poderão ser excluídos ao momento em que c Usuario
              desejar, dito isso, somos obrigados a manter os registros de
              conexao durante o prazo legal. • Esta Política de Privacidade se
              aplica a todos os websites detidos pelo(a) Justo Pedido Mobile ou
              qualquer outra página, mídia social ferramenta, software ou
              conteúdo de sua propriedade
            </p>
            <p>
              3. O tato de usar o site e / ou serviço disponibilizado contrma o
              consentimento inequivoco e incondicional do Usuario com esta
              Politica incluindo os termos de processamento de seus dados
              pessoais. Na ausência de consentimento do usuário com esta
              política e os termos de processamento de seus dados pessoas, o
              Usuário deve parar de usar o site e / ou o serviço providenciados,
              reservando à administração o direito de impeair o acesso do
              referido Usuario.
            </p>
            <p>
              4. Este ambiente é destinado a usuários com 18 (dezoito) anos de
              idade ou mais. Se você tem menos de 18 (dezoito) anos, não poderá
              usar ou registrar-se para usar este site ou seus serviços sem a
              permissao ou consentimento dos pais. Ao concordar com esta
              politica de Privacidade você tem a capacidade legal necessária
              para cumprir e ficar vinculado por esta política de Privacidade
            </p>

            <h5>Armazenamento de informacões</h5>

            <p>
              5. E reconhecida a natureza sensivel e contdencial dos dados e
              demais intormaçoes obtidas e armazenadas. Assim hca estabelecido c
              compromisso de manter estas informações de natureza confidencial
              em sigilo, sem utilizá-las ou divulgá-las sem a autorização do
              Usuário, excetc nos termos previstos nos lermos de Uso e na
              Politica de Privacidade, bem como nos limites necessaros para a
              execuçao das obrigaçoes contratuais e legais, assim como para a
              defesa dos interesses do(a) Justo Pedido Mobile e dos Usuários.
            </p>

            <h5>Cuidado com as informacões e uso de Cookies</h5>

            <p>
              6. O Usuário concorda que o(a) Justo Pedido Mobile poderá coletar,
              registrar, organizar, acumular, armazenar, atualizar, extrair,
              usar, transferir incluindo transferência para outros países onde
              possua parceiros e/ou afiliadas, remover e destruir dados pessoais
              e outras informações
            </p>
            <p>
              7. Os atos descritos acima poderao ser processados de forma manual
              e/ou com o uso de automacao. O presente consentimento e valido até
              a su retirada das configurações do Usuário e/ou até que seja
              solicitado pelo Usuário de forma direta. A solicitação pode ser
              enviada por escrito para o endereço eletrônico:
              rafikrafael@gmail.com
            </p>
            <p>
              8. Dentro dos limites da legislação aplicável, o(a) Justo Pedido
              Mobile tem o direito de transferir as informações fornecidas pelo
              Usuário para terceiros.
            </p>
            <p>
              9. No tratamento de dados pessoais, serão tomadas as medidas
              legais, técnicas e organizacionais necessárias para proteger os
              dados pessoai contra o acesso não autorizado ou acidental,
              destruição, modificação, bloqueio, cópia, disposição, distribuição
              de dados pessoais, bem com outras ações ilegais em relação a dados
              pessoais em cumprimento dos requisitos da legislação brasileira
              e/ou qualquer outra que venha a sei aplicável. O Usuário concorda
              que algumas das informações, incluindo dados pessoais, ficam
              disponíveis publicamente ao usar determinado: serviços e / ou o
              site.
            </p>
            <p>
              10. Justo Pedido Mobile taz uso de cookies. Ao acessar nosso site,
              voce concorda em usar cookies nos termos da nossa Poltica de
              Cookies. Salienta-se que algum dos nossos parceiros de site podem
              usar cookies
            </p>

            <h5>Manutencão dos dados pelo Usuário</h5>

            <p>
              11. O usuário tem o direito de requerer a exclusão de seus dados
              pessoais coletados durante o uso do ambiente eletrônico
              disponibilizado : qualquer momento, utilizando-se serviço
              relevante disponibilizado pelo próprio ambiente, ou por meio de
              contato direto com a administração po meio do endereco eletrônico
              disponibilizado acima. Estes direitos podem ser restringidos da
              maneira prescrita pela legislacão brasileira
            </p>
            <p>
              12. Caso o Usuario tenha ciencia de que seus dados pessoas tenham
              sido comprometidos, de forma que podera afetar seu acesso ao
              ambient
            </p>
            <p>
              13. O Usuário é o único responsável por suas ações relacionadas ao
              uso do site e / ou serviços disponibilizados, significando que, se
              tais açõe resultarem em violação dos direitos e interesses
              legítimos de terceiros, bem como descumpre com a legislação do
              Brasil, o Usuário concorda er indenizar os prejuízos causados
              ao(à) Justo Pedido Mobile e / ou terceiros como resultado da não
              execução ou má execução das obrigações de Usuário sob esta
              Política e / ou a legislação.
            </p>

            <h5>Responsabmdades e competencia:</h5>
            <p>
              14. O Usuário usa os Serviços por sua conta e risco, sendo o único
              responsável por perdas incorridas como resultado do mal-uso pelo
              de ambiente e / ou dos servicos da Empresa
            </p>
            <p>
              15. O(A) Justo Pedido Mobile coopera com as autoridades
              competentes e com terceiros para garantir o cumprimento das leis,
              salvaguardar a integridade e a segurança da Plataforma e de seus
              usuários, e impedir condutas que prejudiquem a integridade moral e
              a honra das pessoas física:
            </p>
            <p>
              16. As disposições desta Política são regidas pelas leis do
              Brasil. Se, por uma razão ou outra, uma ou mais disposições desta
              Política foren consideradas invaldas, isso nao areta as demais
              disposiçoes
            </p>
            <p>
              17. Para todas as questoes o Usuario pode enviar um pedido para o
              endereço da empresa para a seguinte conta: rankrafael@gmall.com
            </p>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default PoliticaPrivacidade;
