import React from "react";
import PropTypes from "prop-types";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
}));

function CustomBackdrop({
  open = false,
  onClick,
  textoAguarde = "Aguarde processando requisição"
}) {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={open} onClick={onClick}>
      <h2>{textoAguarde}</h2>
      <br />
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

CustomBackdrop.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func,
  textoAguarde: PropTypes.string
};

export default CustomBackdrop;
