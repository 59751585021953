import React from "react";
import MaskedInput from "react-text-mask";
import BrV from "br-validations";
import { omit, isString, isEmpty } from "lodash";
import PropTypes from "prop-types";
import { fromPdvAPI } from "helpers/axiosRequest";

export const cpfCnpjValidador = (tipoPessoa, value) => {
  if (!tipoPessoa) return;
  const valuePessoa = valuesTipoPessoa[tipoPessoa];
  if (value && !valuePessoa.validador(value)) {
    return `O ${valuePessoa.label} não é válido!`;
  }
  return;
};

const valuesTipoPessoa = {
  F: {
    label: "CPF",
    mask: [
      /\d/,
      /\d/,
      /\d/,
      ".",
      /\d/,
      /\d/,
      /\d/,
      ".",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/
    ],
    validador: value => BrV.cpf.validate(value),
    size: 11
  },
  J: {
    label: "CNPJ",
    mask: [
      /\d/,
      /\d/,
      ".",
      /\d/,
      /\d/,
      /\d/,
      ".",
      /\d/,
      /\d/,
      /\d/,
      "/",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/
    ],
    validador: value => BrV.cnpj.validate(value),
    size: 14
  }
};

const doRequestCnpj = async docFederal => {
  try {
    const payload = {
      metodo: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/contratante/consulta-cnpj/${docFederal}`
    };
    const retorno = await fromPdvAPI(payload);
    return retorno.status === 200 && retorno.data && !retorno.data.erro
      ? retorno.data
      : undefined;
    // eslint-disable-next-line no-empty
  } catch (error) {}
  return;
};

const getSomenteNumeros = value =>
  (value.match(/\d/g) || []).map(Number).join("");

export default function DocumentoFederalInput(props) {
  const {
    tipoPessoa = "F",
    inputRef,
    className,
    placeholder,
    showMask = false,
    useAntdInput = false,
    width = 150,
    onChange,
    placeholderChar = "_",
    value,
    defaultValue,
    readOnly = false,
    onGetDadosDocFederal,
    renderCarregando,
    onCarregando,
    onBlur,
    ...otherProps
  } = props;

  const valueToUse = React.useMemo(() => {
    if (isString(value)) return value;
    const { target: { value: valueI = "" } = {} } = value || {};
    return valueI;
  }, [value]);

  const [ultimoValorPesquisado, setUltimoValorPesquisado] = React.useState(
    defaultValue || valueToUse
  );

  const [carregando, setCarregando] = React.useState(false);

  const handleChange = event => {
    let valorTratado = "";
    if (isString(event)) {
      valorTratado = event;
    } else {
      const { target: { value = "" } = {} } = event;
      valorTratado = String(value)
        .replace(new RegExp(placeholderChar, "g"), " ")
        .trim();
    }

    if (onChange) onChange(valorTratado);
  };

  const doOnBlur = async e => {
    const { target } = e;

    if (
      target.value
      // !isEmpty(target.value) &&
      // String(getSomenteNumeros(target.value)).length > 11 &&
      // readOnly !== true &&
      // ultimoValorPesquisado !== target.value &&
      // onGetDadosDocFederal
    ) {
      setCarregando(true);
      if (onCarregando) onCarregando(true);
      const dadosConsulta = await doRequestCnpj(
        getSomenteNumeros(target.value)
      );
      if (dadosConsulta) {
        setUltimoValorPesquisado(target.value);
        onGetDadosDocFederal(dadosConsulta);
      }
      setCarregando(false);
      if (onCarregando) onCarregando(false);
    }
    if (onBlur) onBlur(e);
  };

  const valuePessoa = valuesTipoPessoa[tipoPessoa];

  if (carregando && renderCarregando) return renderCarregando;

  return valuePessoa ? (
    <MaskedInput
      mask={valuePessoa.mask}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      placeholderChar={placeholderChar}
      showMask={showMask}
      {...omit(otherProps, ["onChange", "value", "onBlur"])}
      onChange={handleChange}
      onBlur={doOnBlur}
      value={valueToUse}
      defaultValue={defaultValue}
      render={(ref, propsI) => (
        <input
          name="docFederal"
          placeholder={placeholder}
          ref={ref}
          style={{ width: width }}
          className={`${useAntdInput ? "ant-input" : ""} ${className}`}
          {...propsI}
        />
      )}
    />
  ) : null;
}

DocumentoFederalInput.propTypes = {
  tipoPessoa: PropTypes.string,
  placeholder: PropTypes.string,
  showMask: PropTypes.bool,
  onChange: PropTypes.func,
  inputRef: PropTypes.func,
  placeholderChar: PropTypes.string,
  className: PropTypes.string,
  useAntdInput: PropTypes.bool,
  width: PropTypes.number
};
