import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { debounce } from "lodash";
import PropTypes from "prop-types";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import CustomInput from "components/CustomInput/CustomInput";

import "assets/css/login.css";
import Notificacoes from "components/Notificacoes/Notificacoes";
import { usuarioEmailExiste } from "services/usuario";
import { usuarioRecuperarSenha } from "services/usuario";
import CustomBackdrop from "components/CustomBackdrop/CustomBackdrop";

const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  form: {
    width: "80%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

function EsqueciMinhaSenha({ history }) {
  const classes = useStyles();
  const [emailValidado, setEmailValidado] = useState(false);
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, errors, setError, clearErrors } = useForm({
    mode: "onChange"
  });

  const checkEmailExiste = debounce(async valor => {
    if (!valor) return setEmailValidado(false);
    const retorno = await usuarioEmailExiste(valor);
    setEmailValidado(!!retorno);
    if (!retorno) setError("email", "notMatch", "Email não cadastrado!");
    else clearErrors(["email"]);
  }, 300);

  const onSubmit = async data => {
    const { email } = data;
    try {
      setLoading(true);
      const retorno = await usuarioRecuperarSenha(email);
      if (retorno) {
        Notificacoes.success(
          "Foi enviado um e-mail com o link de recuperação para a conta informada!"
        );
        return handleVoltar();
      }
    } catch (error) {
      setLoading(false);
    }
    Notificacoes.error("Ocorreu algum erro no processo, tente novamente");
  };

  const handleVoltar = () => {
    history.push("/");
  };
  return (
    <div className="limiter">
      <div className="container-login100">
        <div className="wrap-login100">
          <div
            className="login100-form-title"
            style={{ backgroundImage: "url(/resources/images/bg-01.jpg)" }}
          >
            <span className="login100-form-title-1">Justo Pedido Mobile</span>
            <br />
            <span className="login100-form-title-1">Recuperar Senha</span>
          </div>
          <div className={classes.paper}>
            <div className={classes.form}>
              <CustomInput
                labelText="Email"
                id="email"
                inputProps={{
                  name: "email",
                  inputRef: register({
                    required: "O email é requerido.",
                    validate: checkEmailExiste
                  }),
                  type: "mail"
                }}
                formControlProps={{
                  fullWidth: true
                }}
                error={!!errors.email}
                errorMessage={errors.email ? errors.email.message : ""}
              />

              <Button
                disabled={!emailValidado}
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleSubmit(onSubmit)}
              >
                Enviar e-mail de recuperação
              </Button>

              <Button
                fullWidth
                variant="contained"
                color="secondary"
                className={classes.submit}
                onClick={handleVoltar}
                startIcon={<ArrowBackIcon />}
              >
                Voltar
              </Button>
            </div>
          </div>
        </div>
      </div>
      <CustomBackdrop open={loading} />
    </div>
  );
}

EsqueciMinhaSenha.propTypes = {
  history: PropTypes.object
};

export default EsqueciMinhaSenha;
