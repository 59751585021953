import React from "react";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { camelCase, get } from "lodash";
import moment from "moment";

import useConfiguracaoDados, {
  ConfiguracaoDadosContext
} from "customHooks/ConfiguracaoDadosHook";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button.js";
import { useConfirmation } from "components/CustomConfirmationDialog/ConfirmationService";
import Page from "components/CustomPages/Page";
import ConfiguracaoSQL from "./ConfiguracaoSQL";
import ComponentSkeleton from "components/ComponentSkeleton/ComponentSkeleton";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import DadosExecucaoAgendada from "./dadosExecucaoAgendada/DadosExecucaoAgendada";
import SituacaoSincronizacao from "./situacaoSincronizacao/SituacaoSincronizacao";

const useStyles = makeStyles(theme => ({
  progress: {
    margin: theme.spacing(2)
  }
}));

export default function ConfiguracaoDadosEntidade() {
  const classes = useStyles();
  const location = useLocation();
  const { pathname } = location;

  const tipo = React.useMemo(() => camelCase(get(pathname.split("/"), 3)), [
    pathname
  ]);

  const confirm = useConfirmation();

  const formMethods = useForm();
  const { handleSubmit, reset, control } = formMethods;

  const configuracaoDadosValue = useConfiguracaoDados({
    tipo,
    reset
  });

  const {
    isLoading,
    doSaveDadosConfiguracao,
    doLimparConfiguracao,
    configuracaoConst
  } = configuracaoDadosValue;

  const { campos } = configuracaoConst;

  const onSubmit = async data => {
    const { ativa, dados = {}, mapeamento } = data;
    const { personalizados = [] } = mapeamento || {};
    await doSaveDadosConfiguracao({
      ativa,
      dados: JSON.stringify(dados),
      mapeamento: JSON.stringify(
        mapeamento
          ? {
              ...mapeamento,
              personalizados: personalizados.map((personalizado, index) => {
                const {
                  id = `CP_${moment().format("YYYYMMDDHHmmss")}${index}`,
                  tipo = "texto"
                } = personalizado;
                return {
                  ...personalizado,
                  id,
                  tipo
                };
              })
            }
          : {}
      ),
      tipo
    });
  };

  const handleLimparConfiguracao = () => {
    confirm({
      variant: "danger",
      catchOnCancel: false,
      title: "Confirma a limpeza das informações da configuração?"
    }).then(async () => doLimparConfiguracao(tipo));
  };

  return (
    <ConfiguracaoDadosContext.Provider value={configuracaoDadosValue}>
      <Page labelTituloHeader={configuracaoConst.titulo}>
        <FormProvider {...formMethods}>
          <ConfiguracaoSQL />
          {campos && (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <ComponentSkeleton showSkeleton={isLoading}>
                  <Controller
                    name="ativa"
                    valueName="checked"
                    control={control}
                    onChange={([event]) => event.target.checked}
                    render={props => (
                      <CustomSwitch
                        labelText="Sincronização Ativa?"
                        {...props}
                      />
                    )}
                  />
                </ComponentSkeleton>
              </GridItem>
            </GridContainer>
          )}
        </FormProvider>
        <GridContainer justify="flex-start">
          <GridItem>
            <Button color="info" onClick={handleSubmit(onSubmit)}>
              Salvar
            </Button>
          </GridItem>
          <GridItem>
            <Button color="warning" onClick={handleLimparConfiguracao}>
              Limpar Configuração
            </Button>
          </GridItem>
        </GridContainer>
      </Page>
      {/* <InsertTestar tipo={tipo} /> */}
      {/* <DadosExecucaoAgendada tipo={tipo} /> */}

      {campos && <SituacaoSincronizacao tipo={tipo} />}
    </ConfiguracaoDadosContext.Provider>
  );
}

ConfiguracaoDadosEntidade.propTypes = {};
