import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { omit } from "lodash";
import { useForm } from "react-hook-form";
import CircularProgress from "@material-ui/core/CircularProgress";

import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import CadastroPage from "components/CustomPages/CadastroPage";
import { useDatabaseFetch } from "./DatabaseFormHooks";
import { saveUpdateMobileFilialDatabase } from "services/mobileFilialDatabase";
import Notificacoes from "components/Notificacoes/Notificacoes";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

export default function DatabaseForm({ id, onCancel, onAfterConfirmar }) {
  const classes = useStyles();

  const [dadosDatabase, isLoading] = useDatabaseFetch(id);
  
  const { filialId, configuracao = "{}", ativa = true } = dadosDatabase;
  const {
    database = "",
    host = "",
    porta,
    usuario = "",
    senha = "",
  } = JSON.parse(configuracao);

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = async data => {
    const configuracao = JSON.stringify({
      ...omit(data, ["ativa", "filialId"])
    });
    const database = {
      configuracao,
      ativa: data.ativa,
      filialId: data.filialId,
      id
    };
    const retorno = await saveUpdateMobileFilialDatabase(database);
    if (retorno) {
      Notificacoes.success("Database salvo com sucesso");
      if (onAfterConfirmar) onAfterConfirmar();
    } else Notificacoes.error("Ocorreu um erro ao salvar a Database");
  };

  return isLoading === true ? (
    <div>
      <CircularProgress className={classes.progress} />
    </div>
  ) : (
    <CadastroPage
      labelTituloHeader="Dados da Database"
      onCancelar={onCancel}
      onSalvar={handleSubmit(onSubmit)}
    >
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <CustomInput
            labelText="Database"
            id="database"
            inputProps={{
              name: "database",
              inputRef: register({ required: "A database é requerida" }),
              defaultValue: database
            }}
            formControlProps={{
              fullWidth: true
            }}
            error={!!errors.database}
            errorMessage={errors.database ? errors.database.message : ""}
          />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <CustomInput
            labelText="Host"
            id="host"
            inputProps={{
              name: "host",
              inputRef: register({ required: "A host é requerido" }),
              defaultValue: host
            }}
            formControlProps={{
              fullWidth: true
            }}
            error={!!errors.host}
            errorMessage={errors.host ? errors.host.message : ""}
          />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <CustomInput
            labelText="Porta"
            id="porta"
            inputProps={{
              name: "porta",
              inputRef: register({ required: "A porta é requerida" }),
              defaultValue: porta
            }}
            formControlProps={{
              fullWidth: true
            }}
            error={!!errors.porta}
            errorMessage={errors.porta ? errors.porta.message : ""}
          />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <CustomInput
            labelText="Usuário"
            id="usuario"
            inputProps={{
              name: "usuario",
              inputRef: register({ required: "O usuário é requerido" }),
              defaultValue: usuario
            }}
            formControlProps={{
              fullWidth: true
            }}
            error={!!errors.usuario}
            errorMessage={errors.usuario ? errors.usuario.message : ""}
          />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <CustomInput
            labelText="Senha"
            id="senha"
            inputProps={{
              name: "senha",
              inputRef: register({ required: "A senha é requerida" }),
              defaultValue: senha
            }}
            formControlProps={{
              fullWidth: true
            }}
            error={!!errors.senha}
            errorMessage={errors.senha ? errors.senha.message : ""}
          />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <CustomSwitch
            labelText="Ativa"
            id="ativa"
            switchProps={{
              name: "ativa",
              inputRef: register,
              defaultChecked: ativa,
              color: "primary"
            }}
          />
        </GridItem>
      </GridContainer>
    </CadastroPage>
  );
}

DatabaseForm.propTypes = {
  id: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  onCancel: PropTypes.func,
  onAfterConfirmar: PropTypes.func
};
