import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import CancelIcon from "@material-ui/icons/Cancel";

import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";

const useStyles = makeStyles(theme => ({
  divCenter: {
    // alignContent: "center"
  },
  button: {
    margin: theme.spacing(1)
  }
}));

function FormTrocarSenhaAtual({ validarSenhaAtual, onClose }) {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = async ({ senhaAtual }) => {
    validarSenhaAtual(senhaAtual);
    // const retorno = await saveUpdateUsuario(usuario);
    // if (retorno) {
    //   Notificacoes.success("Usuario salvo com sucesso");
    //   if (onAfterConfirmar) onAfterConfirmar();
    // } else Notificacoes.error("Ocorreu um erro ao salvar a Usuario");
  };

  return (
    <div className={classes.divCenter}>
      <GridContainer>
        <GridItem sm>
          <CustomInput
            labelText="Senha Atual"
            id="senhaAtual"
            inputProps={{
              name: "senhaAtual",
              inputRef: register({
                required: "O campo de senha atual é requerido"
              }),
              type: "password"
            }}
            formControlProps={{
              fullWidth: true
            }}
            error={!!errors.senhaAtual}
            errorMessage={errors.senhaAtual ? errors.senhaAtual.message : ""}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem sm>
          <Button
            variant="contained"
            className={classes.button}
            color="danger"
            onClick={onClose}
            endIcon={<CancelIcon />}
          >
            Cancelar
          </Button>
        </GridItem>
        <GridItem sm>
          <Button
            variant="contained"
            className={classes.button}
            color="primary"
            onClick={handleSubmit(onSubmit)}
            endIcon={<VerifiedUserIcon />}
          >
            Validar
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  );
}

FormTrocarSenhaAtual.propTypes = {
  validaSenhaAtual: PropTypes.func,
  onClose: PropTypes.func
};

export default FormTrocarSenhaAtual;
