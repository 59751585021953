import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green, red } from "@material-ui/core/colors";
import { SnackbarContent } from "@material-ui/core";

import Button from "components/CustomButtons/Button";
import { filialGerenciadorTestarSQL } from "services/mobileFilialGerenciador";
import { addLimitSql } from "constants/configuracaoTipos";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { useConfiguracoesBasicasContext } from "customHooks/ConfiguracoesBasicasContext";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center"
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative"
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700]
    }
  },
  buttonError: {
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700]
    }
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  rootSnackbar: {
    maxWidth: 600
  },
  snackbar: {
    margin: theme.spacing(1)
  },
  iconColor: {
    colorPrimary: "#fff"
  }
}));

export default function TestarSQL({
  titulo,
  instrucao,
  tipo,
  defaultValue = false,
  setValue
}) {
  const classes = useStyles();

  const [loading, setLoading] = React.useState(false);
  const [sucesso, setSucesso] = React.useState(defaultValue);
  const [msgRetorno, setMsgRetorno] = React.useState("");

  const { filial } = useConfiguracoesBasicasContext();
  const { identificadorApp } = filial;

  const buttonClassname = clsx({
    [classes.buttonSuccess]: sucesso,
    [classes.buttonError]: !sucesso
  });

  const snackbarClassname = clsx({
    [classes.snackbar]: true,
    [classes.buttonSuccess]: sucesso,
    [classes.buttonError]: !sucesso
  });

  const handleButtonClick = async () => {
    if (!loading) {
      setSucesso(false);
      setLoading(true);
      const retorno = await filialGerenciadorTestarSQL(identificadorApp, {
        instrucao: addLimitSql(instrucao),
        tipo
      });
      if (retorno) {
        const { status, msg, erro } = retorno;
        setSucesso(!!status && !erro);
        setMsgRetorno(msg || erro || "");
        if (setValue) setValue(!!status && !erro);
      }
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div className={classes.root}>
            <div className={classes.wrapper}>
              <Button
                variant="contained"
                className={buttonClassname}
                disabled={loading || !instrucao}
                onClick={handleButtonClick}
              >
                {titulo}
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </div>
        </GridItem>
      </GridContainer>
      {msgRetorno && (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <div className={classes.snackbar}>
              <SnackbarContent
                className={snackbarClassname}
                message={msgRetorno}
              />
            </div>
          </GridItem>
        </GridContainer>
      )}
    </React.Fragment>
  );
}

TestarSQL.propTypes = {
  titulo: PropTypes.string,
  identificadorApp: PropTypes.string,
  instrucao: PropTypes.string,
  defaultValue: PropTypes.bool,
  setValue: PropTypes.func,
  tipo: PropTypes.string
};
