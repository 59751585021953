import {
  acoesUsuario,
  permissoesValidacoesUsuario,
  acoesUsuarioLabel
} from "./permissoes/usuario.permissoes";
import {
  acoesContratante,
  permissoesValidacoesContratante,
  acoesContratanteLabel
} from "./permissoes/contratante.permissoes";
const TODOS = "TODOS";
const LEITURA = "LEITURA";
const LISTAR = "LISTAR";
const INCLUIR = "INCLUIR";
const EDITAR = "EDITAR";
const DELETAR = "DELETAR";
const INATIVAR = "INATIVAR";

export const acoes = {
  TODOS,
  LEITURA,
  LISTAR,
  INCLUIR,
  EDITAR,
  DELETAR,
  INATIVAR,
  ...acoesUsuario,
  ...acoesContratante
};

export const acoesLabel = {
  TODOS: "Todos",
  LEITURA: "Leitura",
  LISTAR: "Listar",
  INCLUIR: "Incluir",
  EDITAR: "Editar",
  DELETAR: "Deletar",
  ABRIR: "Abrir",
  FECHAR: "Fechar",
  INATIVAR: "Inativar",
  ...acoesUsuarioLabel,
  ...acoesContratanteLabel
};

export const permissao = {
  CONTRATANTE: "CONTRATANTE",
  USUARIO: "USUARIO",
  MOBILE_FILIAL: "MOBILE_FILIAL",
  MOBILE_DISPOSITIVO: "MOBILE_DISPOSITIVO"
};

const permissoesAcoesDefault = [
  acoes.TODOS,
  acoes.LEITURA,
  acoes.LISTAR,
  acoes.INCLUIR,
  acoes.EDITAR,
  acoes.DELETAR
];

export const permissaoAcoes = {
  [permissao.USUARIO]: permissoesAcoesDefault.concat([
    acoes.USUARIO_ALTERAR_PERFIL_PROPRIO,
    acoes.USUARIO_ALTERAR_SENHA
  ]),
  [permissao.CONTRATANTE]: permissoesAcoesDefault.concat([
    acoes.CONTRATANTE_ALTERAR_PERFIL_PROPRIO
  ]),
  [permissao.MOBILE_FILIAL]: permissoesAcoesDefault,
  [permissao.MOBILE_DISPOSITIVO]: permissoesAcoesDefault
};

export const permissoesAcoesLabels = permissao => {
  const permissaoAcao = permissaoAcoes[permissao];
  if (!permissaoAcao) return {};
  const retorno = {};
  permissaoAcao.forEach(pa => (retorno[pa] = acoesLabel[pa]));
  return retorno;
};

// usado para facilitar as regras no backend e front para checar se determinada
// permissão se enquadra em um grupo
const getGrupoPermissaoPadrao = perfil => {
  return {
    listagem: {
      actions: [LISTAR],
      subject: [perfil]
    },
    // Para que um usuário seja capaz de detalhar um registro ele
    // deve ter alguma dessas ações
    detalhe: {
      actions: [LEITURA, INCLUIR, EDITAR],
      subject: [perfil]
    },
    inclusao: {
      actions: [INCLUIR],
      subject: [perfil]
    },
    editar: {
      actions: [INCLUIR, EDITAR],
      subject: [perfil]
    },
    excluir: {
      actions: [DELETAR],
      subject: [perfil]
    }
  };
};

export const usuarioGrupoPermissao = {
  ...getGrupoPermissaoPadrao(permissao.USUARIO),
  alterarSenha: {
    actions: [acoesUsuario.USUARIO_ALTERAR_SENHA],
    subject: [permissao.USUARIO]
  },
  alterarPerfilProprio: {
    actions: [acoesUsuario.USUARIO_ALTERAR_PERFIL_PROPRIO],
    subject: [permissao.USUARIO]
  }
};

export const permissoesValidacoes = {
  ...permissoesValidacoesUsuario,
  ...permissoesValidacoesContratante
};

export const contratanteGrupoPermissao = getGrupoPermissaoPadrao(
  permissao.CONTRATANTE
);
export const mobileFilialGrupoPermissao = getGrupoPermissaoPadrao(
  permissao.MOBILE_FILIAL
);
export const mobileDispositivoGrupoPermissao = getGrupoPermissaoPadrao(
  permissao.MOBILE_DISPOSITIVO
);
