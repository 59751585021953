import { uniq, keys, clone, compact } from "lodash";
import { acoes } from "../constants/permissoes";

export const validaPermissaoUsuario = (usuarioPermissoes, permissoes) => {
  if (!usuarioPermissoes) return true;
  const checkPermissao = (acao, subject) => {
    return usuarioPermissoes.can(acao, String(subject).toUpperCase());
  };
  return keys(permissoes)
    .map(k => {
      return uniq([acoes.TODOS].concat(permissoes[k])).some(acao =>
        checkPermissao(acao, k)
      );
    })
    .some(r => r === true);
};

export const validaPermissaoUsuarioTODOS = (
  usuarioPermissoes,
  permissoes,
  subject
) =>
  uniq([acoes.TODOS].concat(permissoes)).some(p =>
    usuarioPermissoes.can(p, subject)
  );

export const checkMenuPermissaoUsuario = (usuarioPermissoes, menu) => {
  if (!usuarioPermissoes) return menu;
  if (menu.isDivider) return menu;
  const menuCopy = clone(menu);
  const { permissoes, subItems } = menuCopy;
  if (permissoes) {
    if (!validaPermissaoUsuario(usuarioPermissoes, permissoes)) return;
  }
  if (subItems) {
    const newsubItems = compact(
      subItems.map(c => checkMenuPermissaoUsuario(usuarioPermissoes, c))
    );
    if (
      !permissoes &&
      (newsubItems.length <= 0 || !newsubItems.some(c => !c.isDivider))
    )
      return;
    menuCopy.subItems = newsubItems;
  }
  return menuCopy;
};

export const checkMenuListPermissaoUsuario = (usuarioPermissoes, menuList) => {
  if (!usuarioPermissoes) return menuList;
  const menus = menuList.map(m =>
    checkMenuPermissaoUsuario(usuarioPermissoes, m)
  );
  return compact(menus);
};
