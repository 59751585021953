import { omit } from "lodash";
import { doResquestGraphql } from "../helpers/graphql";

const mobileFilialSubsection = `
  id
  filialUuid
  identificadorApp
  numeroDispositivos
  nome
  tipoDocFederal
  fantasia
  docFederal
  docEstadual
  telefone
  email
  cep
  cidadeId
  endereco
  numero
  bairro
  complemento
  ativo
`;

export async function getListaMobileFiliais(options = {}) {
  const { limit = 0, offset = 0, where, order, fields } = options;
  const fieldsListas = `
    id
    filialUuid
    identificadorApp
    numeroDispositivos
    nome
    tipoDocFederal
    docFederal
    docEstadual
    email
    ativo
  `;
  const query = `
    query getMobileFiliais($queryOptions: QueryOptions) {
      mobileFiliais(queryOptions: $queryOptions) {
        ${fields ? fields : fieldsListas}
      }
    }
  `;

  const variables = {
    queryOptions: {
      limit,
      offset,
      where,
      order
    }
  };

  if (!limit) delete variables.queryOptions.limit;
  if (!offset) delete variables.queryOptions.offset;

  const resultado = await doResquestGraphql(query, variables);
  if (resultado.status && resultado.data && resultado.data.mobileFiliais)
    return resultado.data.mobileFiliais;
  return;
}

export async function getQtdeMobileFiliais(where) {
  const query = `
    query mobileFiliaisCount($queryOptions: QueryOptions) {
      mobileFiliaisCount(queryOptions: $queryOptions)
    }
  `;

  const variables = {
    queryOptions: {
      where
    }
  };

  const retorno = await doResquestGraphql(query, variables);
  if (retorno && retorno.status) return retorno.data.mobileFiliaisCount;
  return 0;
}

export async function deleteMobileFilial(id) {
  const query = `
    mutation deleteMobileFilial($id: ID!) {
      deleteMobileFilial(id: $id)
    }
  `;

  const variables = {
    id
  };

  const retorno = await doResquestGraphql(query, variables);
  if (retorno && retorno.status) return retorno.data.deleteMobileFilial;
  return false;
}

export async function saveUpdateMobileFilial(mobileFilial) {
  const query = `
    mutation doCreateUpdatMobileFilial($input: MobileFilialCreateUpdateInput!) {
      createUpdatMobileFilial(input: $input) {
        ${mobileFilialSubsection}
      }
    }
  `;

  const retorno = await doResquestGraphql(query, {
    input: {
      ...omit(mobileFilial, [
        "filialUuid",
        "identificadorApp",
        "numeroDispositivos",
        "cidadeId"
      ]),
      numeroDispositivos: parseInt(mobileFilial.numeroDispositivos) || 1,
      cidadeId: mobileFilial.cidadeId
        ? parseInt(mobileFilial.cidadeId)
        : undefined
    }
  });
  return retorno;
}

export async function getMobileFilial(id) {
  const query = `
    query getMobileFilial($id: ID!) {
      mobileFilial(id: $id) {
        ${mobileFilialSubsection}
      }
    }
  `;

  const variables = {
    id
  };

  const retorno = await doResquestGraphql(query, variables);
  if (retorno.status && retorno.data && retorno.data.mobileFilial)
    return retorno.data.mobileFilial;
  return;
}
