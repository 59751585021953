import axios from "axios";
import { getStoredUserAuth } from "./login.helpers";

function getAuthorization() {
  const { token } = getStoredUserAuth();
  return token ? token : null;
}

export const fromPdvAPI = async payload => {
  const { url, metodo = "get", data, headers = {} } = payload;
  const token = getAuthorization();

  if (token) headers["Authorization"] = `Bearer ${token}`;

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const timeout = setTimeout(() => {
    source.cancel("Cancelado pelo timeout de 15 segundos!");
  }, 15000);

  return axios({
    method: metodo,
    url,
    data,
    timeout: 8000,
    headers,
    cancelToken: source.token
  })
    .then(resp => {
      clearTimeout(timeout);
      return Promise.resolve(resp);
    })
    .catch(error => {
      clearTimeout(timeout);
      return Promise.reject(error);
    });
};
