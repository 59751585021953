import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { isFunction } from "lodash";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routesGerenciador.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/iconeJustoPedidoBranco.png";
import ConfiguracoesBasicasProvider, {
  useConfiguracoesBasicasContext
} from "customHooks/ConfiguracoesBasicasContext";

let ps;

// eslint-disable-next-line react/display-name
const switchRoutes = (routesToUse, filialId) => (
  <Switch>
    {routesToUse.map((prop, key) => (
      <Route path={prop.path} component={prop.component} key={key} />
    ))}
    <Redirect to={`/gerenciador/${filialId}/situacao_gerenciador`} />
  </Switch>
);

const useStyles = makeStyles(styles);

export default function AdminGerenciador(props) {
  const { match, history } = props;
  const { params: { id } = {} } = match;
  return (
    <ConfiguracoesBasicasProvider filialId={Number(id || 0)}>
      <AdminGerenciadorRoutes {...props} />
    </ConfiguracoesBasicasProvider>
  );
}

function AdminGerenciadorRoutes(props) {
  const { match, history } = props;
  const { params: { contratanteId, id: filialId } = {} } = match;

  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const {
    isLoadingFilial,
    configuracaoBasica
  } = useConfiguracoesBasicasContext();
  const routesToUse = React.useMemo(() => {
    return routes.filter(route =>
      route.enabled
        ? isFunction(route.enabled)
          ? route.enabled({ configuracaoBasica }) === true
          : route.enabled === true
        : true
    );
  }, [routes, configuracaoBasica]).map(route => ({
    ...route,
    path: `/gerenciador/${filialId}${route.path}`
  }));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  if (isLoadingFilial) return null;
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routesToUse}
        logoText={"Gerenciador Justo Pedido Mobile"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        mostraDownload={false}
        {...props}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routesToUse}
          handleDrawerToggle={handleDrawerToggle}
          {...props}
        />
        <div className={classes.content}>
          <div className={classes.container}>
            {switchRoutes(routesToUse, filialId)}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
