export const acoesUsuario = {
  USUARIO_ALTERAR_SENHA: "USUARIO_ALTERAR_SENHA",
  USUARIO_ALTERAR_PERFIL_PROPRIO: "USUARIO_ALTERAR_PERFIL_PROPRIO"
};

export const acoesUsuarioLabel = {
  USUARIO_ALTERAR_SENHA: "Alterar senha",
  USUARIO_ALTERAR_PERFIL_PROPRIO: "Alterar perfil próprio"
};

export const permissoesValidacoesUsuario = {
  USUARIO_ALTERAR_SENHA: usuario => ({ id: usuario.id }),
  USUARIO_ALTERAR_PERFIL_PROPRIO: usuario => ({ id: usuario.id })
};
