import Build from "@material-ui/icons/Build";
import Home from "@material-ui/icons/Home";
import People from "@material-ui/icons/People";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
// core components/views for Admin layout
import ContratanteLista from "views/Admin/Contratante/lista/ContratanteLista";
import ContratanteTabs from "views/Admin/Contratante/form/ContratanteTabs";
import { acoes } from "constants/permissoes";
import UsuarioLista from "views/Admin/Usuario/lista/UsuarioLista";
import HomePage from "views/Admin/Home/Home";
import MobileFilialLista from "views/Admin/MobileFilial/lista/MobileFilialLista";
import UsuarioPage from "views/Admin/Usuario/form/UsuarioPage";
import MobileFilialTabs from "./views/Admin/MobileFilial/form/MobileFilialTabs";
import UsuarioPerfil from "views/Admin/Usuario/perfil/UsuarioPerfil";
import DatabaseLista from "views/Admin/Database/lista/DatabaseLista";
import DatabasePage from "views/Admin/Database/form/DatabasePage";
import MobileFilialDispositivosLista from "views/Admin/MobileFilialDispositivos/lista/MobileFilialDispositivosLista";
import MobileDispositivoForm from "views/Admin/MobileFilialDispositivos/form/MobileDispositivoForm";

const dashboardRoutes = [
  {
    path: "/home",
    name: "Home",
    icon: Home,
    component: HomePage,
    layout: "/admin",
    showSiderbar: true,
    permissoes: { contratante: [acoes.LISTAR] }
  },
  {
    path: "/contratantes",
    name: "Contratantes",
    icon: MonetizationOn,
    component: ContratanteLista,
    layout: "/admin",
    showSiderbar: true,
    permissoes: { contratante: [acoes.LISTAR] }
  },
  {
    path: "/contratante/:contratanteId?",
    name: "Inclusão / Alteração de Contratante",
    layout: "/admin",
    permissoes: { contratante: [acoes.LEITURA, acoes.INCLUIR, acoes.EDITAR] },
    component: ContratanteTabs,
    exact: true
  },
  {
    path: "/contratante/:contratanteId/filial/:filialId?",
    exact: true,
    name: "Inclusão / Alteração de Filial",
    component: MobileFilialTabs,
    layout: "/admin",
    permissoes: { contratante: [acoes.LEITURA, acoes.INCLUIR, acoes.EDITAR] }
  },
  {
    path: "/contratante/:contratanteId/filial/:filialId/dispositivo/:id?",
    exact: true,
    name: "Cadastro de Dispositivo",
    component: MobileDispositivoForm,
    layout: "/admin",
    showSiderbar: false,
    permissoes: {
      mobile_dispositivo: [acoes.LEITURA, acoes.INCLUIR, acoes.EDITAR]
    }
  },
  {
    path: "/lista_database",
    name: "Databases",
    icon: MonetizationOn,
    component: DatabaseLista,
    layout: "/admin",
    showSiderbar: true
    // permissoes: { database: [acoes.LISTAR] }
  },
  {
    path: "/cadastro_database/:id?",
    name: "Inclusão / Alteração de Database",
    component: DatabasePage,
    layout: "/admin",
    permissoes: { database: [acoes.LEITURA, acoes.INCLUIR, acoes.EDITAR] }
  },
  {
    path: "/lista_usuario",
    name: "Usuarios",
    icon: People,
    component: UsuarioLista,
    layout: "/admin",
    showSiderbar: true,
    permissoes: { usuario: [acoes.LISTAR] }
  },
  {
    path: "/cadastro_usuario/:id?",
    name: "Inclusão / Alteração de Usuario",
    component: UsuarioPage,
    layout: "/admin",
    permissoes: { usuario: [acoes.LEITURA, acoes.INCLUIR, acoes.EDITAR] }
  },
  {
    path: "/perfil",
    name: "Perfil do Usuário",
    component: UsuarioPerfil,
    showSiderbar: false,
    layout: "/admin",
    permissoes: { usuario: [acoes.EDITAR] }
  },
  {
    path: "/filiais",
    name: "Filiais",
    icon: Build,
    component: MobileFilialLista,
    layout: "/admin",
    showSiderbar: dadosUsuario =>
      dadosUsuario.contratanteId && dadosUsuario.contratanteId > 0,
    permissoes: { mobile_filial: [acoes.LISTAR] }
  },
  {
    path: "/filial/:filialId?",
    exact: true,
    name: "Inclusão / Alteração de Filial",
    component: MobileFilialTabs,
    layout: "/admin",
    showSiderbar: false,
    permissoes: { mobile_filial: [acoes.LEITURA, acoes.INCLUIR, acoes.EDITAR] }
  },
  {
    path: "/filial/:filialId/dispositivo/:id?",
    exact: true,
    name: "Cadastro de Dispositivo",
    component: MobileDispositivoForm,
    layout: "/admin",
    showSiderbar: false,
    permissoes: {
      mobile_dispositivo: [acoes.LEITURA, acoes.INCLUIR, acoes.EDITAR]
    }
  }
  // {
  //   path: "/perfil_usuario",
  //   name: "Perfil do Usuário",
  //   icon: Person,
  //   component: UserProfile,
  //   layout: "/admin",
  //   showSiderbar: true,
  //   permissoes: { usuario: [acoes.USUARIO_ALTERAR_PERFIL_PROPRIO] }
  // }
];

export default dashboardRoutes;
