import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";

import image1 from "assets/img/telaLoginJusto.png";
import image2 from "assets/img/telaDadosCliente.png";
import image3 from "assets/img/telaPedido.png";
import image4 from "assets/img/telaPedidoAddItem.png";
import image5 from "assets/img/telaPedidoEditarItem.png";

const valores = [
  {
    label: "Tela de Login",
    imagem: image1
  },
  {
    label: "Tela dados do Cliente",
    imagem: image2
  },
  {
    label: "Tela de Pedido",
    imagem: image3
  },
  {
    label: "Tela seleção de produto",
    imagem: image4
  },
  {
    label: "Tela edição de item",
    imagem: image5
  }
];

const carouselItemsRendereds = valores.map((valor, index) => (
  <div key={index}>
    <img
      src={valor.imagem}
      alt={valor.label}
      className="slick-image"
      style={{ maxWidth: "180px" }}
    />
    <div className="slick-caption">
      <h6>{valor.label}</h6>
    </div>
  </div>
));

export default function CarouselAndroid() {
  const settings = {
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true
  };
  return <Carousel {...settings}>{carouselItemsRendereds}</Carousel>;
}
