import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import { useFieldArray, Controller } from "react-hook-form";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import RenderTagsInsert from "./RenderTagsInsert";
import InsertExemplo from "./InsertExemplo";
import InputAreaSelectInsert from "../InputAreaSelectInsert";
import Button from "components/CustomButtons/Button";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { makeStyles } from "@material-ui/core/styles";
import { useConfirmation } from "components/CustomConfirmationDialog/ConfirmationService";
import CardDnd from "components/Card/CardDnd";
import SnackbarContent from "components/Snackbar/SnackbarContent";
import ComponentSkeleton from "components/ComponentSkeleton/ComponentSkeleton";

const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap"
  }
}));

function InsertPadraoInsertArray({
  configuracaoInsert,
  formMethods,
  isLoading
}) {
  const classes = useStyles();
  const confirm = useConfirmation();

  const { errors, control } = formMethods;

  const {
    field,
    tagsInsert,
    exemploInsert,
    label = "SQL Insert",
    tagsInsertPersonalizadas
  } = configuracaoInsert;

  const fieldName = React.useMemo(() => `dados.${field}`, [field]);

  const { fields, append, remove, swap } = useFieldArray({
    control,
    name: fieldName
  });

  const errosInsert = React.useMemo(() => get(errors, fieldName), [
    formMethods,
    errors
  ]);

  const handleAdd = () => append({ name: fieldName });
  const handleRemove = index => () => {
    confirm({
      variant: "danger",
      catchOnCancel: false,
      title: "Deseja remover o insert?"
    }).then(() => remove(index));
  };

  const moveInsert = React.useCallback(
    (dragIndex, hoverIndex) => {
      swap(dragIndex, hoverIndex);
    },
    [fields]
  );

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs>
          <RenderTagsInsert
            tagsInsert={tagsInsert}
            label={label}
            tagsInsertPersonalizadas={tagsInsertPersonalizadas}
          />
        </GridItem>
      </GridContainer>
      {exemploInsert && (
        <GridContainer>
          <GridItem xs>
            <InsertExemplo exemplo={exemploInsert} />
          </GridItem>
        </GridContainer>
      )}
      <GridContainer>
        <GridItem xs>
          <SnackbarContent
            message={
              <strong>
                É possível alterar a ordem arrastando os cards dos inserts
              </strong>
            }
          />
        </GridItem>
      </GridContainer>
      <DndProvider backend={Backend}>
        {fields.map((item, index) => (
          <CardDnd
            key={item.id}
            moveCard={moveInsert}
            index={index}
            id={item.id}
          >
            <CardHeader className={classes.header}>
              <strong>SQL Insert*</strong>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <ComponentSkeleton showSkeleton={isLoading}>
                    <Controller
                      name={`${fieldName}[${index}]`}
                      control={control}
                      rules={{ required: "O campo de Insert é requerido" }}
                      render={props => (
                        <InputAreaSelectInsert
                          inputProps={{
                            multiline: true
                          }}
                          formControlProps={{
                            fullWidth: true,
                            required: true
                          }}
                          labelText={label}
                          error={!!errosInsert}
                          errorMessage={get(
                            errosInsert,
                            `${index}.message`,
                            ""
                          )}
                          {...props}
                        />
                      )}
                    />
                  </ComponentSkeleton>
                </GridItem>
              </GridContainer>
              {fields.length > 1 && (
                <Button size="sm" color="danger" onClick={handleRemove(index)}>
                  Remover Insert
                </Button>
              )}
            </CardBody>
          </CardDnd>
        ))}
      </DndProvider>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Button variant="contained" color="info" onClick={handleAdd}>
            Adicionar Insert
          </Button>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
}

InsertPadraoInsertArray.propTypes = {
  isLoading: PropTypes.bool,
  formMethods: PropTypes.object,
  configuracaoInsert: PropTypes.object,
  tagsInsertPersonalizadas: PropTypes.string
};

export default InsertPadraoInsertArray;
