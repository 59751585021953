import React from "react";
import {
  filialGerenciadorSincronizar,
  filialGerenciadorSincronizarCancelar,
  filialGerenciadorSincronizarStatus,
  filialGerenciadorLimparDados
} from "services/mobileFilialGerenciador";
import { useConfiguracoesBasicasContext } from "customHooks/ConfiguracoesBasicasContext";

export const useSituacaoSincronizacao = tipo => {
  const [dadosSincronizacao, setDadosSincronizacao] = React.useState({});
  const intervalSituacao = React.useRef;
  const { filial = {} } = useConfiguracoesBasicasContext();

  const pollState = () => {
    intervalSituacao.current = setInterval(() => {
      loadDadosSincronizacao();
    }, 500);
  };

  const doSincronizacao = reenviarTodos => {
    filialGerenciadorSincronizar(filial.identificadorApp, {
      tipo,
      reenviarTodos
    });
    pollState();
  };

  const doLimparDadosServidor = () =>
    filialGerenciadorLimparDados(filial.identificadorApp, tipo);

  const doSincronizacaoCancelar = () => {
    filialGerenciadorSincronizarCancelar(filial.identificadorApp, tipo);
  };

  const loadDadosSincronizacao = async () => {
    const retorno = await filialGerenciadorSincronizarStatus(
      filial.identificadorApp,
      tipo
    );
    if (retorno) {
      await setDadosSincronizacao(retorno);
      const { executando } = retorno;
      if (!executando && intervalSituacao.current)
        clearInterval(intervalSituacao.current);
    }
  };

  React.useEffect(() => {
    loadDadosSincronizacao();
  }, []);

  return {
    dadosSincronizacao,
    loadDadosSincronizacao,
    doSincronizacao,
    doSincronizacaoCancelar,
    doLimparDadosServidor
  };
};
