import React from "react";
import PropTypes from "prop-types";
import { getListaCidades } from "services/cidade";
import { getCidade } from "services/cidade";
import IntegrationAutosuggest from "./IntegrationAutosuggest";

const handleFetchRegistros = async value => {
  const options = {};
  if (value)
    options.where = {
      campo: "nome",
      operador: "like",
      valor: `${value}%`
    };

  const retorno = await getListaCidades(options);
  const resultado = (retorno || []).map(cidade => ({
    label: `${cidade.nome} - ${cidade.estado.sigla}`,
    value: cidade.id
  }));
  return resultado;
};

const handleFetchRegistro = async value => {
  if (!value) return;
  const retorno = await getCidade(value);
  return retorno
    ? {
        label: `${retorno.nome} - ${retorno.estado.sigla}`,
        value: retorno.id
      }
    : null;
};

export default function CustomAutocompleteCidade(props) {
  const {
    labelText = "Cidade",
    id = "cidadeId",
    name = "cidadeId",
    key = "cidade_id",
    error,
    success,
    errorMessage,
    helperText,
    ...otherprops
  } = props;
  console.log("Teste", props)

  return (
    <IntegrationAutosuggest
      id={id}
      key={key}
      name={name}
      labelText={labelText}
      fetchRegistros={handleFetchRegistros}
      fetchRegistro={handleFetchRegistro}
      error={error}
      success={success}
      errorMessage={errorMessage}
      helperText={helperText}
      {...otherprops}
    />
  );
}

CustomAutocompleteCidade.propTypes = {
  labelText: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  key: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  errorMessage: PropTypes.string,
  helperText: PropTypes.string,
  formHelperTextProps: PropTypes.object
};
