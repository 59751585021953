import React, { Fragment } from "react";
import PropTypes from "prop-types";

import Button from "components/CustomButtons/Button.js";
import Page from "./Page";

export default function CadastroPage(props) {
  const {
    children,
    labelTituloHeader,
    labelCategoria,
    onSalvar,
    labelSalvar = "Salvar",
    onCancelar,
    labelCancelar = "Cancelar",
    containerProps,
    headerProps,
    bodyProps,
    footerProps
  } = props;

  return (
    <Page
      labelTituloHeader={labelTituloHeader}
      labelCategoria={labelCategoria}
      containerProps={containerProps}
      headerProps={headerProps}
      bodyProps={bodyProps}
      footerProps={footerProps}
      bodyFooter={
        <Fragment>
          {onSalvar && (
            <Button color="info" onClick={onSalvar}>
              {labelSalvar}
            </Button>
          )}
          {onCancelar && (
            <Button color="danger" onClick={onCancelar}>
              {labelCancelar}
            </Button>
          )}
        </Fragment>
      }
    >
      {children}
    </Page>
  );
}

CadastroPage.propTypes = {
  children: PropTypes.node,
  labelTituloHeader: PropTypes.string,
  labelCategoria: PropTypes.string,
  onSalvar: PropTypes.func,
  labelSalvar: PropTypes.string,
  onCancelar: PropTypes.func,
  labelCancelar: PropTypes.string,
  containerProps: PropTypes.object,
  headerProps: PropTypes.object,
  bodyProps: PropTypes.object,
  footerProps: PropTypes.object
};
