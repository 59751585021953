import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Phone from "@material-ui/icons/Phone";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import EmailIcon from "@material-ui/icons/Email";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import { Container } from "@material-ui/core";

const useStyles = makeStyles(styles);

const Telefones = () => (
  <div style={{ alignItems: "center", alignContent: "center" }}>
    <p>(44) 98822-1417</p>
    <p>(44) 98808-8522</p>
    <p>(44) 99152-7792</p>
    <p>(44) 99862-2301</p>
  </div>
);

export default function ContatoSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Contato</h2>
          <h4 className={classes.description}>
            Atendimento de Segunda a Sexta das 9h às 17h (horário de Brasília).
          </h4>
        </GridItem>
      </GridContainer>
      <div>
        <Container maxWidth="md" component="main">
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
              <InfoArea
                title={<Telefones />}
                description="Telefone."
                icon={Phone}
                iconColor="info"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <InfoArea
                title={<Telefones />}
                description="WhatsApp."
                icon={WhatsAppIcon}
                iconColor="success"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <InfoArea
                title="contato@justopedidomobile.com.br - vendas@justopedidomobile.com.br"
                description="Email."
                icon={EmailIcon}
                iconColor="danger"
                vertical
              />
            </GridItem>
          </GridContainer>
        </Container>
      </div>
    </div>
  );
}
