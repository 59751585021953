import React from "react";
import { get } from "lodash";

import { tiposConfiguracao } from "constants/configuracaoTipos";
import Notificacoes from "components/Notificacoes/Notificacoes";
import { getMobileFilialConfiguracao } from "services/mobileFilialConfiguracao";
import { saveUpdateMobileFilialConfiguracao } from "services/mobileFilialConfiguracao";
import { useConfiguracoesBasicasContext } from "./ConfiguracoesBasicasContext";

const useConfiguracaoDados = ({ tipo, reset }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [configuracaoSalva, setConfiguracaoSalva] = React.useState({});
  const {
    filial = {},
    loadConfiguracaoBasica
  } = useConfiguracoesBasicasContext();
  const { id: filialId } = filial;

  const configuracaoConst = React.useMemo(
    () => get(tiposConfiguracao, tipo, {}),
    [tipo]
  );

  const { valorDefault = {} } = configuracaoConst;

  const loadConfiguracao = async () => {
    try {
      setIsLoading(true);
      const _configuracao = await getMobileFilialConfiguracao(filialId, tipo);
      if (_configuracao) {
        const formValues = {
          ativa: get(_configuracao, "ativa", false),
          dados: {
            ...get(valorDefault, "dados"),
            ...get(_configuracao, "dados")
          },
          mapeamento: {
            ...get(valorDefault, "mapeamento"),
            ...get(_configuracao, "mapeamento")
          }
        };
        setConfiguracaoSalva(formValues);
        if (reset) {
          await reset(formValues);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (filialId && tipo) loadConfiguracao();
  }, [filialId, tipo]);

  const doLimparConfiguracao = async tipo => {
    const configuracao = {
      tipo,
      dados: "",
      mapeamento: "",
      ativa: false,
      filialId
    };
    try {
      const retorno = await saveUpdateMobileFilialConfiguracao(configuracao);
      if (retorno) {
        await loadConfiguracaoBasica();
        return Notificacoes.success("Configuração salva com sucesso.");
      }
    } catch (error) {
      console.log(error);
    }
    Notificacoes.error("Ocorreu um erro ao salvar a configuração.");
  };

  const doSaveDadosConfiguracao = async mobileFilialDatabase => {
    try {
      const retorno = await saveUpdateMobileFilialConfiguracao({
        ...mobileFilialDatabase,
        filialId
      });
      if (retorno) {
        Notificacoes.success("Configuração salva com sucesso.");
        await loadConfiguracaoBasica();
        return true;
      }
    } catch (error) {
      console.log(error);
    }
    Notificacoes.error("Ocorreu um erro ao salvar a configuração.");
    return false;
  };

  return {
    tipo,
    isLoading,
    configuracaoSalva,
    configuracaoConst,
    doSaveDadosConfiguracao,
    doLimparConfiguracao,
  };
};

export default useConfiguracaoDados;

export const ConfiguracaoDadosContext = React.createContext({
  isLoading: true,
  configuracao: {},
  configuracaoConst: {},
  doSaveDadosConfiguracao: () => {},
  doLimparConfiguracao: () => {}
});
