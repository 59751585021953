import React from "react";
import PropTypes from "prop-types";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";

import Button from "components/CustomButtons/Button";

import CustomTable from "components/CustomTable/CustomTable";

export default function UsuariosTabela({
  usuarios,
  onAddUsuario,
  onEditar,
  onRemover
}) {
  const handleEditarUsuario = (event, rowData) => onEditar(Number(rowData.id));

  return (
    <CustomTable
      data={usuarios}
      onEdit={handleEditarUsuario}
      onRemove={onRemover}
      title={
        <Button color="rose" onClick={onAddUsuario}>
          Novo Usuário
        </Button>
      }
      columns={[
        { title: "Nome", field: "nome" },
        { title: "Email", field: "email" },
        {
          title: "Ativo",
          field: "ativo",
          type: "boolean"
        }
      ]}
      options={{
        actionsColumnIndex: -1,
        paging: false
      }}
    />
  );
}

UsuariosTabela.propTypes = {
  usuarios: PropTypes.array,
  onAddUsuario: PropTypes.func,
  onEditar: PropTypes.func,
  onRemover: PropTypes.func
};
