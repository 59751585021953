import { DEFAULT_USER_AUTH } from "constants/Consts";
import { Ability } from "@casl/ability";

/** Return user auth from local storage value */
export const getStoredUserAuth = () => {
  const auth = window.localStorage.getItem("UserAuth");
  if (auth) {
    const userAuth = JSON.parse(auth);
    if (userAuth.dadosUsuario.caslRules) {
      const { dadosUsuario } = userAuth;
      userAuth.dadosUsuario.permissoes = new Ability(
        JSON.parse(dadosUsuario.caslRules)
      );
    }
    return userAuth;
  }
  return DEFAULT_USER_AUTH;
};
