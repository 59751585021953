import React from "react";
import { isNull } from "lodash";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { isNumber } from "lodash";
import Icon from "@material-ui/core/Icon";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import CustomTablePagination from "components/CustomTable/CustomTablePagination/CustomTablePagination";
import {
  getListaMobileDispositivos,
  deleteMobileDispositivo,
  getQtdeMobileDispositivos,
  bloqueiaMobileDispositivo
} from "services/mobileFilialDispositivo";
import Notificacoes from "components/Notificacoes/Notificacoes";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "bold",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

function renderIconBlock() {
  return <Icon className="fa fa-ban" fontSize="small" />;
}

function BloqueiaDispositivoDialog({ onCancel, onConfirmar, showDialog }) {
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={showDialog}
    >
      <DialogTitle id="confirmation-dialog-title">
        Confirma o bloqueio do dispositivo?
        <br />O bloqueio somente poderá ser removido pela equipe de suporte.
      </DialogTitle>
      <DialogActions>
        <Button onClick={onConfirmar} color="info">
          Ok
        </Button>
        <Button onClick={onCancel} color="danger">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default function MobileFilialDispositivosLista(props) {
  const [dataBloquear, setDataBloquear] = React.useState(null);
  const tableRef = React.useRef();
  const { filialId, contratanteId, history } = props;
  // const { params: { filialId, v } = {} } = match;

  const urlDefault = React.useMemo(
    () => (contratanteId ? `/admin/contratante/${contratanteId}/` : "/admin/"),
    [contratanteId]
  );

  const doEditar = id =>
    props.history.push(
      `${urlDefault}filial/${filialId}/dispositivo/${
        id && isNumber(id) ? id : ""
      }`
    );

  const handleEditar = (event, rowData) => {
    doEditar(Number(rowData.id));
  };

  const handleRemover = async rowData => {
    if (rowData) {
      const resultado = await deleteMobileDispositivo(rowData.id);
      if (resultado) {
        Notificacoes.success("Dispositivo removido com sucesso");
      } else Notificacoes.error("Ocorreu um erro ao remover a Dispositivo");
    }
  };

  const handleCancelBloquear = () => setDataBloquear(null);

  const handleBloqueio = async () => {
    if (!isNull(dataBloquear)) {
      const resultado = await bloqueiaMobileDispositivo(dataBloquear.id);
      setDataBloquear(null);
      if (resultado) {
        Notificacoes.success("Dispositivo removido com sucesso");
        tableRef.current.callRefresh();
      } else Notificacoes.error("Ocorreu um erro ao remover a Dispositivo");
    }
  };

  const handleFeatchDispositivos = async options => {
    if (!options) options = {};
    if (!options.where) options.where = {};
    if (!options.where.andWhere) options.where.andWhere = [];
    options.where.andWhere.push(
      {
        campo: "filialId",
        operador: "eq",
        valor: filialId
      },
      {
        campo: "plataforma",
        operador: "eq",
        valor: "Android"
      },
      {
        campo: "whitelisted",
        operador: "eq",
        valor: "false"
      }
    );

    return getListaMobileDispositivos(options);
  };

  const handleBloquearDispositivo = async (event, rowData) =>
    setDataBloquear(rowData);

  const handleGetQtdeMobileDispositivos = async options => {
    if (!options) options = {};
    if (!options.where) options.where = {};
    if (!options.where.andWhere) options.where.andWhere = [];
    options.where.andWhere.push(
      {
        campo: "filialId",
        operador: "eq",
        valor: filialId
      },
      {
        campo: "plataforma",
        operador: "eq",
        valor: "Android"
      },
      {
        campo: "whitelisted",
        operador: "eq",
        valor: "false"
      }
    );

    return getQtdeMobileDispositivos(options);
  };

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h4 className={classes.cardTitleWhite}>
                  Lista de Dispositivos
                </h4>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <CustomTablePagination
              ref={tableRef}
              title={null}
              // title={
              //   <Button color="rose" onClick={doEditar}>
              //     Novo Dispositivo
              //   </Button>
              // }
              options={{
                toolbar: true,
                showTitle: true
              }}
              doFetch={handleFeatchDispositivos}
              doFetchQtde={handleGetQtdeMobileDispositivos}
              onEdit={handleEditar}
              // onRemove={handleRemover}
              actions={[
                rowData => ({
                  icon: renderIconBlock,
                  tooltip: "Bloquear Dispositivo",
                  onClick: handleBloquearDispositivo,
                  disabled: !!rowData.bloqueado
                })
              ]}
              columns={[
                { title: "UUID", field: "uuid" },
                { title: "Dispositivo", field: "dispositivo" },
                {
                  title: "Apelido",
                  field: "apelido"
                },
                { title: "Versão do App", field: "versaoApp" },
                {
                  title: "Data Ultimo Acesso",
                  field: "dataUltimoAcesso",
                  type: "DateTime"
                },
                {
                  title: "Ativo",
                  field: "ativo",
                  type: "boolean"
                },
                {
                  title: "Bloqueado",
                  field: "bloqueado",
                  type: "boolean"
                }
              ]}
            />
            <BloqueiaDispositivoDialog
              showDialog={!isNull(dataBloquear)}
              onCancel={handleCancelBloquear}
              onConfirmar={handleBloqueio}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

MobileFilialDispositivosLista.propTypes = {
  history: PropTypes.object
};
