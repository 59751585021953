import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(theme => ({
  ...styles,
  "@global": {
    ul: {
      margin: 0,
      padding: 0
    },
    li: {
      listStyle: "none"
    }
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[700]
        : theme.palette.grey[200]
  },
  cardPricing: {
    // display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2)
  },
  button: {
    backgroundColor: "rgb(44, 126, 144)"
  }
}));

const tiers = [
  // {
  //   title: "1 Licença",
  //   price: "29,90"
  // },
  // {
  //   title: "Para mais linceças consulte os valores",
  //   price: "19,90"
  // },
  // {
  //   title: "10 Licenças",
  //   price: "18,90"
  // },
  // {
  //   title: "25 Licenças",
  //   price: "10,90"
  // },
  // {
  //   title: "50 Licenças",
  //   price: "7,90"
  // },
  // {
  //   title: "100 Licenças",
  //   price: "6,90"
  // },
];

export default function PlanosSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Planos</h2>
          <h5 className={classes.description}>
            O Gerenciador de integração já faz parte do pacote.
          </h5>
        </GridItem>
      </GridContainer>
      <Container maxWidth="md" component="main">
        <Grid container spacing={2} justify="center" alignItems="flex-end">
          <Grid item md={6}>
            <Card>
              <CardHeader
                title={"Entre em contato para ver os planos"}
                titleTypographyProps={{
                  align: "center",
                  variant: "subtitle2"
                }}
                subheaderTypographyProps={{
                  align: "center",
                  variant: "subtitle2"
                }}
                className={classes.cardHeader}
              />
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography
                    component="h5"
                    variant="subtitle2"
                    color="textPrimary"
                  >
                    Valores diferenciados para software houses.
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
          {tiers.map(tier => (
            <Grid item key={tier.title} xs={8} sm={4} md={2}>
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{
                    align: "center",
                    variant: "subtitle2"
                  }}
                  subheaderTypographyProps={{
                    align: "center",
                    variant: "subtitle2"
                  }}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Typography
                      component="h5"
                      variant="subtitle2"
                      color="textPrimary"
                    >
                      R$ {tier.price}
                    </Typography>
                    <Typography color="textSecondary" variant="subtitle2">
                      por dispositivo
                    </Typography>
                  </div>
                  {/* <ul>
                    {tier.valorEquivalente ? (
                      <Typography
                        component="h5"
                        variant="subtitle2"
                        align="center"
                      >
                        Equivalente a R${tier.valorEquivalente}/mês
                      </Typography>
                    ) : null}
                  </ul> */}
                </CardContent>
                {/* <CardActions>
                  <Button
                    fullWidth
                    variant={tier.buttonVariant}
                    color="primary"
                  >
                    {tier.buttonText}
                  </Button>
                </CardActions> */}
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}
