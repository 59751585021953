import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button";

export default function CustomTableRemoveDialog({
  onCancel,
  onRemove,
  showDialog
}) {
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={showDialog}
    >
      <DialogTitle id="confirmation-dialog-title">
        Confirma a remoção do registro?
      </DialogTitle>
      <DialogActions>
        <Button onClick={onRemove} color="info">
          Ok
        </Button>
        <Button onClick={onCancel} color="danger">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CustomTableRemoveDialog.propTypes = {
  onCancel: PropTypes.func,
  onRemove: PropTypes.func,
  showDialog: PropTypes.bool
};
