export const acoesContratante = {
  CONTRATANTE_ALTERAR_PERFIL_PROPRIO: "CONTRATANTE_ALTERAR_PERFIL_PROPRIO",
};

export const acoesContratanteLabel = {
  CONTRATANTE_ALTERAR_PERFIL_PROPRIO: "Alterar perfil próprio",
};

export const permissoesValidacoesContratante = {
  CONTRATANTE_ALTERAR_PERFIL_PROPRIO: usuario => ({
    contratanteId: {
      $in: usuario.contratantes ? usuario.contratantes.map(c => c.id) : []
    }
  })
};