import React from "react";
import PropTypes from "prop-types";
import { omit, get } from "lodash";
import CustomInput from "components/CustomInput/CustomInput";

function InputAreaSelectInsert({ inputProps, ...otherProps }) {
  const { inputRef } = inputProps;
  const localRef = React.useRef(inputRef);
  const selectRef = inputRef || localRef;

  const onKeyDown = e => {
    if (e.key === "Tab" && !e.shiftKey) {
      e.preventDefault();
      const value = get(selectRef, "current.value");
      if (!value) return;
      const selectionStart = selectRef.current.selectionStart;
      const selectionEnd = selectRef.current.selectionEnd;
      selectRef.current.value =
        value.substring(0, selectionStart) +
        "  " +
        value.substring(selectionEnd);
      selectRef.current.selectionStart =
        selectionEnd + 2 - (selectionEnd - selectionStart);
      selectRef.current.selectionEnd =
        selectionEnd + 2 - (selectionEnd - selectionStart);
    }
    if (e.key === "Tab" && e.shiftKey) {
      e.preventDefault();
      const value = get(selectRef, "current.value");
      if (!value) return;
      const selectionStart = selectRef.current.selectionStart;
      const selectionEnd = selectRef.current.selectionEnd;

      const beforeStart = value
        .substring(0, selectionStart)
        .split("")
        .reverse()
        .join("");
      const indexOfTab = beforeStart.indexOf("  ");
      const indexOfNewline = beforeStart.indexOf("\n");

      if (indexOfTab !== -1 && indexOfTab < indexOfNewline) {
        selectRef.current.value =
          beforeStart
            .substring(indexOfTab + 2)
            .split("")
            .reverse()
            .join("") +
          beforeStart
            .substring(0, indexOfTab)
            .split("")
            .reverse()
            .join("") +
          value.substring(selectionEnd);

        selectRef.current.selectionStart = selectionStart - 2;
        selectRef.current.selectionEnd = selectionEnd - 2;
      }
    }
  };

  return (
    <CustomInput
      id="select"
      inputProps={{
        inputRef: selectRef,
        multiline: true,
        onKeyDown: onKeyDown,
        ...omit(inputProps, ["inputRef", "onKeyDown"])
      }}
      {...otherProps}
    />
  );
}

InputAreaSelectInsert.propTypes = {
  inputProps: PropTypes.object
};

export default InputAreaSelectInsert;
