import React, { useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import MaterialTable from "material-table";
import { isNull } from "lodash";
import CustomTableRemoveDialog from "./CustomTableRemoveDialog";

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const {
    data,
    columns,
    onEdit,
    onRemove,
    options,
    actions = [],
    ...otherProps
  } = props;
  const [dataRemove, setDataRemove] = useState(null);

  const classes = useStyles();

  const optionsToUse = {
    rowStyle: (data, index) => {
      if (index % 2) {
        return { backgroundColor: "#ecf2f9" };
      }
    },
    ...options
  };

  const handleCancelRemove = () => setDataRemove(null);
  const handleRemove = (event, rowData) => {
    setDataRemove(rowData);
  };

  const handleRemoveRegistro = async () => {
    await onRemove(dataRemove);
    setDataRemove(null);
  };

  const actionsDefault = [];
  if (onEdit) {
    actionsDefault.push({
      icon: "edit",
      tooltip: "Editar",
      onClick: onEdit
    });
  }
  if (onRemove) {
    actionsDefault.push({
      icon: "delete",
      tooltip: "Excluir",
      onClick: handleRemove
    });
  }

  return (
    <div className={classes.tableResponsive}>
      <MaterialTable
        className={classes.table}
        columns={columns.map(c => ({ ...c, customSort: null }))}
        data={data}
        options={optionsToUse}
        actions={actions.concat(actionsDefault)}
        localization={{
          body: {
            emptyDataSourceMessage: "Sem registros"
          },
          toolbar: {
            searchTooltip: "Procurar",
            searchPlaceholder: "Procurar",
            exportTitle: "Exportar",
            exportAriaLabel: "Exportar"
          },
          header: {
            actions: "Ações"
          },
          pagination: {
            labelRowsSelect: "Linhas",
            labelRowsPerPage: "Linhas por página",
            labelDisplayedRows: "{from}-{to} de {count}",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Proxima página",
            nextTooltip: "Proxima página",
            lastAriaLabel: "Ultima página",
            lastTooltip: "Ultima página"
          }
        }}
        {...otherProps}
      />
      <CustomTableRemoveDialog
        showDialog={!isNull(dataRemove)}
        onCancel={handleCancelRemove}
        onRemove={handleRemoveRegistro}
      />
    </div>
  );
}

CustomTable.propTypes = {
  data: PropTypes.any,
  actions: PropTypes.array,
  options: PropTypes.object,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.object)
};
