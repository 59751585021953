import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import ComponentSkeleton from "components/ComponentSkeleton/ComponentSkeleton";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import Page from "components/CustomPages/Page";
import CustomSelect from "components/CustomSelect/CustomSelect";

const camposUnicos = [
  {
    value: "docFederal",
    label: "CPF/CNPJ"
  },
  {
    value: "nome",
    label: "Nome/Razão Social"
  },
  {
    value: "telefone",
    label: "Telefone"
  },
  {
    value: "nenhum",
    label: "Nenhum"
  }
];

function ConfiguracaoCliente({ isLoading, control }) {
  return (
    <Page labelTituloHeader="Cliente">
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="dados.permiteInclusaoNovosClientes"
              control={control}
              render={props => (
                <CustomSwitch
                  labelText="Permite incluir novos clientes?"
                  {...props}
                />
              )}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="dados.utilizaFormaPagamentoCliente"
              control={control}
              render={props => (
                <CustomSwitch labelText="Utiliza forma de pagamento para o Cliente?" {...props} />
              )}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="dados.clientePorVendedor"
              valueName="checked"
              control={control}
              onChange={([event]) => event.target.checked}
              as={<CustomSwitch labelText="Utiliza Clientes por Vendedor?" />}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={8} md={6}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="dados.campoClienteValidacaoUnico"
              control={control}
              rules={{
                required: "O tipo de Integração é requerido!"
              }}
              as={
                <CustomSelect
                  labelText="Campo de validação para cadastro de cliente unico?"
                  formControlProps={{
                    fullWidth: true
                  }}
                  options={camposUnicos}
                />
              }
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>
    </Page>
  );
}

ConfiguracaoCliente.propTypes = {
  isLoading: PropTypes.bool,
  control: PropTypes.any
};

export default ConfiguracaoCliente;
