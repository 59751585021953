import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { omit, isArray } from "lodash";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";

// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
// core components
import styles from "assets/jss/material-dashboard-react/components/customInputStyle.js";
import Input from "@material-ui/core/Input";

const useStyles = makeStyles(() => ({
  ...styles,
  autoCompleteRoot: {
    height: "48px"
  },
  inputRoot: {
    marginTop: "16px"
  },
  tag: {
    marginTop: "15px"
  }
}));

export default function CustomInput(props) {
  const classes = useStyles();
  const {
    formControlProps = {},
    labelText,
    labelSempreAcima,
    id,
    labelProps,
    inputProps,
    error,
    errorMessage,
    success,
    ...otherProps
  } = props;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true
  });
  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined
  });

  const { shrink = labelSempreAcima, ...othersLabelProps } = labelProps || {};
  const { onChange } = inputProps;

  const handleOnChange = (e, value) => onChange && onChange(value);
  return (
    <FormControl
      {...formControlProps}
      className={formControlProps.className + " " + classes.formControl}
    >
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + labelClasses}
          classes={{ shrink: classes.labelShrink }}
          htmlFor={id}
          shrink={shrink}
          {...othersLabelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      <Autocomplete
        multiple
        freeSolo
        onChange={handleOnChange}
        renderTags={(value = [], getTagProps) =>
          (isArray(value) ? value : [value]).map((option, index) => (
            <Chip
              key={index}
              label={option}
              size="small"
              {...getTagProps({ index })}
            />
          ))
        }
        classes={{
          ...(inputProps || {}).classes,
          root: marginTop + classes.autoCompleteRoot,
          disabled: classes.disabled,
          tag: classes.tag,
        }}
        id={id}
        {...omit(inputProps, "onChange")}
        {...otherProps}
        renderInput={params => (
          <Input
            inputProps={params.inputProps}
            {...params.InputProps}
            variant="filled"
            fullWidth
            classes={{
              ...(inputProps || {}).classes,
              input: marginTop + classes.inputRoot,
              disabled: classes.disabled,
              underline: underlineClasses
            }}
          />
        )}
      />
      {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
      {error ? <FormHelperText error>{errorMessage}</FormHelperText> : null}
    </FormControl>
  );
}

CustomInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  labelSempreAcima: PropTypes.bool,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  success: PropTypes.bool
};
