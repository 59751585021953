import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import CellWifiIcon from "@material-ui/icons/CellWifi";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import PortableWifiOffIcon from "@material-ui/icons/PortableWifiOff";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import CarouselAndroid from "../CarouselTelas/CarouselAndroid";

const useStyles = makeStyles(styles);

export default function RecursosSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Recursos</h2>
        </GridItem>
      </GridContainer>
      <CarouselAndroid />

      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={3}>
            <InfoArea
              title="Inclusão de Clientes"
              description="Possíbilidade de Inclusão de Novos Clientes."
              icon={PersonAddIcon}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <InfoArea
              title="Geração de Pedidos / Ordem de Serviço"
              description="Emitidos e sincronizados na hora."
              icon={MonetizationOnIcon}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <InfoArea
              title="Transferência de dados."
              description="Envio instantâneo dos novos registro se o dispositivo estiver conectado a internet."
              icon={CellWifiIcon}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <InfoArea
              title="Offline"
              description="Permite a criação dos dados do pedido mesmo se o dispositivo não estiver conectado a internet ."
              icon={PortableWifiOffIcon}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
