import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import CardHeader from "components/Card/CardHeader.js";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "bold",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
};

const useStyles = makeStyles(styles);

export default function HeaderPage(props) {
  const {
    labelTituloHeader,
    labelCategoria,
    labelClassName,
    ...headerProps
  } = props;
  const classes = useStyles();
  const labelTituloHeaderClassName = clsx(
    classes.cardTitleWhite,
    labelClassName
  );
  const _headerProps = {
    color: "info",
    ...headerProps
  };

  return (
    <CardHeader {..._headerProps}>
      <h4 className={labelTituloHeaderClassName}>{labelTituloHeader}</h4>
      {labelCategoria ? (
        <p className={classes.cardCategoryWhite}>{labelCategoria}</p>
      ) : null}
    </CardHeader>
  );
}

HeaderPage.propTypes = {
  labelTituloHeader: PropTypes.string,
  labelCategoria: PropTypes.string
};
