import React from "react";
// @material-ui/core components
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
// core components
import { doCreateTokenAutoLogin } from "services/usuario";
import Notificacoes from "components/Notificacoes/Notificacoes";
import { authContext } from "contexts/AuthContext";
import CustomBackdrop from "components/CustomBackdrop/CustomBackdrop";

export default function UsuarioAutoLogin({ match }) {
  const { params: { token } = {} } = match;
  const authValues = React.useContext(authContext);

  const [redirectTo, setRedirectTo] = React.useState("");
  React.useEffect(() => {
    const doErro = () => {
      Notificacoes.error("Token não é válido, solicite novamente o processo!");
      setRedirectTo("/login");
    };

    const fetchDados = async () => {
      if (!token) return doErro();
      const dadosLogin = await doCreateTokenAutoLogin(token);
      if (!dadosLogin) return doErro();
      const { acao = "", usuario } = dadosLogin;
      Notificacoes.success("Autenticado com sucesso, redirecionando!");
      setRedirectTo(acao === "RECUPERAR_SENHA" ? "/recuperarsenha" : "/admin");
      authValues.setAuthStatus(usuario, dadosLogin.token, acao);
    };
    fetchDados();
  }, []);

  return !redirectTo ? (
    <CustomBackdrop open={!redirectTo} />
  ) : (
    <Redirect to={redirectTo} />
  );
}

UsuarioAutoLogin.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
};
