import { useState, useEffect } from "react";
import {
  getContratante,
  getContratanteUsuarios,
  getContratanteMobileFiliais
} from "services/contratante";
import { saveUpdateContratante } from "services/contratante";
import Notificacoes from "components/Notificacoes/Notificacoes";

export function useContratanteFetch(id, history) {
  const [contratante, setContratante] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [formError, setFormError] = useState("");

  async function fetchContratante() {
    setIsLoading(true);
    if (id && id > 0) {
      const resultado = await getContratante(id);
      if (resultado) setContratante(resultado);
    }
    setIsLoading(false);
  }

  async function salvarContratante(valores, setError) {
    // setIsLoading(true);
    setFormError("");
    const retorno = await saveUpdateContratante({
      ...valores,
      maxFiliais: Number(valores.maxFiliais || 0),
      maxDipositivos: Number(valores.maxDipositivos || 0)
    });
    if (
      retorno.status &&
      retorno.data &&
      retorno.data.createUpdateContratante
    ) {
      setContratante(retorno.data.createUpdateContratante);
      if (!id) {
        history.replace(
          `/admin/contratante/${retorno.data.createUpdateContratante.id}`
        );
      }
      Notificacoes.success("Contratante salvo com sucesso");
    } else {
      if (retorno.fieldErrors) {
        for (const key of Object.keys(retorno.fieldErrors)) {
          setError(key, {
            type: "custom",
            message: retorno.fieldErrors[key]
          });
        }
      }
      if (retorno?.errorMessages.length)
        setFormError(retorno.errorMessages.join("\n"));
      Notificacoes.error("Ocorreu um erro ao salvar o Contratante");
    }
    // setIsLoading(false);
  }

  useEffect(() => {
    fetchContratante();
  }, []);

  return { contratante, isLoading, salvarContratante, formError };
}

export function useContratanteUsuarioFetch(id) {
  const [usuarios, setUsuarios] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  async function fetchContratanteUsuarios() {
    setIsLoading(true);
    if (id && id > 0) {
      const resultado = await getContratanteUsuarios(id);
      if (resultado) setUsuarios(resultado);
    }
    setIsLoading(false);
  }

  const doRefresh = () => fetchContratanteUsuarios();

  useEffect(() => {
    fetchContratanteUsuarios();
  }, []);

  return { usuarios, isLoading, doRefresh };
}

export function useContratanteMobileFilialFetch(id) {
  const [mobileFiliais, setMobileFiliais] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  async function fetchContratanteFiliais() {
    setIsLoading(true);
    if (id && id > 0) {
      const resultado = await getContratanteMobileFiliais(id);
      if (resultado) setMobileFiliais(resultado);
    }
    setIsLoading(false);
  }

  const doRefresh = () => fetchContratanteFiliais();

  useEffect(() => {
    fetchContratanteFiliais();
  }, []);

  return { mobileFiliais, isLoading, doRefresh };
}
