import { doResquestGraphql } from "../helpers/graphql";

const mobileFilialConfiguracaoSubsection = `
  id
  tipo
  dados
  mapeamento
  ativa
`;

export async function saveUpdateMobileFilialConfiguracao(
  mobileFilialConfiguracao
) {
  const query = `
    mutation doCreateUpdateMobileFilialConfiguracao($input: MobileFilialConfiguracaoCreateUpdateInput!) {
      createUpdateMobileFilialConfiguracao(input: $input) {
        ${mobileFilialConfiguracaoSubsection}
      }
    }
  `;

  const variables = {
    input: mobileFilialConfiguracao
  };

  const retorno = await doResquestGraphql(query, variables);
  if (
    retorno.status &&
    retorno.data &&
    retorno.data.createUpdateMobileFilialConfiguracao
  )
    return retorno.data.createUpdateMobileFilialConfiguracao;
  return;
}

export async function getMobileFilialConfiguracao(filialId, tipo) {
  const query = `
    query getMobileFilialConfiguracao($filialId: ID!, $tipo: String!) {
      mobileFilialConfiguracao(filialId: $filialId, tipo: $tipo) {
        ${mobileFilialConfiguracaoSubsection}
      }
    }
  `;

  const variables = {
    filialId,
    tipo
  };

  const retorno = await doResquestGraphql(query, variables);
  if (retorno.status && retorno.data && retorno.data.mobileFilialConfiguracao) {
    const { mobileFilialConfiguracao } = retorno.data;
    return trataMobileFilialConfiguracao(mobileFilialConfiguracao);
  }
  return;
}

const trataMobileFilialConfiguracao = value => ({
  ...value,
  dados: JSON.parse(value.dados),
  mapeamento: JSON.parse(value.mapeamento)
});

export async function getMobileFilialConfiguracaos(filialId) {
  const query = `
    query getMobileFilialConfiguracaos($filialId: ID!) {
      mobileFilialConfiguracaos(filialId: $filialId) {
        ${mobileFilialConfiguracaoSubsection}
      }
    }
  `;

  const variables = {
    filialId
  };

  const retorno = await doResquestGraphql(query, variables);
  if (
    retorno.status &&
    retorno.data &&
    retorno.data.mobileFilialConfiguracaos
  ) {
    const { mobileFilialConfiguracaos } = retorno.data;
    return mobileFilialConfiguracaos.map(v => trataMobileFilialConfiguracao(v));
  }
  return;
}
