import React from "react";
import PropTypes from "prop-types";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";

import Button from "components/CustomButtons/Button";

import CustomTable from "components/CustomTable/CustomTable";
import { Icon } from "@material-ui/core";
import GerarQRCode from "components/GerarQRCode/GerarQRCode";
import { Link } from "react-router-dom";

function renderIconQrCode() {
  return <Icon className="fa fa-qrcode" fontSize="small" />;
}

function renderIconGerenciador() {
  return <Icon className="fa fa-cog " fontSize="small" />;
}
export default function MobileFiliaisTabela({
  mobileFiliais,
  onAddMobileFilial,
  onEditar,
  onRemover
}) {
  const handleEditarFilial = (event, rowData) => onEditar(Number(rowData.id));

  const [
    identificadorAppSelecionado,
    setIdentificadorAppSelecionado
  ] = React.useState("");

  const handleCloseQrCode = () => setIdentificadorAppSelecionado("");

  const handleIdentificadorAppSelecionado = (event, rowData) => {
    setIdentificadorAppSelecionado(rowData.identificadorApp);
  };

  const handleAbrirGerenciador = (event, rowData) => {
    window.open(`/gerenciador/${rowData.id}`, "_blank");
  };

  return (
    <>
      <GerarQRCode
        opened={identificadorAppSelecionado !== ""}
        handleClose={handleCloseQrCode}
        valor={identificadorAppSelecionado}
      />
      <CustomTable
        data={mobileFiliais}
        onEdit={handleEditarFilial}
        onRemove={onRemover}
        title={
          <Button color="rose" onClick={onAddMobileFilial}>
            Nova Filial
          </Button>
        }
        actions={[
          {
            icon: renderIconQrCode,
            // icon: 'edit',
            tooltip: "Gerar QrCode",
            onClick: handleIdentificadorAppSelecionado
          },
          {
            icon: renderIconGerenciador,
            tooltip: "Gerenciador",
            onClick: handleAbrirGerenciador
          }
        ]}
        columns={[
          { title: "Nome", field: "nome" },
          { title: "CPF / CNPJ", field: "docFederal" },
          {
            title: "Identificador do App",
            field: "identificadorApp"
          },
          { title: "Email", field: "email" },
          { title: "Telefone", field: "telefone" },
          {
            title: "Ativo",
            field: "ativo",
            type: "boolean"
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          paging: false
        }}
      />
    </>
  );
}

MobileFiliaisTabela.propTypes = {
  mobileFiliais: PropTypes.array,
  onAddMobileFilial: PropTypes.func,
  onEditar: PropTypes.func,
  onRemover: PropTypes.func
};
