import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CarouselAndroid from "./CarouselTelas/CarouselAndroid";

import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Hidden } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function DadosIniciais() {
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
        <h2 className={classes.title}>
          A solução de emissão de pedidos mobile para seu Cliente.
        </h2>
        <h3>
          Mantenha o foco no seu produto e invista em uma solução de pedidos mobile de fácil integração.
        </h3>
        <br />
      </GridItem>
    </GridContainer>
  );
}
