import Home from "@material-ui/icons/Home";
import SettingsIcon from "@material-ui/icons/Settings";
import PowerIcon from "@material-ui/icons/Power";
import { snakeCase, get } from "lodash";

import HomePage from "views/Gerenciador/views/Home/Home";
import ConfiguracaoBasica from "views/Gerenciador/views/ConfiguracaoBasicas/ConfiguracaoBasicas";
import ConfiguracaoIntegracao from "views/Gerenciador/views/ConfiguracaoIntegracao/ConfiguracaoIntegracao";
import { tiposConfiguracao } from "./constants/configuracaoTipos";
import ConfiguracaoDadosEntidade from "views/Gerenciador/views/ConfiguracaoDadosEntidades/ConfiguracaoDadosEntidade";

const dashboardRoutes = [
  // {
  //   path: "/gerenciador/dashboard",
  //   name: "Dashboard",
  //   icon: Dashboard,
  //   component: DashboardPage,
  //   layout: "/admin",
  //   showSiderbar: true
  // },
  {
    path: "/situacao_gerenciador",
    name: "Situação do Gerenciador",
    icon: Home,
    component: HomePage,
    showSiderbar: true
  },
  {
    path: "/configuracao_basica",
    name: "Configuração Básica",
    showSiderbar: true,
    icon: SettingsIcon,
    component: ConfiguracaoBasica
  },
  {
    path: "/configuracao_conexao_dados",
    name: "Configuraçào da Forma de Recuperar Dados",
    showSiderbar: true,
    icon: PowerIcon,
    component: ConfiguracaoIntegracao
  }
].concat(
  Object.keys(tiposConfiguracao)
    .filter(
      key => !["configuracaoIntegracao", "configuracaoBasica"].includes(key)
    )
    .map(key => ({
      path: `/${snakeCase(key)}`,
      name: get(tiposConfiguracao, `${key}.titulo`),
      showSiderbar: true,
      icon: get(tiposConfiguracao, `${key}.icon`),
      component: get(
        tiposConfiguracao,
        `${key}.component`,
        ConfiguracaoDadosEntidade
      ),
      enabled: get(tiposConfiguracao, `${key}.enabled`)
    }))
);

export default dashboardRoutes;
