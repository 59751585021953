import React from "react";
import PropTypes from "prop-types";
import { get, compact } from "lodash";

import MapeamentoFields from "./mapeamentoFields/MapeamentoFields";
import TestarSQL from "./TestarSQL";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import InputAreaSelectInsert from "./InputAreaSelectInsert";
import MapeamentoFieldsPersonalizados from "./mapeamentoFields/MapeamentoFieldsPersonalizados/MapeamentoFieldsPersonalizados";
import { getFieldsFromSQL } from "constants/configuracaoTipos";
import { Controller, useFormContext } from "react-hook-form";
import ComponentSkeleton from "components/ComponentSkeleton/ComponentSkeleton";
import { ConfiguracaoDadosContext } from "customHooks/ConfiguracaoDadosHook";

function TabSelectSelect({
  configuracaoCampo,
  camposPersonalizados = false,
  camposPersonalizadosEditavel = false
}) {
  const { isLoading } = React.useContext(ConfiguracaoDadosContext);
  const formMethods = useFormContext();
  const { errors, watch, control } = formMethods;

  const { field, campos, label = "SQL Select", exemploSql } = configuracaoCampo;
  const dadosFieldName = `dados.${field}`;
  const watchSelect = watch(dadosFieldName);
  const fieldsSelect = [""].concat(
    compact(getFieldsFromSQL(watchSelect) || [])
  );

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name={dadosFieldName}
              control={control}
              rules={{ required: "O SQL do Select é requerido" }}
              render={props => (
                <InputAreaSelectInsert
                  inputProps={{
                    multiline: true
                  }}
                  formControlProps={{
                    fullWidth: true,
                    required: true
                  }}
                  labelText={label}
                  error={!!get(errors, dadosFieldName)}
                  errorMessage={get(errors, `${dadosFieldName}.message`)}
                  {...props}
                />
              )}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>
      <div style={{ marginBottom: 10 }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            Insira todos os fields da query, não utilize * no sql.
          </GridItem>
        </GridContainer>
        {exemploSql && (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              Exemplo: {exemploSql}
            </GridItem>
          </GridContainer>
        )}
      </div>

      <ComponentSkeleton showSkeleton={isLoading}>
        <Controller
          name={`${dadosFieldName}_statusTestado`}
          control={control}
          valueName="checked"
          render={props => (
            <TestarSQL
              titulo="Testar Select"
              tipo="SELECT"
              instrucao={watchSelect}
              {...props}
            />
          )}
        />
      </ComponentSkeleton>

      <MapeamentoFields
        isLoading={isLoading}
        fieldsListar={fieldsSelect}
        campos={campos}
        field={field}
        formMethods={formMethods}
      />

      {camposPersonalizados === true && (
        <MapeamentoFieldsPersonalizados
          isLoading={isLoading}
          formMethods={formMethods}
          fieldsListar={fieldsSelect}
          camposPersonalizadosEditavel={camposPersonalizadosEditavel}
        />
      )}
    </React.Fragment>
  );
}

TabSelectSelect.propTypes = {
  dadosForm: PropTypes.object,
  dados: PropTypes.object,
  configuracaoCampo: PropTypes.object,
  mapeamento: PropTypes.object,
  identificadorApp: PropTypes.string,
  camposPersonalizados: PropTypes.bool,
  camposPersonalizadosEditavel: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func
  ])
};

export default TabSelectSelect;
