import React from "react";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";
import { omit } from "lodash";

// (44) 99999 9999
const mascaraCelular = [
  "(",
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

const mascaraFixo = [
  "(",
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

const getSomenteNumero = valor =>
  (valor.match(/\d/g) || []).map(Number).join("");

export const telefoneValidador = value => {
  if (!value) return;
  const somenteNumeros = (value.match(/\d/g) || []).map(Number).join("");
  const telefoneLength = somenteNumeros[3] === "9" ? 11 : 10;
  if (somenteNumeros && somenteNumeros.length < telefoneLength) {
    return "Numero de telefone inválido!";
  }
  return;
};

export default function TelefoneInput(props) {
  const [mascaraTelefoneCelular, setMascaraTelefoneCelular] = React.useState(
    false
  );

  const {
    className,
    inputRef,
    placeholderChar = "_",
    placeholder,
    showMask = false,
    useAntdInput = false,
    onChange,
    ...otherProps
  } = props;

  const trataTipoMascara = value => {
    const telefoneCelular = value[5] === "9" ? true : false;
    if (
      (telefoneCelular === true && mascaraTelefoneCelular === false) ||
      (telefoneCelular === false && mascaraTelefoneCelular === true)
    )
      setMascaraTelefoneCelular(telefoneCelular);
  };

  const handleOnChange = event => {
    const { target: { value = "" } = {} } = event;
    const valorTratado = String(value)
      .replace(new RegExp(placeholderChar, "g"), " ")
      .trim();
    trataTipoMascara(value);

    if (onChange) onChange(valorTratado);
  };

  React.useEffect(() => {
    const { value } = otherProps;
    if (value) trataTipoMascara(value);
  }, [otherProps?.value]);

  return (
    <MaskedInput
      mask={mascaraTelefoneCelular === true ? mascaraCelular : mascaraFixo}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      placeholderChar={placeholderChar}
      showMask={showMask}
      onChange={handleOnChange}
      {...omit(otherProps, ["onChange"])}
      render={(ref, props) => (
        <input
          name="telefone"
          placeholder={placeholder}
          ref={ref}
          className={`form-control w-100 ${
            useAntdInput ? "ant-input" : ""
          } ${className}`}
          {...props}
        />
      )}
    />
  );
}

TelefoneInput.propTypes = {
  placeholder: PropTypes.string,
  showMask: PropTypes.bool,
  readOnly: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  inputRef: PropTypes.func,
  placeholderChar: PropTypes.string,
  className: PropTypes.string,
  useAntdInput: PropTypes.bool
};
