import React, { useContext } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
// @material-ui/icons
import AccountCircle from "@material-ui/icons/AccountCircle";
// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import { authContext } from "contexts/AuthContext";

const useStyles = makeStyles(styles);

function AdminNavbarLinks() {
  const classes = useStyles();
  const history = useHistory();
  const [openProfile, setOpenProfile] = React.useState(null);
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const auth = useContext(authContext);
  const { state, setUnauthStatus } = auth;
  const { dadosUsuario } = state;

  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };

  const handleLogout = () => {
    handleCloseProfile();
    setUnauthStatus();
  };

  const handleOpenPerfilUsuario = () => {
    handleCloseProfile();
    history.push("/admin/perfil");
  };

  return (
    <div>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          // justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <AccountCircle className={classes.userIcon} />
          <p className={classes.userEmail}>{dadosUsuario.email}</p>
          {/* <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Perfil</p>
          </Hidden> */}
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleOpenPerfilUsuario}
                      className={classes.dropdownItem}
                    >
                      Perfil
                    </MenuItem>
                    <Divider light />
                    <MenuItem
                      onClick={handleLogout}
                      className={classes.dropdownItem}
                    >
                      Sair
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  );
}

AdminNavbarLinks.propTypes = {
  // history: PropTypes.object
};

export default AdminNavbarLinks;
