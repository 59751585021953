import LoginForm from "views/Login/Login";
import UsuarioAutoLogin from "views/Usuario/autologin/UsuarioAutoLogin";
import EsqueciMinhaSenha from "views/Usuario/esqueciMinhaSenha/EsqueciMinhaSenha";
import LandingPage from "views/LandingPage/LandingPage";
import PoliticaPrivacidade from "views/PoliticaPrivacidade/PoliticaPrivacidade";

const publicRoutes = [
  {
    path: "/",
    component: LandingPage
  },
  {
    path: "/login",
    component: LoginForm
  },
  {
    path: "/autologin/:token",
    component: UsuarioAutoLogin
  },
  {
    path: "/esqueciminhasenha",
    component: EsqueciMinhaSenha
  },
  {
    path: "/politicaprivacidade",
    component: PoliticaPrivacidade
  }
];

export default publicRoutes;
