import React from "react";
import PropTypes from "prop-types";
import CustomInput from "components/CustomInput/CustomInput";
import ComponentSkeleton from "components/ComponentSkeleton/ComponentSkeleton";
import { Controller } from "react-hook-form";

export default function CaminhoArquivoSqlite({
  isLoading,
  control,
  errors,
  tipoIntegracao
}) {
  return ["sqlite"].includes(tipoIntegracao) ? (
    <ComponentSkeleton showSkeleton={isLoading}>
      <Controller
        name="dados.sqliteArquivo"
        control={control}
        rules={{
          required: "O caminho do arquivo do banco sqlite é requerido."
        }}
        render={props => (
          <CustomInput
            formControlProps={{
              fullWidth: true
            }}
            labelText="Caminho do Arquivo do DB"
            error={!!errors.porta}
            errorMessage={
              errors.sqliteArquivo ? errors.sqliteArquivo.message : ""
            }
            {...props}
          />
        )}
      />
    </ComponentSkeleton>
  ) : null;
}

CaminhoArquivoSqlite.propTypes = {
  isLoading: PropTypes.bool,
  control: PropTypes.any,
  errors: PropTypes.object,
  tipoIntegracao: PropTypes.string
};
