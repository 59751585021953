import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import CustomInput from "components/CustomInput/CustomInput";

export default function CustomTimeEdit({
  value = "0:00",
  onChange,
  ...otherInputs
}) {
  // return <CustomInput inputComponent={TimeInput} {...props} />;
  // }

  // const TimeInput = ({ value = "0:00", onChange }) => {

  const handleChange = event => {
    if (event) {
      if (typeof event === "string") onChange(event);
      else {
        const { target } = event;
        if (target) onChange(target.value);
      }
    }
  };

  const onBlur = event => {
    const value = event.target.value;
    const seconds = Math.max(0, getSecondsFromHHMMSS(value));

    const time = toHHMMSS(seconds);
    handleChange(time);
  };

  const getSecondsFromHHMMSS = value => {
    const [str1, str2, str3] = value.split(":");

    const val1 = Number(str1);
    const val2 = Number(str2);
    const val3 = Number(str3);

    if (!isNaN(val1) && isNaN(val2) && isNaN(val3)) {
      return val1;
    }

    if (!isNaN(val1) && !isNaN(val2) && isNaN(val3)) {
      return val1 * 60 + val2;
    }

    if (!isNaN(val1) && !isNaN(val2) && !isNaN(val3)) {
      return val1 * 60 * 60 + val2 * 60 + val3;
    }

    return 0;
  };

  const toHHMMSS = secs => {
    const secNum = parseInt(secs.toString(), 10);
    const hours = Math.floor(secNum / 3600);
    const minutes = Math.floor(secNum / 60) % 60;
    const seconds = secNum % 60;

    return [hours, minutes, seconds]
      .map(val => (val < 10 ? `0${val}` : val))
      .filter((val, index) => val !== "00" || index > 0)
      .join(":")
      .replace(/^0/, "");
  };

  return (
    <CustomInput
      type="text"
      onChange={handleChange}
      onBlur={onBlur}
      value={value}
      {...otherInputs}
    />
  );
}

CustomInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  labelSempreAcima: PropTypes.bool,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  success: PropTypes.bool
};
