import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
// core components

const useStyles = makeStyles({});

export default function CustomCheckbox(props) {
  const classes = useStyles();
  const {
    formControlProps,
    labelText,
    id,
    labelProps,
    checkboxProps,
    error,
    errorMessage,
    success,
    ...otherProps
  } = props;

  return (
    <FormGroup row {...formControlProps}>
      <FormControlLabel
        control={<Checkbox id={id} {...checkboxProps} {...otherProps} />}
        label={labelText}
        {...labelProps}
      />
      {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
      {error ? <FormHelperText error>{errorMessage}</FormHelperText> : null}
    </FormGroup>
  );
}

CustomCheckbox.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  checkboxProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  success: PropTypes.bool
};
