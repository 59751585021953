import { useState, useEffect } from "react";
import { getUsuario } from "services/usuario";

function useUsuarioFetch(id) {
  const [usuario, setUsuario] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  async function fetchUsuario() {
    setIsLoading(true);
    if (id && id > 0) {
      const resultado = await getUsuario(id);
      if (resultado) setUsuario(resultado);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchUsuario();
  }, []);

  return [usuario, isLoading];
}
export { useUsuarioFetch };
