import React from "react";
import deburr from "lodash/deburr";
import Autosuggest from "react-autosuggest";
import PropTypes from "prop-types";
import classNames from "classnames";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { debounce, omit } from "lodash";

import styles from "assets/jss/material-dashboard-react/components/customInputStyle.js";

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input
        }
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map(part => (
          <span
            key={part.text}
            style={{ fontWeight: part.highlight ? 500 : 400 }}
          >
            {part.text}
          </span>
        ))}
      </div>
    </MenuItem>
  );
}

function getSuggestions(suggestions, value) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : suggestions.filter(suggestion => {
        const keep =
          count < 5 &&
          suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
}

const useStyles = makeStyles(theme => ({
  ...styles,
  root: {
    height: 250,
    flexGrow: 1
  },
  container: {
    position: "relative"
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  suggestion: {
    display: "block"
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none"
  },
  divider: {
    height: theme.spacing(2)
  }
}));

export default function IntegrationAutosuggest(props) {
  const classes = useStyles();

  const {
    id,
    name,
    labelText,
    placeholder,
    fetchRegistros,
    fetchRegistro,
    value = null,
    onChange,
    error,
    errorMessage,
    success,
    inputProps,
    formControlProps,
    setValueReactForm,
    triggerValidationReactForm,
    ...otherProps
  } = props;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true
  });
  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined
  });

  const [selectedValueLabel, setSelectedValueLabel] = React.useState("");
  const [selectedValue, setSelectedValue] = React.useState(null);
  const [stateSuggestions, setSuggestions] = React.useState([]);

  if (value !== selectedValue) {
    if (value && !stateSuggestions.some(s => s.value === value)) {
      fetchRegistro(value).then(valor => {
        if (valor) {
          setSuggestions([valor]);
          setSelectedValueLabel(valor.label);
        }
      });
    }
    setSelectedValue(value);
  }

  const onChangeToUse = async valor => {
    if (setValueReactForm) setValueReactForm(name || id, valor);
    if (triggerValidationReactForm)
      await triggerValidationReactForm({ name: name || id });
    if (onChange) onChange(valor);
  };

  const handleSuggestionsFetchRequested = async valores => {
    if (valores.reason === "input-changed")
      return await debounceOnChange(valores.value);
    else if (valores.reason === "escape-pressed") await onChangeToUse(null);
  };

  const debounceOnChange = debounce(async valor => {
    if (selectedValue) await onChangeToUse(null);
    if (valor) {
      if (fetchRegistros) return setSuggestions(await fetchRegistros(valor));
      return setSuggestions(getSuggestions(stateSuggestions, valor));
    }
  }, 200);

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleChange = (event, { newValue, method }) => {
    setSelectedValueLabel(newValue);
    if (newValue === "" || method === "scape") onChangeToUse(null);
  };

  const getSuggestionValue = suggestion => {
    setSelectedValue(suggestion.value);
    onChangeToUse(suggestion.value).then();
    return suggestion.label;
  };

  const autosuggestProps = {
    renderInputComponent,
    suggestions: stateSuggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion
  };

  return (
    <FormControl
      {...formControlProps}
      className={formControlProps.className + " " + classes.formControl}
    >
      <Autosuggest
        // id={id}
        {...autosuggestProps}
        inputProps={{
          // classes,
          id,
          name,
          label: labelText,
          placeholder,
          value: selectedValueLabel,
          onChange: handleChange,
          classes: {
            ...(inputProps || {}).classes,
            root: marginTop,
            disabled: classes.disabled,
            underline: underlineClasses
          },
          ...otherProps
        }}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion
        }}
        renderSuggestionsContainer={options => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
      />
      {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
      {error ? <FormHelperText error>{errorMessage}</FormHelperText> : null}
    </FormControl>
  );
}

IntegrationAutosuggest.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  labelText: PropTypes.string,
  placeholder: PropTypes.string,
  inputProps: PropTypes.object,
  fetchRegistros: PropTypes.func,
  fetchRegistro: PropTypes.func,
  value: PropTypes.any,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  success: PropTypes.bool,
  formControlProps: PropTypes.object,
  setValueReactForm: PropTypes.func,
  triggerValidationReactForm: PropTypes.func
};
