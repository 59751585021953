import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { isNumber } from "lodash";
import Icon from "@material-ui/core/Icon";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import CustomTablePagination from "components/CustomTable/CustomTablePagination/CustomTablePagination";
import {
  getListaMobileFiliais,
  deleteMobileFilial,
  getQtdeMobileFiliais
} from "services/mobileFilial";
import Notificacoes from "components/Notificacoes/Notificacoes";
import GerarQRCode from "components/GerarQRCode/GerarQRCode";


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "bold",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

function renderIconQrCode() {
  return <Icon className="fa fa-qrcode" fontSize="small" />;
}

function renderIconGerenciador() {
  return <Icon className="fa fa-cog " fontSize="small" />;
}

export default function MobileFilialLista(props) {
  const [
    identificadorAppSelecionado,
    setIdentificadorAppSelecionado
  ] = React.useState("");

  const handleCloseQrCode = () => setIdentificadorAppSelecionado("");

  const doEditar = id =>
    props.history.push(
      `/admin/filial/${id && isNumber(id) ? id : ""}`
    );

  const handleEditar = (event, rowData) => {
    doEditar(Number(rowData.id));
  };

  const handleIdentificadorAppSelecionado = (event, rowData) => {
    setIdentificadorAppSelecionado(rowData.identificadorApp);
  };

  const handleRemover = async rowData => {
    if (rowData) {
      const resultado = await deleteMobileFilial(rowData.id);
      if (resultado) {
        Notificacoes.success("Mobile Filial removida com sucesso");
      } else Notificacoes.error("Ocorreu um erro ao remover a Mobile Filial");
    }
  };

  const handleAbrirGerenciador = (event, rowData) => {
    window.open(`/gerenciador/${rowData.id}`, "_blank");
  };


  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h4 className={classes.cardTitleWhite}>Lista de Filiais</h4>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <GerarQRCode
              opened={identificadorAppSelecionado !== ""}
              handleClose={handleCloseQrCode}
              valor={identificadorAppSelecionado}
            />
            <CustomTablePagination
              title={
                <Button color="rose" onClick={doEditar}>
                  Nova Mobile Filial
                </Button>
              }
              options={{
                toolbar: true,
                showTitle: true
              }}
              doFetch={getListaMobileFiliais}
              doFetchQtde={getQtdeMobileFiliais}
              onEdit={handleEditar}
              actions={[
                {
                  icon: renderIconQrCode,
                  // icon: 'edit',
                  tooltip: "Gerar QrCode",
                  onClick: handleIdentificadorAppSelecionado
                },
                {
                  icon: renderIconGerenciador,
                  tooltip: "Gerenciador",
                  onClick: handleAbrirGerenciador
                }
              ]}
              onRemove={handleRemover}
              columns={[
                { title: "Nome", field: "nome" },
                { title: "CPF / CNPJ", field: "docFederal" },
                {
                  title: "Identificador do App",
                  field: "identificadorApp"
                },
                { title: "Email", field: "email" },
                { title: "Telefone", field: "telefone" },
                {
                  title: "Ativo",
                  field: "ativo",
                  type: "boolean"
                },
              ]}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

MobileFilialLista.propTypes = {
  history: PropTypes.object
};
