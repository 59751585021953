import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import ComponentSkeleton from "components/ComponentSkeleton/ComponentSkeleton";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import Page from "components/CustomPages/Page";
import CustomInput from "components/CustomInput/CustomInput";

function ConfiguracaoPedido({ isLoading, control }) {
  return (
    <Page labelTituloHeader="Pedido">
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="dados.utilizaPedido"
              valueName="checked"
              control={control}
              render={props => (
                <CustomSwitch
                  labelText="Utiliza Emissão de Pedidos?"
                  {...props}
                />
              )}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="dados.permiteAlterarValorVendaItemPedido"
              valueName="checked"
              control={control}
              render={props => (
                <CustomSwitch
                  labelText="Permite alterar o valor de venda do item do pedido?"
                  {...props}
                />
              )}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="dados.permiteDescontoNoItemPedido"
              valueName="checked"
              control={control}
              render={props => (
                <CustomSwitch
                  labelText="Permite desconto no item do Pedido?"
                  {...props}
                />
              )}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="dados.utilizaTipoOperacao"
              valueName="checked"
              control={control}
              render={props => (
                <CustomSwitch
                  labelText="Utiliza tipo de operação do Pedido?"
                  {...props}
                />
              )}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="dados.permiteDescontoNoPedido"
              valueName="checked"
              control={control}
              render={props => (
                <CustomSwitch
                  labelText="Permite desconto no Pedido?"
                  {...props}
                />
              )}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="dados.utilizaCondicaoPagamento"
              valueName="checked"
              control={control}
              render={props => (
                <CustomSwitch labelText="Utiliza condição de pagamento no pedido?" {...props}/>
              )}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="dados.labelPrazoEntregaPedido"
              control={control}
              render={props => (
                <CustomInput
                  formControlProps={{
                    fullWidth: true
                  }}
                  labelText="Label campo texto Prazo de Entrega tela pedido. Em branco oculta o campo"
                  {...props}
                />
              )}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="dados.utilizaSugestaoUltimoPedido"
              valueName="checked"
              control={control}
              render={props => (
                <CustomSwitch
                  labelText="Utiliza sugestão do ultimo pedido do cliente?"
                  {...props}
                />
              )}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="dados.utilizaSituacaoPedido"
              valueName="checked"
              control={control}
              onChange={([event]) => event.target.checked}
              as={<CustomSwitch labelText="Utiliza situação do Pedido?" />}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>
    </Page>
  );
}

ConfiguracaoPedido.propTypes = {
  isLoading: PropTypes.bool,
  control: PropTypes.any
};

export default ConfiguracaoPedido;
