import { doResquestGraphql } from "../helpers/graphql";

const cidadeSubsection = `
  id
  nome
  estadoId
  estado {
    nome
    sigla
  }
`;

export async function getListaCidades(options = {}) {
  const { limit = 10, offset = 0, where, order, fieldsToList } = options;
  const fieldsToListDefault = `
    id
    nome
    estado {
      sigla
    }
  `;
  const query = `
    query getCidades($queryOptions: QueryOptions) {
      cidades(queryOptions: $queryOptions) {
        ${fieldsToList ? fieldsToList : fieldsToListDefault}
      }
    }
  `;

  const variables = {
    queryOptions: {
      limit,
      offset,
      where,
      order
    }
  };

  if (variables.queryOptions.limit === 0) {
    delete variables.queryOptions.limit;
  }

  const retorno = await doResquestGraphql(query, variables);
  if (retorno && retorno.status && retorno.data && retorno.data.cidades)
    return retorno.data.cidades;
  return;
}

export async function getQtdeCidades(where) {
  const query = `
    query cidadesCount($queryOptions: QueryOptions) {
      cidadesCount(queryOptions: $queryOptions)
    }
  `;

  const variables = {
    queryOptions: {
      where
    }
  };

  const retorno = await doResquestGraphql(query, variables);
  if (retorno && retorno.status) return retorno.data.cidadesCount;
  return 0;
}

export async function deleteCidade(id) {
  const query = `
    mutation deleteCidade($id: ID!) {
      deleteCidade(id: $id)
    }
  `;

  const variables = {
    id
  };

  const retorno = await doResquestGraphql(query, variables);
  if (retorno && retorno.status) return retorno.data.deleteCidade;
  return false;
}

export async function getCidade(id, fieldsToList) {
  const query = `
    query getCidade($id: ID!) {
      cidade(id: $id) {
        ${fieldsToList ? fieldsToList : cidadeSubsection}
      }
    }
  `;

  const variables = {
    id
  };

  const retorno = await doResquestGraphql(query, variables);
  if (retorno && retorno.status && retorno.data && retorno.data.cidade)
    return retorno.data.cidade;
}

export async function saveCidade(values) {
  const query = `
        mutation doCreateCidade($input: CidadeCreateUpdateInput!) {
          createCidade(input: $input) {
            ${cidadeSubsection}
          }
        }
      `;

  return await doResquestGraphql(query, values);
}

export async function updateCidade(values) {
  const query = `
        mutation doUpdateCidade($id: ID!, $input: CidadeCreateUpdateInput!) {
          updateCidade(id: $id, input: $input) {
            ${cidadeSubsection}
          }
        }
      `;

  return await doResquestGraphql(query, values);
}
