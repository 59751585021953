import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import { green, amber } from "@material-ui/core/colors";
import { SnackbarContent } from "@material-ui/core";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
}));

function CustomSnackbarContent({
  className,
  iconSize = 25,
  message,
  variant,
  ...other
}) {
  const classes = useStyles();
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon
            style={{ fontSize: iconSize }}
            className={clsx(classes.iconVariant)}
          />
          {message}
        </span>
      }
      // action={[
      //   <IconButton
      //     key="close"
      //     aria-label="close"
      //     color="inherit"
      //     onClick={onClose}
      //   >
      //     <WarningIcon className={classes.icon} />
      //   </IconButton>
      // ]}
      {...other}
    />
  );
}

CustomSnackbarContent.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  variant: PropTypes.string,
  iconSize: PropTypes.number
};

export default CustomSnackbarContent;
