import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import HeaderLinks from "components/Header/HeaderLinks";
import HeaderLeftLinks from "components/Header/HeaderLeftLinks";
import Parallax from "components/Parallax/Parallax";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import RecursosSection from "./Sections/RecursosSection";
import GerenciadorIntegracaoSection from "./Sections/GerenciadorIntegracaoSection";
import PlanosSection from "./Sections/PlanosSection";
import ContatoSection from "./Sections/ContatoSection";
import DadosIniciais from "./DadosIniciais";
import VideoSection from "./Sections/VideoSection";

const dashboardRoutes = [];

const useStyles = makeStyles({
  ...styles,
  classesAppBar: {
    backgroundColor: "rgba(44, 126, 144)",
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(44, 126, 144, 0.46)"
  },
  logo: {
    width: 220,
    height: 50
  }
});

const logoImg = require("assets/img/logoBannerTransparent.gif");

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div id="inicio">
      <Header
        classesAppBar={classes.classesAppBar}
        routes={dashboardRoutes}
        // brand="Justo Pedido Mobile"
        brand={<img className={classNames(classes.logo)} src={logoImg} />}
        brandButtonProps={{
          href: "#inicio"
        }}
        leftLinks={<HeaderLeftLinks />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/landing-bg3.png")}>
        <div className={classes.container}>
          <DadosIniciais />
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} id="video">
          <VideoSection />
        </div>
        <div className={classes.container} id="recursos">
          <RecursosSection />
        </div>
        <div className={classes.container} id="gerenciadorIntegracao">
          <GerenciadorIntegracaoSection />
        </div>
        <div className={classes.container} id="planos">
          <PlanosSection />
        </div>
        <div className={classes.container} id="contato">
          <ContatoSection />
        </div>
      </div>
      <Footer />
    </div>
  );
}
