import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import ComponentSkeleton from "components/ComponentSkeleton/ComponentSkeleton";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import Page from "components/CustomPages/Page";

function ConfiguracaoOrdemServico({ isLoading, control }) {
  return (
    <Page labelTituloHeader="Ordem de Serviço">
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="dados.utilizaOrdemServico"
              valueName="checked"
              control={control}
              render={props => (
                <CustomSwitch
                  labelText="Utiliza Ordem de Serviço?"
                  {...props}
                />
              )}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="dados.permiteIncluirServicoSemCadastro"
              valueName="checked"
              control={control}
              render={props => (
                <CustomSwitch
                  labelText="Permite inserir serviço não cadastrado?"
                  {...props}
                />
              )}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="dados.permiteAlterarValorVendaProdutoOrdemServico"
              valueName="checked"
              control={control}
              render={props => (
                <CustomSwitch
                  labelText="Permite alterar o valor de venda do produto?"
                  {...props}
                />
              )}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="dados.permiteDescontoNoProdutoOrdemServico"
              valueName="checked"
              control={control}
              render={props => (
                <CustomSwitch
                  labelText="Permite desconto no produto?"
                  {...props}
                />
              )}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="dados.permiteAlterarValorVendaServicoOrdemServico"
              valueName="checked"
              control={control}
              render={props => (
                <CustomSwitch
                  labelText="Permite alterar o valor do serviço?"
                  {...props}
                />
              )}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="dados.permiteDescontoNoServicoOrdemServico"
              valueName="checked"
              control={control}
              render={props => (
                <CustomSwitch
                  labelText="Permite desconto no serviço?"
                  {...props}
                />
              )}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ComponentSkeleton showSkeleton={isLoading}>
            <Controller
              name="dados.permiteDescontoNaOrdemServico"
              valueName="checked"
              control={control}
              render={props => (
                <CustomSwitch
                  labelText="Permite desconto na Ordem de Serviço?"
                  {...props}
                />
              )}
            />
          </ComponentSkeleton>
        </GridItem>
      </GridContainer>
    </Page>
  );
}

ConfiguracaoOrdemServico.propTypes = {
  isLoading: PropTypes.bool,
  control: PropTypes.any
};

export default ConfiguracaoOrdemServico;
