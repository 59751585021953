import React from "react";
import PropTypes from "prop-types";
import { tiposConfiguracao } from "constants/configuracaoTipos";
import { getMobileFilial } from "../services/mobileFilial";
import { getMobileFilialConfiguracao } from "../services/mobileFilialConfiguracao";

const { configuracaoBasica } = tiposConfiguracao;
const { valorDefault } = configuracaoBasica;

export const ConfiguracoesBasicasContext = React.createContext({
  configuracoesBasicas: valorDefault,
  loadConfiguracaoBasica: () => {},
  setConfiguracaoBasica: () => {},
  filial: {},
  isLoadingFilial: false
});

export const useConfiguracoesBasicasContext = () => {
  const contexto = React.useContext(ConfiguracoesBasicasContext);
  return contexto;
};

const ConfiguracoesBasicasProvider = ({ filialId, children }) => {
  const [configuracaoBasica, setConfiguracaoBasica] = React.useState({});
  const [filial, setFilial] = React.useState({});
  const [isLoadingFilial, setIsLoadingFilial] = React.useState(true);

  const loadDadosFilial = async () => {
    try {
      setIsLoadingFilial(true);
      const dadosFilial = await getMobileFilial(filialId);
      if (dadosFilial) setFilial(dadosFilial);
      // TODO se não ter o retorno da filial redirecionar para
      await loadConfiguracaoBasica();
    } finally {
      setIsLoadingFilial(false);
    }
  };

  const loadConfiguracaoBasica = async () => {
    const resultado = await getMobileFilialConfiguracao(
      filialId,
      "configuracaoBasica"
    );
    if (resultado) setConfiguracaoBasica(resultado);
  };

  React.useEffect(() => {
    if (filialId > 0) loadDadosFilial();
  }, [filialId]);

  return (
    <ConfiguracoesBasicasContext.Provider
      value={{
        configuracaoBasica,
        loadConfiguracaoBasica,
        setConfiguracaoBasica,
        filial,
        isLoadingFilial
      }}
    >
      {children}
    </ConfiguracoesBasicasContext.Provider>
  );
};

ConfiguracoesBasicasProvider.propTypes = {
  children: PropTypes.node,
  filialId: PropTypes.number
};

export default ConfiguracoesBasicasProvider;
