import React from "react";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { camelCase, get } from "lodash";

import useConfiguracaoDados, {
  ConfiguracaoDadosContext
} from "customHooks/ConfiguracaoDadosHook";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button.js";
import { useConfirmation } from "components/CustomConfirmationDialog/ConfirmationService";
import Page from "components/CustomPages/Page";
import ComponentSkeleton from "components/ComponentSkeleton/ComponentSkeleton";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import SituacaoSincronizacao from "../ConfiguracaoDadosEntidades/situacaoSincronizacao/SituacaoSincronizacao";
import CustomInput from "components/CustomInput/CustomInput";
import CustomTimeEdit from "components/CustomTimeEdit/CustomTimeEdit";
import RenderTagsInsert from "../ConfiguracaoDadosEntidades/tabSelectInsert/tabInsert/RenderTagsInsert";
import TestarSQL from "../../../Admin/MobileFilial/form/database/configuracaoMapeamento/sql/TestarSQL";
import InputAreaSelectInsert from "../ConfiguracaoDadosEntidades/tabSelectInsert/InputAreaSelectInsert";

const useStyles = makeStyles(theme => ({
  progress: {
    margin: theme.spacing(2)
  }
}));

const tipo = "configuracaoDadosSituacaoPedido";

export default function SituacaoPedido() {
  const classes = useStyles();
  const location = useLocation();
  const { pathname } = location;

  const confirm = useConfirmation();

  const formMethods = useForm();
  const { errors, handleSubmit, reset, control, watch } = formMethods;

  const configuracaoDadosValue = useConfiguracaoDados({
    tipo,
    reset
  });

  const watchSelect = watch("dados.select");

  const {
    isLoading,
    doSaveDadosConfiguracao,
    doLimparConfiguracao,
    configuracaoConst
  } = configuracaoDadosValue;

  const onSubmit = async data => {
    const { ativa, dados = {}, mapeamento } = data;
    await doSaveDadosConfiguracao({
      ativa,
      dados: JSON.stringify(dados),
      mapeamento: JSON.stringify(mapeamento ? { ...mapeamento } : {}),
      tipo
    });
  };

  const handleLimparConfiguracao = () => {
    confirm({
      variant: "danger",
      catchOnCancel: false,
      title: "Confirma a limpeza das informações da configuração?"
    }).then(async () => doLimparConfiguracao(tipo));
  };

  return (
    <ConfiguracaoDadosContext.Provider value={configuracaoDadosValue}>
      <Page labelTituloHeader={configuracaoConst.titulo}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <ComponentSkeleton showSkeleton={isLoading}>
              <Controller
                name="dados.tempoSincronizacao"
                control={control}
                render={props => (
                  <CustomTimeEdit
                    formControlProps={{
                      fullWidth: true,
                      required: true
                    }}
                    labelText="Intervalo para cada sincronização"
                    error={!!get(errors, "dados.tempoSincronizacao")}
                    errorMessage={get(
                      errors,
                      "dados.tempoSincronizacao.message"
                    )}
                    {...props}
                  />
                )}
              />
            </ComponentSkeleton>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs>
            <RenderTagsInsert
              tagsInsert={{
                string: ["PEDIDO_IDENTIFICADORES"]
              }}
              label="Campo a ser utilizado para filtrar os pedidos"
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs>
            <RenderTagsInsert
              tagsInsert={{
                string: ["PEDIDO_IDENTIFICADOR", "SITUACAO_PEDIDO"]
              }}
              label="Campo a ser utilizado para filtrar os pedidos"
              showComoUsar={false}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <ComponentSkeleton showSkeleton={isLoading}>
              <Controller
                name={"dados.select"}
                control={control}
                rules={{ required: "O SQL do Select é requerido." }}
                render={props => (
                  <InputAreaSelectInsert
                    inputProps={{
                      multiline: true
                    }}
                    formControlProps={{
                      fullWidth: true,
                      required: true
                    }}
                    labelText="Select a situação do Pedido."
                    error={!!get(errors, "dados.select")}
                    errorMessage={get(errors, `dados.select.message`)}
                    {...props}
                  />
                )}
              />
            </ComponentSkeleton>
          </GridItem>
        </GridContainer>
        <div style={{ marginBottom: 10 }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              Insira todos os fields da query, não utilize * no sql.
              <br />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              {`Exemplo: "Select codigo_venda as PEDIDO_IDENTIFICADOR, situacao_pedido_PEDIDO as SITUACAO_PEDIDO from venda where
              codigo_venda in ({PEDIDO_IDENTIFICADORES})"`}
            </GridItem>
          </GridContainer>
        </div>

        <ComponentSkeleton showSkeleton={isLoading}>
          <Controller
            name={"select_statusTestado"}
            control={control}
            valueName="checked"
            render={props => (
              <TestarSQL
                titulo="Testar Select"
                tipo="SELECT"
                instrucao={watchSelect}
                {...props}
              />
            )}
          />
        </ComponentSkeleton>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <ComponentSkeleton showSkeleton={isLoading}>
              <Controller
                name="dados.situacaoInicial"
                control={control}
                render={props => (
                  <CustomInput
                    formControlProps={{
                      fullWidth: true
                    }}
                    labelText="Situação Inicial do Pedido"
                    {...props}
                  />
                )}
              />
            </ComponentSkeleton>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <ComponentSkeleton showSkeleton={isLoading}>
              <Controller
                name="dados.situacaoFinal"
                control={control}
                render={props => (
                  <CustomInput
                    formControlProps={{
                      fullWidth: true
                    }}
                    labelText="Situação Final do Pedido"
                    {...props}
                  />
                )}
              />
            </ComponentSkeleton>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <ComponentSkeleton showSkeleton={isLoading}>
              <Controller
                name="ativa"
                valueName="checked"
                control={control}
                onChange={([event]) => event.target.checked}
                render={props => (
                  <CustomSwitch {...props} labelText="Sincronização Ativa?" />
                )}
              />
            </ComponentSkeleton>
          </GridItem>
        </GridContainer>
        <GridContainer justify="flex-start">
          <GridItem>
            <Button color="info" onClick={handleSubmit(onSubmit)}>
              Salvar
            </Button>
          </GridItem>
          <GridItem>
            <Button color="warning" onClick={handleLimparConfiguracao}>
              Limpar Configuração
            </Button>
          </GridItem>
        </GridContainer>
      </Page>
      <SituacaoSincronizacao
        tipo={tipo}
        somenteBotoes={true}
        somenteSincronizarTodos={true}
      />
    </ConfiguracaoDadosContext.Provider>
  );
}

SituacaoPedido.propTypes = {};
