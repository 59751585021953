import React, { useState } from "react";
import PropTypes from "prop-types";

import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import UsuariosTabela from "./UsuariosTabela";
import UsuarioForm from "views/Admin/Usuario/form/UsuarioForm";
import { deleteUsuario } from "services/usuario";
import Notificacoes from "components/Notificacoes/Notificacoes";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ContratanteUsuarios({ contratanteId, dadosContratanteUsuario }) {
  const [usuarioId, setUsuarioId] = useState(-1);
  const { usuarios, doRefresh, isLoading } = dadosContratanteUsuario;
  const handleAddUsuario = () => setUsuarioId(0);
  const handleCancel = () => setUsuarioId(-1);
  const handleRemoveUsuario = async rowData => {
    if (!rowData || !rowData.id) return;
    const resultado = await deleteUsuario(rowData.id);
    if (resultado) {
      Notificacoes.success("Usuario removido com sucesso");
      doRefresh();
    } else Notificacoes.error("Ocorreu um erro ao remover o Usuario");
  };

  const handleAfterConfirmar = () => {
    setUsuarioId(-1);
    doRefresh();
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <UsuariosTabela
                isLoading={isLoading}
                usuarios={usuarios}
                onAddUsuario={handleAddUsuario}
                onRemover={handleRemoveUsuario}
                onEditar={setUsuarioId}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Dialog
        open={usuarioId >= 0}
        // onClose={onCancel}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={"md"}
      >
        <UsuarioForm
          id={usuarioId}
          contratanteId={contratanteId}
          onCancel={handleCancel}
          onAfterConfirmar={handleAfterConfirmar}
        />
      </Dialog>
    </div>
  );
}

ContratanteUsuarios.propTypes = {
  contratanteId: PropTypes.number,
  dadosContratanteUsuario: PropTypes.object
};
