import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import Slide from "@material-ui/core/Slide";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import MobileFiliaisTabela from "./MobileFiliaisTabela";
import { deleteMobileFilial } from "services/mobileFilial";
import Notificacoes from "components/Notificacoes/Notificacoes";

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ContratanteMobileFiliais({
  contratanteId,
  dadosContratanteMobileFilial
}) {
  const history = useHistory();
  const { mobileFiliais, doRefresh, isLoading } = dadosContratanteMobileFilial;
  const handleAddMobileFilial = () =>
    history.push(`/admin/contratante/${contratanteId}/filial`);
  const handlOnEditarFilial = filialId =>
    history.push(
      `/admin/contratante/${contratanteId}/filial/${filialId}`
    );

  const handleRemoveFilial = async rowData => {
    if (!rowData || !rowData.id) return;
    const resultado = await deleteMobileFilial(rowData.id);
    if (resultado) {
      Notificacoes.success("Filial removida com sucesso");
      doRefresh();
    } else Notificacoes.error("Ocorreu um erro ao remover a Filial");
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <MobileFiliaisTabela
                isLoading={isLoading}
                mobileFiliais={mobileFiliais}
                onAddMobileFilial={handleAddMobileFilial}
                onRemover={handleRemoveFilial}
                onEditar={handlOnEditarFilial}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

ContratanteMobileFiliais.propTypes = {
  contratanteId: PropTypes.number,
  dadosContratanteMobileFilial: PropTypes.object
};
