import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import MobileFilialForm from "./MobileFilialForm";
import { authContext } from "contexts/AuthContext";
import useMobileFilialReducer from "./MobileFilialFormHooks";
// import ConfiguracaoMapeamento from "./database/configuracaoMapeamento/ConfiguracaoMapeamento";

const useStyles = makeStyles(theme => ({
  progress: {
    margin: theme.spacing(2)
  }
}));

export default function MobileFilialTabs(props) {
  const { match = {}, onAfterConfirmar, history } = props;
  const { params: { contratanteId, filialId } = {} } = match;

  const classes = useStyles();

  const {
    state: { dadosUsuario }
  } = React.useContext(authContext);

  const { isLoading, mobileFilial, setMobileFilial } = useMobileFilialReducer(
    Number(filialId || 0)
  );

  const handleOnCancel = () =>
    contratanteId
      ? history.push(`/admin/contratante/${contratanteId}`)
      : history.push(`/admin/filiais`)
  return (
    <React.Fragment>
      <MobileFilialForm
        mobileFilial={mobileFilial}
        isLoading={isLoading}
        onCancel={handleOnCancel}
        onAfterConfirmar={onAfterConfirmar}
        setMobileFilial={setMobileFilial}
        contratanteId={contratanteId}
        history={history}
        // exibeHeader={false}
      />
    </React.Fragment>
  );
}

MobileFilialTabs.propTypes = {
  match: PropTypes.object,
  contratanteId: PropTypes.number,
  filialId: PropTypes.number,
  onCancel: PropTypes.func,
  onAfterConfirmar: PropTypes.func
};
