import React from "react";
import PropTypes from "prop-types";
import Skeleton from "@material-ui/lab/Skeleton";

function ComponentSkeleton({
  children,
  showSkeleton,
  skeletonVariant = "text"
}) {
  return showSkeleton === true ? (
    <Skeleton variant={skeletonVariant} />
  ) : (
    children
  );
}

ComponentSkeleton.propTypes = {
  children: PropTypes.node,
  showSkeleton: PropTypes.bool,
  skeletonVariant: PropTypes.string
};

export default ComponentSkeleton;
