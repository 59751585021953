import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { omit } from "lodash";
import { Controller, useForm } from "react-hook-form";

import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CadastroPage from "components/CustomPages/CadastroPage";
import { saveUpdateMobileFilialDatabase } from "services/mobileFilialDatabase";
import Notificacoes from "components/Notificacoes/Notificacoes";
import { getMobileDispositivo } from "services/mobileFilialDispositivo";
import FormInput from "components/FormFields/FormFields";
import FormSwitch from "components/FormFields/FormSwitch";
import { updateMobileDispositivo } from "services/mobileFilialDispositivo";
import FormTelefone from "components/FormFields/FormTelefone";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

export default function MobileDispositivoForm({ match, history }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const classes = useStyles();

  const { params: { filialId, id, contratanteId } = {} } = match;

  const { handleSubmit, control, reset } = useForm();

  const loadMobileDispositivo = async id => {
    try {
      setIsLoading(true);
      if (id) {
        const retorno = await getMobileDispositivo(id);
        reset(retorno);
      }
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (id) loadMobileDispositivo(id);
  }, [id]);

  const onSubmit = async data => {
    const { apelido, telefone, email } = data;
    const retorno = await updateMobileDispositivo({
      id,
      apelido,
      email,
      telefone
    });
    if (retorno) {
      Notificacoes.success("Dados do dispositivo salvo com sucesso.");
      handleOnCancel();
    } else
      Notificacoes.error("Ocorreu um erro ao salvar os dados do dispositivo.");
  };

  const urlDefault = React.useMemo(
    () => (contratanteId ? `/admin/contratante/${contratanteId}/` : "/admin/"),
    [contratanteId]
  );

  const handleOnCancel = () =>
    history.push(`${urlDefault}filial/${filialId}`);

  return (
    <CadastroPage
      labelTituloHeader="Dados do Dispositivo"
      onCancelar={handleOnCancel}
      onSalvar={handleSubmit(onSubmit)}
    >
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <FormInput
            isLoading={isLoading}
            control={control}
            name="apelido"
            label="Apelido"
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <FormInput
            isLoading={isLoading}
            control={control}
            name="email"
            label="Email"
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <FormTelefone
            isLoading={isLoading}
            control={control}
            name="telefone"
            label="Telefone"
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <FormInput
            isLoading={isLoading}
            control={control}
            name="uuid"
            label="UUID"
            readOnly={true}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <FormInput
            isLoading={isLoading}
            control={control}
            name="dispositivo"
            label="Modelo Dispositivo"
            readOnly={true}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <FormInput
            isLoading={isLoading}
            control={control}
            name="plataforma"
            label="Plataforma"
            readOnly={true}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <FormInput
            isLoading={isLoading}
            control={control}
            name="versaoApp"
            label="Versão do APP"
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <FormInput
            isLoading={isLoading}
            control={control}
            name="dataAtivacao"
            label="Data da Ativação"
            readOnly={true}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <FormInput
            isLoading={isLoading}
            control={control}
            name="dataUltimoAcesso"
            label="Data do Ultimo Acesso"
            readOnly={true}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <FormSwitch
            isLoading={isLoading}
            control={control}
            name="ativo"
            label="Ativo"
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <FormSwitch
            isLoading={isLoading}
            control={control}
            name="bloqueado"
            label="Bloqueado"
            disabled={true}
          />
        </GridItem>
      </GridContainer>
    </CadastroPage>
  );
}

MobileDispositivoForm.propTypes = {
  id: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  filialId: PropTypes.oneOf([PropTypes.number, PropTypes.string])
};
