import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Switch from "@material-ui/core/Switch";
// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
// core components

const useStyles = makeStyles({});

export default function CustomSwitch(props) {
  const classes = useStyles();
  const {
    formControlProps,
    labelText,
    id,
    labelProps,
    switchProps,
    error,
    errorMessage,
    success,
    onChange,
    disabled,
    ...otherProps
  } = props;

  const handleOnChange = e => {
    onChange && onChange(e.target.checked);
  };

  const defaultChecked =
    otherProps.defaultChecked || otherProps.defaultValue || false;
  const checked = otherProps.checked || otherProps.value || false;

  return (
    <FormGroup row {...formControlProps}>
      <FormControlLabel
        control={
          <Switch
            id={id}
            {...switchProps}
            onChange={handleOnChange}
            defaultChecked={defaultChecked}
            checked={checked}
            disabled={disabled}
          />
        }
        label={labelText}
        {...labelProps}
      />
      {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
      {error ? <FormHelperText error>{errorMessage}</FormHelperText> : null}
    </FormGroup>
  );
}

CustomSwitch.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  switchProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  success: PropTypes.bool,
  onChange: PropTypes.func
};
