import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Power from "@material-ui/icons/Power";
import PowerOff from "@material-ui/icons/PowerOff";
import { makeStyles } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";
import Fab from "@material-ui/core/Fab";
import { SnackbarContent } from "@material-ui/core";
import { filialGerenciadorStatus } from "services/mobileFilialGerenciador";
import useInterval from "customHooks/IntervalHandle";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center"
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative"
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700]
    }
  },
  buttonError: {
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700]
    }
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  rootSnackbar: {
    maxWidth: 600
  },
  snackbar: {
    margin: theme.spacing(1)
  },
  iconColor: {
    colorPrimary: "#fff"
  }
}));

export default function SituacaoGerenciador({ identificadorApp }) {
  const classes = useStyles();

  const [connectado, setConnectado] = React.useState(false);

  useInterval(async () => {
    const retorno = identificadorApp
      ? await filialGerenciadorStatus(identificadorApp)
      : false;
    await setConnectado(retorno === true);
  }, 2000);

  // React.useEffect(() => {
  //   const interval = setInterval(async () => {
  //     const retorno = identificadorApp
  //       ? await filialGerenciadorStatus(identificadorApp)
  //       : false;
  //     await setConnectado(retorno === true);
  //   }, 2000);
  //   return () => clearInterval(interval);
  // }, []);

  const buttonClassname = clsx({
    [classes.buttonSuccess]: connectado,
    [classes.buttonError]: !connectado
  });

  const snackbarClassname = clsx({
    [classes.snackbar]: true,
    [classes.buttonSuccess]: connectado,
    [classes.buttonError]: !connectado
  });

  const renderedFab = React.useMemo(
    () => (
      <React.Fragment>
        <div className={classes.wrapper}>
          <Fab
            key="fab_situacao_conexao_gerenciador"
            classes={{
              root: buttonClassname
            }}
            aria-label="Situação do gerenciador de dados da filial"
          >
            {connectado ? (
              <Power style={{ color: "#fff" }} />
            ) : (
              <PowerOff style={{ color: "#fff" }} />
            )}
          </Fab>
        </div>
        <div className={classes.wrapper}>
          <SnackbarContent
            className={snackbarClassname}
            message={`O gerenciador do Cliente esta ${
              connectado ? "conectado" : "desconectado"
            }.`}
          />
        </div>
      </React.Fragment>
    ),
    [connectado]
  );

  return <div className={classes.root}>{renderedFab}</div>;
}

SituacaoGerenciador.propTypes = {
  identificadorApp: PropTypes.string
};
