import React from "react";
import PropTypes from "prop-types";
/** Custom Hooks */
import { DEFAULT_USER_AUTH } from "constants/Consts";
import { getStoredUserAuth } from "helpers/login.helpers";
import { getUsuario } from "services/usuario";

export const authActions = {
  AUTH_SET_STATE: "auth/SET_STATE",
  AUTH_LOGIN: "auth/LOGIN",
  AUTH_LOGOUT: "auth/LOGOUT"
};

let dispatch_;

const reducer = (state, action) => {
  switch (action.type) {
    case authActions.AUTH_SET_STATE:
      return {
        ...state,
        ...action.payload
      };
    case authActions.AUTH_LOGIN: {
      const { payload } = action;
      const { dadosUsuario, token, acao } = payload;
      window.localStorage.setItem(
        "UserAuth",
        JSON.stringify({ acao, dadosUsuario, token, usuarioAutenticado: true })
      );
      return {
        ...state,
        dadosUsuario,
        token,
        acao,
        usuarioAutenticado: true
      };
    }
    case authActions.AUTH_LOGOUT: {
      window.localStorage.clear();
      return {
        ...DEFAULT_USER_AUTH
      };
    }
    default:
      return state;
  }
};

export const authContext = React.createContext({
  state: {
    ...getStoredUserAuth()
  },
  setAuthStatus: () => {},
  setUnauthStatus: () => {}
});

const { Provider } = authContext;

// const AuthProvider = ({ children }) => {
//   const { auth, setAuthStatus, setUnauthStatus } = useAuthHandler(
//     getStoredUserAuth()
//   );
//   return (
//     <Provider value={{ auth, setAuthStatus, setUnauthStatus }}>
//       {children}
//     </Provider>
//   );
// };

const AuthProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, getStoredUserAuth());
  const { dadosUsuario } = state;
  const { id } = dadosUsuario;

  const usuarioAutenticado = id && id > 0;

  dispatch_ = dispatch;
  const setAuthStatus = (dadosUsuario, token, acao) => {
    return dispatch({
      type: authActions.AUTH_LOGIN,
      payload: {
        dadosUsuario,
        token,
        acao
      }
    });
  };

  const setUnauthStatus = () => {
    dispatch({
      type: authActions.AUTH_LOGOUT
    });
  };

  React.useEffect(() => {
    if (usuarioAutenticado) getUsuario(id);
  }, []);

  return (
    <Provider
      value={{
        state,
        setAuthStatus,
        setUnauthStatus
      }}
    >
      {children}
    </Provider>
  );
};

export default AuthProvider;

AuthProvider.propTypes = {
  children: PropTypes.node
};

export const doLogout = () => {
  dispatch_({
    type: authActions.AUTH_LOGOUT
  });
};
