import { doResquestGraphql } from "../helpers/graphql";

const databaseSubsection = `
  id
  configuracao
  ativa
  filialId
`;

export async function getListaMobileFilialDatabases(options = {}) {
  const { limit = 0, offset = 0, where, order, fields } = options;
  const query = `
    query getMobileFilialDatabases($queryOptions: QueryOptions) {
      mobileFilialDatabases(queryOptions: $queryOptions) {
        ${fields || databaseSubsection}
      }
    }
  `;

  const variables = {
    queryOptions: {
      limit,
      offset,
      where,
      order
    }
  };

  if (!limit) delete variables.queryOptions.limit;
  if (!offset) delete variables.queryOptions.offset;

  const resultado = await doResquestGraphql(query, variables);
  if (
    resultado.status &&
    resultado.data &&
    resultado.data.mobileFilialDatabases
  )
    return resultado.data.mobileFilialDatabases;
  return;
}

export async function getQtdeMobileFilialDatabases(where) {
  const query = `
    query getQtdeMobileFilialDatabases($queryOptions: QueryOptions) {
      mobileFilialDatabasesCount(queryOptions: $queryOptions)
    }
  `;

  const variables = {
    queryOptions: {
      where
    }
  };

  const retorno = await doResquestGraphql(query, variables);
  if (retorno && retorno.status) return retorno.data.mobileFilialDatabasesCount;
  return 0;
}

export async function deleteMobileFilialDatabase(id) {
  const query = `
    mutation deleteMobileFilialDatabase($id: ID!) {
      deleteMobileFilialDatabase(id: $id)
    }
  `;

  const variables = {
    id
  };

  const retorno = await doResquestGraphql(query, variables);
  if (retorno && retorno.status) return retorno.data.deleteMobileFilialDatabase;
  return false;
}

export async function saveUpdateMobileFilialDatabase(values) {
  const query = `
    mutation doCreateUpdateMobileFilialDatabase($input: MobileFilialDatabaseCreateUpdateInput!) {
      createUpdateMobileFilialDatabase(input: $input) {
        ${databaseSubsection}
      }
    }
  `;

  const variables = {
    input: values
  };

  const retorno = await doResquestGraphql(query, variables);
  if (
    retorno.status &&
    retorno.data &&
    retorno.data.createUpdateMobileFilialDatabase
  )
    return retorno.data.createUpdateMobileFilialDatabase;
  return;
}

export async function getMobileFilialDatabase(id) {
  const query = `
    query getMobileFilialDatabase($id: ID!) {
      mobileFilialDatabase(id: $id) {
        ${databaseSubsection}
      }
    }
  `;

  const variables = {
    id
  };

  const retorno = await doResquestGraphql(query, variables);
  if (retorno.status && retorno.data && retorno.data.mobileFilialDatabase)
    return retorno.data.mobileFilialDatabase;
  return;
}
